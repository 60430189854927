import React, { Fragment, useEffect, useState } from "react";
import { getAllClients } from "../../services/clients/clients";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import {
  GetInvoicePpd,
  getPayForms,
  sendStampComplement,
} from "../../services/invoice/invoice";
import { validarSesion } from "../../hooks/tokens/useToken";
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { useHistory } from "react-router-dom";
import { FormatAmount } from "../../utils/formatNumbers";
import { ModalComplementPayment } from "../../components/Invoice/ModalComplementPayment";
import { getAllCommercialLines } from "../../services/commercialLine/commercialLine";
import TypePersonInvoiceEnum from "../../utils/enum/type_person.enum";

export const IndexComplementPaymentService = () => {
  const history = useHistory();
  const [createSweet] = useSweetAlert();

  const branchId = localStorage.getItem("branchId");

  const [listCommercialLines, setListCommercialLines] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [isSelectedClients, setIsSelectedClients] = useState(false);
  const [clientInformation, setClientInformation] = useState(null);
  const [listInvoices, setListInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [listPayForms, setListPayForms] = useState([]);

  const [loadingComplement, setLoadingComplement] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableBtnSave, setDisableBtnSave] = useState(false);

  const [formComplement, setFormComplement] = useState({
    commercialLineId: "",
    companyBusinessNameId: "",
    taxRegimeId: "",
    type_person: "",
    paymentDate: "",
    paymentFormId: "",
    comments: "",
  });

  useEffect(() => {
    GetAllClients();
    GetPayForms();
    GetCommercialLines();
  }, []);

  const GetAllClients = async () => {
    const listClient = await getAllClients();

    if (listClient) {
      setListClients(listClient);
    }
  };

  const GetCommercialLines = async () => {
    const commercialLines = await getAllCommercialLines();

    if (commercialLines?.length > 0) {
      const list = commercialLines?.map((commercial) => {
        return {
          value: commercial?.id_commercial_line,
          label: commercial?.long_name,
          rfc: commercial?.rfc,
          cp: commercial?.cp,
          id_tax_regime: commercial?.id_tax_regime,
          code_tax_regime: commercial?.code_tax_regime,
          name_tax_regime: commercial?.name_tax_regime,
          type_person: commercial?.type_person
            ? TypePersonInvoiceEnum.MORAL
            : TypePersonInvoiceEnum.FISICA,
        };
      });

      setListCommercialLines(list);
    } else setListCommercialLines([]);
  };

  const GetPayForms = async () => {
    const payForms = await getPayForms();

    if (payForms?.length > 0) {
      const list = payForms?.map((form) => {
        return {
          label: form.abbreviation + " - " + form.name,
          value: form.id_pay_method,
          code: form.abbreviation,
        };
      });

      setListPayForms(list);
    } else setListPayForms([]);
  };

  const GetRelatedinvoices = async (ClientId) => {
    const getListInvoices = await GetInvoicePpd(ClientId);

    if (getListInvoices?.code === 200) {
      if (getListInvoices?.data?.length > 0)
        setListInvoices(getListInvoices?.data);
    } else if (getListInvoices?.code === 401) {
      validarSesion(history, getListInvoices.code, GetRelatedinvoices);
    } else if (getListInvoices?.code === 500) {
      createSweet(
        "warning",
        "warning",
        "Obtener información del paciente",
        "Ocurrio un problema al obtener la información del paciente"
      );
    } else {
      createSweet("warning", "warning", "Orden", getListInvoices.msg);
    }
  };

  const handleCheckInvoice = (isSelected, invoice) => {
    if (isSelected) {
      setSelectedInvoices([
        ...selectedInvoices,
        {
          ...invoice,
          paymentBy: "liquided",
          amount: 0,
        },
      ]);
    } else {
      setSelectedInvoices(
        selectedInvoices.filter(
          (item) => item.id_invoice !== invoice.id_invoice
        )
      );
    }
  };

  const handleModal = () => setShowModal(!showModal);

  const handleChangeType = (type, invoiceId) => {
    setSelectedInvoices(
      selectedInvoices.map((item) => {
        if (item.id_invoice === invoiceId) {
          return {
            ...item,
            paymentBy: type,
          };
        }
        return item;
      })
    );
  };

  const handleChangeAmount = (amount, invoiceId) => {
    setSelectedInvoices(
      selectedInvoices.map((item) => {
        if (item.id_invoice === invoiceId) {
          return {
            ...item,
            amount: amount,
          };
        }
        return item;
      })
    );
  };

  const handleSaveComplement = async () => {
    setLoadingComplement(true);
    setDisableBtnSave(true);

    const body = {
      CommercialLineId: formComplement.commercialLineId,
      BranchId: branchId,
      ClientId: clientInformation?.id_company,
      ClientBusinessId: formComplement.companyBusinessNameId,
      PaymentDate: formComplement.paymentDate,
      PaymentFormCode: formComplement.paymentFormId,
      AdditionalComments: formComplement.comments,
      RelatedDocuments: selectedInvoices.map((invoice) => {
        return {
          InvoiceId: invoice.id_invoice,
          PreviousAmount:
            invoice.previous_amount === 0
              ? invoice.total
              : invoice.previous_amount,
          PaymentAmount:
            invoice.paymentBy === "liquided"
              ? invoice.previous_amount
              : invoice.amount,
          OutsideBalance:
            (invoice.previous_amount === 0
              ? invoice.total
              : invoice.previous_amount) -
            (invoice.paymentBy === "liquided"
              ? invoice.previous_amount
              : invoice.amount),
          Partiality: invoice.parciality_number,
        };
      }),
    };

    const sendStamp = await sendStampComplement(body);

    if (sendStamp.code === 200) {
      createSweet(
        "create",
        "info",
        "Exito!",
        "Se genero la factura con exito!"
      );

      setDisableBtnSave(false);

      setTimeout(() => {
        window.location.href = "../../../facturacion/Emitidos";
      }, 2000);
    } else if (sendStamp.code === 401)
    {
      // validarSesion(history, sendStamp.code, handleSaveComplement);
      createSweet(
        "error",
        "error",
        "Factura",
        "Su sesión ha expirado, por favor vuelva a iniciar sesión"
      );
    }
    else if (sendStamp.code === 500)
      createSweet(
        "error",
        "error",
        "Factura",
        "Se perdio la conexión, con el servidor"
      );
    else if (sendStamp.code === 400)
      createSweet(
        "error",
        "error",
        "Factura",
        "No se llevo acabo la operación"
      );
    else createSweet("warning", "warning", "Factura", sendStamp.msg);

    setLoadingComplement(false);

    setDisableBtnSave(false);
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-l-0 p-r-0">
        {!isSelectedClients && (
          <Alert color="danger">
            {" "}
            <i class="fa fa-bookmark-o"></i> Selecciona un cliente para realizar
            un complemento de pago
          </Alert>
        )}
        <Row>
          {!isSelectedClients &&
            listClients.map((client, index) => (
              <Col
                sm="12"
                md="3"
                lg="3"
                key={`client-${client?.id_company}`}
                className="pointer"
                onClick={() => {
                  setIsSelectedClients(true);
                  GetRelatedinvoices(client?.id_company);
                  setClientInformation(client);
                }}
              >
                <Card className="shadow-lg p-1 shadow-showcase ribbon-wrapper">
                  <Col sm="12" className="p-l-0 p-r-0">
                    <Row>
                      <Col
                        sm="12"
                        md="12"
                        lg="12"
                        className="d-block text-center p-2"
                      >
                        <div className="profile-vector">
                          <img
                            className="img-fluid"
                            src={require("../../assets/images/loginHelenLabs/Agreement/AgremmentAprovado.svg")}
                            style={{ width: "20%" }}
                            alt=""
                          />
                        </div>
                        <p className="f-w-600 f-12 ">
                          {client?.bussines_name ?? ""}
                        </p>
                        <Row>
                          <Col
                            sm="12"
                            md="12"
                            lg="12"
                            className=" text-left d-block pl-4"
                          >
                            <p className="f-12">
                              <i class="fa fa-male font-primary"></i>{" "}
                              {client?.rfc ?? ""}
                            </p>
                            <p className="f-12">
                              <i class="fa fa-envelope font-primary"></i>{" "}
                              {client?.contact_email ?? ""}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
            ))}
          {isSelectedClients && (
            <Col sm="12" md="12" lg="12" className="text-right">
              <Button
                color="dark"
                className="m-2 rounded"
                onClick={() => {
                  setIsSelectedClients(false);
                  setSelectedInvoices([]);
                  setClientInformation(null);
                  setListInvoices([]);
                }}
                size="sm"
              >
                {" "}
                <span className="fa fa-filter"></span> Volver a los clientes
              </Button>
            </Col>
          )}
          {isSelectedClients && (
            <>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <CardHeader
                    tag={"h6"}
                    className="bg-info p-2"
                  >{`${clientInformation?.bussines_name}`}</CardHeader>
                  <CardBody className="p-1">
                    <Row>
                      <Col sm="12" md="12" lg="12" className="text-right">
                        {selectedInvoices?.length > 0 && (
                          <Button
                            color="dark"
                            className="m-2 rounded"
                            size="sm"
                            onClick={() => {
                              handleModal();
                              setLoadingComplement(true);
                              setTimeout(() => {
                                setLoadingComplement(false);
                              }, 2000);
                            }}
                          >
                            {" "}
                            Complemento de pago
                          </Button>
                        )}
                      </Col>
                      <Col sm="12" md="12" lg="12">
                        <div className="table-responsive">
                          {listInvoices?.length === 0  && (
                            <div className="text-center">
                              <div
                                style={{ textAlign: "-webkit-center" }}
                                className="d-block"
                              >
                                `{" "}
                                <img
                                  className="img-fluid"
                                  src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}
                                  style={{ width: "32%" }}
                                  alt=""
                                />
                              </div>
                              <p className="f-w-600 f-14 badge badge-light-primary">
                                No hay facturas pendientes por pagar
                              </p>
                            </div>
                          )}
                          
                          {
                            listInvoices?.length > 0  && ( <Table className="table-sm table-stripped" size="sm">
                            <thead className="theadInvoice">
                              <tr>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                    borderTopLeftRadius: "10px",
                                  }}
                                ></th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                ></th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  SERIE/FOLIO
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  FECHA
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  MÉTODO DE PAGO
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  PARCIALIDAD
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  TOTAL
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  MONTO PÁGADO
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  SALDO
                                </th>
                                <th
                                  className="text-center labeltheadInvoice"
                                  style={{
                                    padding: "5px 15px",
                                    textAlign: "center",
                                    position: "relative",
                                    borderTopRightRadius: "10px",
                                  }}
                                >
                                  UUID
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {listInvoices.map((invoice, index) => (
                                <tr
                                  key={`invoice-${invoice?.id_invoice}`}
                                  className="text-center f-w-600"
                                >
                                  <td className="d-block">
                                    <Input
                                      type="checkbox"
                                      checked={
                                        selectedInvoices?.find(
                                          (inv) =>
                                            inv?.id_invoice ===
                                            invoice?.id_invoice
                                        ) ?? false
                                      }
                                      onChange={(e) =>
                                        handleCheckInvoice(
                                          e.target.checked,
                                          invoice
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <img
                                      alt="flask"
                                      className="img-fluid text-info mr-2"
                                      src={require("../../assets/images/flask_laboratory.svg")}
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                  </td>
                                  <td>{`${invoice?.serie ?? ""} ${
                                    invoice?.folio ?? ""
                                  }`}</td>
                                  <td>{invoice?.date ?? "-"}</td>
                                  <td>{invoice?.name_type_method ?? "-"}</td>
                                  <td>{invoice?.parciality_number ?? 1}</td>
                                  <td>
                                    $ {FormatAmount(invoice?.total ?? 0, 2)}
                                  </td>
                                  <td>
                                    ${" "}
                                    {FormatAmount(
                                      invoice?.payment_amount ?? 0,
                                      2
                                    )}
                                  </td>
                                  <td>
                                    ${" "}
                                    {FormatAmount(
                                      invoice?.outside_balance ?? 0,
                                      2
                                    )}
                                  </td>
                                  <td>{invoice?.uuid}</td>
                                </tr>
                              ))}
                            </tbody>
                            </Table>)
                          }
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Container>
      {showModal && (
        <ModalComplementPayment
          loadingPreview={loadingComplement}
          showPreviewInvoice={showModal}
          handlePreviewInvoice={handleModal}
          listInvoices={selectedInvoices}
          handleChangeType={handleChangeType}
          handleChangeAmount={handleChangeAmount}
          clientInformation={clientInformation}
          formComplement={formComplement}
          setFormComplement={setFormComplement}
          listPayForms={listPayForms}
          listCommercialLines={listCommercialLines}
          handleSaveComplement={handleSaveComplement}
          disbleButton={disableBtnSave}
        />
      )}
    </Fragment>
  );
};
