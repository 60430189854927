import React from "react";

export const FormNotification = ({ notifications, hoveredId, setHoveredId, removeNotification }) => {
  return (
    <ul className="list-unstyled">
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <li
            key={notification.id}
            className="w-100 bg-white p-3 shadow-lg mb-2 transition-transform"
            style={{
              transform: hoveredId === notification.id ? "scale(1.05)" : "scale(1)",
              backgroundColor: hoveredId === notification.id ? "#f0f0f0" : "white",
              boxShadow: hoveredId === notification.id
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "0px 2px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              cursor: "pointer",
              transition: "all 0.3s ease-in-out",
            }}
            onMouseEnter={() => setHoveredId(notification.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <div className="d-flex align-items-center">
              <img
                src={require("../../assets/images/notifications/cancelado.png")}
                style={{ width: "25px", height: "25px" }}
                alt="cancelado"
                className="mr-2"
              />
              <h6 className="text-sm text-left mb-0 ms-2 flex-grow-1 mr-2" style={{ color: "black" }}>
                {notification.titleAlert}
              </h6>
              <p className="text-sm text-left mb-0 ms-2 flex-grow-1" style={{ fontSize: "12px" }}>
                {notification.time}
              </p>

              <i
                className="fa fa-times text-gray-500"
                style={{
                  color: "#7bc6ed",
                  cursor: "pointer",
                  display: hoveredId === notification.id ? "block" : "none",
                }}
                onClick={() => removeNotification(notification.id)}
              ></i>
            </div>

            <p className="text-xs text-gray-500 mt-1">{notification.message}</p>
          </li>
        ))
      ) : (
        <p className="text-center text-gray-500">No hay notificaciones</p>
      )}
    </ul>
  );
};
