import React, { Fragment, useState, useEffect,useRef } from 'react';
import { Table, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, FormText, TabContent, TabPane, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Button } from 'reactstrap'
import { Users } from 'react-feather';
import { ConfigServer } from '../../../data/config';
import { useHistory } from 'react-router-dom';



export const IndexTypeMicro = (props) => {

    const {
        checkTypeClient,informationPatient,id_company,informationSampling,nim_Notification,listTest,handleCancelWorkOrderUpdate,
        number_format,totalW,listPayMethods,handleDinamicInputWorkOrder,validTotalCredit,AvailablePayMethodByCompany,_adminFinance,
        handleGiveChange,handlePayBill,handleDeletePayMethod,isEditWorkOrder,isPayForCompany,
        listPayMethods_valid,showMessageW,subTotalW,totalDiscountW,totalTaxesW,totalDevolutions,listPayMethod,valueTax,downloadQuoter,
        checkInvoice
    } = props;
       

    const [activeTab, setActiveTab] = useState('2'); 
    const [orgActiveTab, setOrgActiveTab] = useState('1');
    const [isFirstLoad, setIsFirstLoad] = useState(true); 

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false); 
        }
    }, [isFirstLoad]);

    const handleiew1 = () => {
        setActiveTab("2")
        setOrgActiveTab("1")
    }

        const history = useHistory();
        var id_quoter = 0


    const groupedData = informationSampling.map(sample => {
        id_quoter = sample.id_quoter
        if (sample.listSamples && Array.isArray(sample.listSamples)) {
            return sample.listSamples.map(_sample => {
                const relatedTest = listTest.find(test => test.id_mic_detail_sample === _sample.id_mic_detail_sample);
                console.log("🚀 ~ IndexTypeMicro ~ relatedTest:", relatedTest);
    
                return {
                    id_mic_detail_sample: _sample.id_mic_detail_sample,
                    folio: _sample.folio,
                    name_mic_sample: _sample.name_mic_sample,
                    name_mic_sampling: _sample.name_mic_sampling,
                    name_mic_site: _sample.name_mic_site,
                    test_name: relatedTest ? relatedTest.name : 'No Determinado',  
                    price: relatedTest ? relatedTest.price : 'No Disponible',
                    contract_number: relatedTest ? relatedTest.contract_number : 'No Disponible'  
 
                    
                };
            });
        }
        return [];  
    });

    
    console.log("🚀 ~ IndexTypeMicro ~ groupedData:", groupedData);

    return (
        <>
            <div className="email-wrap bookmark-wrap">
                <Row>
                    <Col xl="3" className="box-col-6">
                        <div className="email-left-aside">
                            <Card>
                                <CardBody>
                                    <div className="email-app-sidebar left-bookmark">
                                        <div className="media">
                                            <div className="media-size-email">
                                                <img className="mr-3 rounded-circle" src={require("../../../assets/images/user/user.png")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <h5 className="f-w-600">{"Cliente"}</h5>
                                                <p className='f-14' >{checkTypeClient === 1 ? "Particular" : "Empresa"}</p>
                                                <b className='txt-success' >{nim_Notification ?? "-"}</b>
                                            </div>
                                        </div>
                                        <Nav className="main-menu contact-options" role="tablist"  >
                                            <NavItem>
                                                <button className="badge-light-success btn-block btn-mail" onClick={handleiew1}>
                                                    <Users className="mr-2 txt-success" />
                                                    <label className='txt-success'>
                                                        {checkTypeClient === 1
                                                            ? `${informationPatient.name} ${informationPatient.paternal_surname} ${informationPatient.maternal_surname}`
                                                            : id_company.label || ""}
                                                    </label>
                                                </button>
                                            </NavItem>
                                            <NavItem>
                                                <span className="main-title txt-success"> {"Acciones rápidas"}</span>
                                            </NavItem>
                                            <NavItem>
                                                <a onClick={() => handleCancelWorkOrderUpdate() }  className={activeTab === '3' ? 'active' : ''} >
                                                    <span className="title txt-danger"> {"Cancelar Admisión"}</span>
                                                </a>
                                            </NavItem>
                                            <NavItem>
                                                <a onClick={() => history.goBack()}  className={activeTab === '4' ? 'active' : ''}>
                                                    <span className="title txt-secondary"> {"Regresar a dashboard"}</span>
                                                </a>
                                            </NavItem>
                                            <NavItem>
                                                <a  onClick={() => downloadQuoter(id_quoter,valueTax)}   className={activeTab === '5' ? 'active' : ''} >
                                                    <span className="title txt-secondary"> {"Descargar cotización"}</span>
                                                </a>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col xl="9" md="12" className="box-col-12">
                        <div className="email-right-aside bookmark-tabcontent contacts-tabs">
                            <div className="email-body radius-left">
                                <div className="pl-0">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="2">
                                            {isFirstLoad ? (
                                                <>

                                                </>
                                              
                                            ) : (
                                                <Card>
                                                    <CardHeader className=" pt-2 pb-2 pl-0 pr-0">
                                                        <Col sm="12" className='p-l-0 p-r-0' >
                                                        <Row>
                                                            <Col sm="1" style={{textAlign:"right"}} className='p-l-0 p-r-0' >
                                                            <img alt="flask"  src={require("../../../assets/images/price/codeQuoter.svg")} style={{ width: "28px", height: "28px" }} />
                                                            </Col>
                                                            <Col sm="11" className='p-l-0 p-r-0' >
                                                            <h6 className='txt-success f-w-500' >Datos generales de la cotización</h6>

                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row className="list-persons">
                                                            <Col xl="4 xl-50" md="5" className='p-l-0' >
                                                                <Nav className="flex-column nav-pills" id="v-pills-tab1" role="tablist" aria-orientation="vertical">
                                                                    {
                                                                        informationSampling.length > 0 && informationSampling.map(_find => {
                                                                            return  <Col sm="12" className='pt-0'  >
                                                                            <Row>
                                                                                <Col sm="6">
                                                                                <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i> Código de la cotización</label> <br />

                                                                                <label>{_find.name_quoter} </label>
                                                                                </Col>
                                                                                <Col sm="6">
                                                                                <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i> Plan de muestreo</label> <br />

                                                                                <label>{_find.sampling_plan} </label>
                                                                                </Col>
                                                                                <Col sm="12" className='pt-5' >
                                                                                <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i> Observaciones del muestreo:</label> <br />

                                                                                <br />
                                                                                <textarea 
                                                                                    className='form-control form-control-sm input-air-primary'
                                                                                    rows="2"
                                                                                    value={_find.observation}
                                                                                    readOnly
                                                                               ></textarea>


                                                                                </Col>
                                                                            </Row>
                                                                            </Col>
                                                                            
                                                                        })
                                                                    }
                                                                   
                                                                    
                                                                </Nav>
                                                            </Col>
                                                            <Col xl="8 xl-50" md="7">
                                                                <TabContent activeTab={orgActiveTab}>
                                                                    <TabPane tabId="1">
                                                                        <Col sm="12" style={{textAlign:"center"}} >
                                                                        <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i> Firmas de los que autorizaron el muestreo</label> 

                                                                        {
                                                                             informationSampling.length > 0 && informationSampling.map(_find => {
                                                                                return    <Row className='pt-5' >
                                                                                <Col sm="6">
                                                                                <label> Responsable del muestreo </label>
                                                                                <br />
                                                                                <img width={"100%"} alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + _find?.firm_sampler} />
                                                                                </Col>
                                                                                <Col sm="6">
                                                                                <label>Firma del contacto/cliente </label>
                                                                                <img width={"100%"} alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + _find?.firm_client} />

                                                                                </Col>
                                                                            </Row>
                                                                             })
                                                                        }

                                                                     
                                                                        </Col>
                                                                      
                                                                    </TabPane>
                                                                </TabContent>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )}
                                        </TabPane>
                                   
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                   
                        <Card>
                            <div className='pt-4 pb-4 pr-4 pl-4' >
                                <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="1" className='p-l-0 p-r-0' style={{textAlign:"right"}} >
                                    <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/microType.svg")} style={{ width: "50px", height: "50px" }} />

                                    </Col> 
                                    <Col sm="5" className='pt-3' >
                                    <h6 className='txt-success f-w-600' >Listado de Muestras</h6>

                                    </Col>
                                </Row>
                                </Col>


                                <hr />
                            <Table className='b-r-5 table table-sm '>
                                <thead className='b-r-5' style={{textAlign : "center"}}>
                                        <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                        <th >Folio</th>
										<th >Tipo de Muestra</th>
										<th >Muestra</th>
                                        <th>Determinaciones</th>
                                        <th>Precio</th>
                                        <th>Convenio</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {groupedData.flat().map((item, index) => (
                                        <tr key={index} style={{ textAlign: "center" }}>
                                            <td className='txt-success f-w-600' >{item.folio}</td>
                                            <td>{item.name_mic_sample}</td>
                                            <td>{item.name_mic_sampling}</td>
                                            <td>{item.test_name}</td>
                                            <td>{item.price}</td>
                                            <td className='f-w-600' >{item.contract_number}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>

                            </div>
                           

                        </Card>
                        </Col>
                       
                   
                </Row>
                <Row>
                    <Col sm="12">
                    <Row>
                    <Col xl="3" className="box-col-6">
                        <div className="email-left-aside">
                            <Card>
                                <CardBody>
                                    <div className="email-app-sidebar left-bookmark">
                                        <div className="media">
                                            <div className="media-size-email">
                                                <img className="mr-3 rounded-circle" src={require("../../../assets/images/user/user.png")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <h5 className="f-w-600">{"Datos de Cobranza"}</h5>
                                              
                                            </div>
                                        </div>
                                        <Nav className="main-menu contact-options pt-4" role="tablist"  >
                                            <NavItem>
                                            <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i>Generar pago a</label> <br />
                                            <label className='f-w-300' >{isPayForCompany ? "Empresa" : "Paciente"} </label> <br />
                                            <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i>Generar factura a</label> <br />
                                            <label  className='f-w-300'>Empresa</label> <br />
                                            <label style={{fontSize:"12px"}} className='f-w-600  '><i className="fa fa-circle text-success"></i>Requiere factura: <label className='f-w-500  txt-danger' >{checkInvoice ? "Si" : "No"}</label> </label> 
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                   
                        <Col sm="9">
                        <Card>
                            <div className='pt-3 pb-3 pr-3 pl-3' >
                                <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col className='p-l-0 p-r-0' sm="1" style={{textAlign:"right"}} >
                                    <img alt="flask"  src={require("../../../assets/images/price/tab5.svg")} style={{ height: "34px" }} />
                                    </Col>
                                    <Col className='p-l-0 p-r-0 pt-2' sm="11">
                                    <h6 className='txt-success' >Resúmen de pago</h6>
                                    </Col>
                                </Row>
                                </Col>
                            <Row className='m-t-5 p-1'>
                                    <Col sm="12" md="6" lg="6" xl="6" className=' mt-2 pl-4'>
                                        <Row className='m-t-15'>
                                        <Table style={{ fontSize: "11px" }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: "#51bb25" }}>
                                                        <td colSpan={3} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                            Detalle de Pagos
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "5px", textAlign:"center" }}>
                                                            <label className=''>Método de pago</label>
                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>Monto</label>
                                                        </td>
                                                        <td style={{ padding: "5px" }}>
                                                            <label className=''>Acciones</label>
                                                        </td>
                                                    </tr>
                                                    {
                                                        listPayMethods.map((_paymethod, keyPayment) => {
                                                            return (
                                                                <tr key={`method-${keyPayment}`} className={_paymethod.deleted ? 'd-none' : ''}>
                                                                    <td>
                                                                        <Input type="select" required={true} className='form-control form-control-sm input-air-primary p-1' value={_paymethod.id_pay_method} data-id={keyPayment}
                                                                            onChange={(e) => handleDinamicInputWorkOrder(e, "id_pay_method", "listPayMethods")} disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}>
                                                                            <option value={0}>Selecciona una opción</option>
                                                                            {
                                                                                listPayMethod.map(_pMethod => {
                                                                                    if (checkTypeClient === 3 && isPayForCompany) {
                                                                                        
                                                                                        if (_pMethod.name === AvailablePayMethodByCompany) {
                                                                                            return (
                                                                                                <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                    else if (_pMethod.abbreviation !== "CONV") {

                                                                                        if (_adminFinance === "True" ) {
                                                                                            return (
                                                                                                <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            if (_pMethod.visibility_particular === true) {                                                                                                    
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        }                                                                                         
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input type='number' name="amount" data-id={keyPayment} value={_paymethod.amount} placeholder='$ 0.0' step="any"
                                                                            className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                            disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}
                                                                        />
                                                                    </td>
                                                                    <td className={validTotalCredit ? "d-none" : 'p-1 text-center' }  style={{ verticalAlign: "middle" }} colSpan={3}  >
                                                                        {

                                                                            (_paymethod.fully_paid !== undefined && !_paymethod.fully_paid) ?
                                                                                <Button type='button' className='btn-air-success' outline color='success' size='xs' onClick={() => handlePayBill(_paymethod.id_work_order_pay, _paymethod.amount)}>
                                                                                    <i className="fa fa-money"></i>
                                                                                </Button>
                                                                                :
                                                                                <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(keyPayment)}
                                                                                    disabled={(_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW > 0 ? false : true)}
                                                                                >
                                                                                    <i className='icofont icofont-ui-delete'></i>
                                                                                </Button>
                                                                        }
                                                                       
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                
                                            
                                                </tbody>
                                                {
                                                 !validTotalCredit  && 
                                                     <tfoot>
                                                     <tr>
                                                         <td colSpan={3} className="text-xs-center">
                                                             <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                         </td>
                                                     </tr>
                                                 </tfoot>
                                                }
                                            </Table>
                                        </Row>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="12" md="5" lg="5" xl="5" className=' p-l-0 mt-2'>
                                        <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                            <Col sm="12">
                                            <Table style={{ fontSize: "11px" }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: "#51bb25" }}>
                                                        <td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                            Detalle de movimientos
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                            <label className='f-12'>Subtotal</label>
                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>$ {number_format(subTotalW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                        <label style={{fontSize:"13px"}} className={valueTax === 16 ? 'f-w-600 txt-primary' : 'f-w-600 txt-secondary' }  >I.V.A({valueTax}%)</label>

                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>$ {number_format(totalTaxesW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                            <label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
                                                        </td>
                                                        <td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
                                                            <label style={{fontSize:"13px"}} className='f-w-600'>$ {number_format(totalW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </Table>
                                            </Col>
                                        </Row>
                                      
                                    </Col>
                                </Row>

                            </div>
                              

                        </Card>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                <br />
                <br />
            </div>
        </>
    );
};