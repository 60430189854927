import React from 'react';
import { Col,Button, CardBody,Row, ButtonGroup } from 'reactstrap'
import Blur from 'react-css-blur';
import { ToolTips } from '../../components/tooltips/ToolTips';
import './StyleDiscount.css'


export const CardItemSpecialDiscount = (props) => {
    
    const { handleUpdate, handleDelete, handleAuthorized, handleShowValidCode, methodsActions, items, idKey } = props;
    // console.log("🚀 ~ CardItemSpecialDiscount ~ items:", items)

    return (
        <>
            <Col sm="4" className={`text-center profile-details mb-0 p-b-0 ${!items.visibleTable && 'd-none'}`}>
                    {
                        methodsActions === true 
                        ?
                        
                        <Row className="mt-2 list">
                            <Col key={`card-${idKey}`} className='mt-3'>
                                <div className='project-box p-3 ribbon-wrapper-left'>
                                    <div className={`ribbon ribbon-clip ribbon-${items.id_authorized != null ? (items.used_at !== null ? "rev" : "aprov") : "inac" }`}>
                                        {items.id_authorized !== null ? (items.used_at !== null ? "Reclamado" : "Disponible") : "Sin autorizar"}
                                    </div>
                                <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                    <ButtonGroup>
                                        {
                                            items.id_authorized === null &&
                                            <Button outline className='btn' color='success' size='xs' onClick={() => handleAuthorized(items.id_special_discount)}>
                                                <i id={`tooltip-authorizedDiscount-${idKey}`} className="icofont icofont-ui-lock">
                                                    <ToolTips
                                                        placement="top"
                                                        dataTarget={`tooltip-authorizedDiscount-${idKey}`}
                                                        dataText={'Autorizar descuento'}
                                                    />
                                                </i>
                                            </Button>
                                        }
                                        {
                                            <Button outline className='btn' color='info' size='xs' onClick={() => handleUpdate(items.id_special_discount, (items.id_authorized === null ? "edit" : "view"))}>
                                                <i id={`tooltip-${items.id_authorized === null ? 'editDiscount' : 'viewDiscount' }-${items}`} 
                                                    className={`icofont ${items.id_authorized === null ? 'icofont-ui-edit' : 'icofont-eye-alt'}`}>
                                                        {/* <ToolTips
                                                            placement="top"
                                                            dataTarget={`tooltip-${items.id_authorized === null ? 'editDiscount' : 'viewDiscount'}-${idKey}`}
                                                            dataText={items.id_authorized === null ? 'Editar' : 'Visualizar'}
                                                        /> */}
                                                </i> 
                                            </Button>

                                            
                                        }
                                        {
                                            items.id_authorized === null &&
                                            <Button outline className='btn' color='danger' size='xs' onClick={() => handleDelete(items.id_special_discount)}>
                                                <i id={`tooltip-deleteDiscount-${idKey}`} className='icofont icofont-ui-delete'>
                                                    <ToolTips
                                                        placement="top"
                                                        dataTarget={`tooltip-deleteDiscount-${idKey}`}
                                                        dataText="Eliminar "
                                                    />
                                                </i>
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </div>
                               
                                <div className='text-center'>
                                    <span>
                                        <h5>
                                        <span className='codeSearch'>COD-00{items.id_special_discount}</span> / &nbsp;

                                            {/* {/ <span className='codeSearch'>COD-00{obj.is_special_discount}</span> / &nbsp; /} */}
                                            {
                                                !items.visibility ? ( 
                                                    <Blur cursor={items.id_authorized !== null ? 'pointer' : 'default'} radius="5px" transition='400ms' style={{ filter: "blur(5px)" }}>
                                                    <span 
                                                        onClick={items.id_authorized !== null ? () => handleShowValidCode(items.id_special_discount) : null} 
                                                        onCopy={(e) => { e.preventDefault(); return false; }}
                                                    >
                                                        CÓDIGO
                                                    </span>
                                                    </Blur>
                                                ) : (
                                                    <span>{items.code}</span>    
                                                )
                                            }

                                        </h5>
                                    </span>
                                </div>

                              

                                <Row>
                                    <Col sm="6">
                                        <div className='media'>
                                            <img className='img-35 mr-1 rounded-circle' src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} style={{ width: "22%" }} />
                                            <div className='media-body'>
                                                <label className='titleDiscount f-14'>Creado por:</label>
                                                <p className='f-12'><b>{ items.name_user_created }</b></p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className='media text-right'>
                                            <div className='media-body'>
                                                {
                                                    items.name_user_authorized !== null ?
                                                        <>
                                                            <p className="m-0 p-0 titleDiscount ">Autorizado por: &nbsp;</p>
                                                            <p className='f-12'><b>{ items.name_user_authorized }</b></p>
                                                        </>
                                                    :
                                                        <label className='tittleAut f-14'>Sin autorizar &nbsp;</label>
                                                }
                                            </div>
                                            <img className="img-35 mr-1 rounded-circle" 
                                                src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount2.svg")}  style={{ width: "22%" }}  
                                                alt="" 
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            </Col>
                        </Row>

                            
                        : ""
                    }
                       
        </Col> 
        </>
        
    )
    
}
