import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllCompanyBusiness() {
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Company/List`);

    if (response.code === 200)
        return response.data;
    else
        return response;
}