import React from 'react'
import { Col, Pagination, Row, Table } from 'reactstrap'
import { CardItemSpecialDiscount } from '../discounts/CardItemSpecialDiscount';

export const ListCardSpecialDiscounts = (props) => {

    const {
        methodsActions,
        handleUpdate = null, handleDelete = null, handleShowValidCode, handleAuthorized = null,
        nextPage = null, previousPage = null, totalPageCount, currentPage, goToPage, listSpecialDiscounts
    } = props;

    return (
        <>
            {
                nextPage !== null && previousPage !== null &&
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <Table>
                            <tbody>
                                <Pagination aria-label='...' style={{ justifyContent: "center" }}>
                                    <ul className='pagination pagination-primary'>
                                        {
                                            currentPage === 1
                                                ?
                                                <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                :
                                                <li style={{ cursor: 'pointer' }} className="page-item" onClick={previousPage}><span className='page-link'>Anterior</span></li>
                                        }
                                        {
                                            currentPage > 1 &&
                                            <>
                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage(1)} className="page-item"><span className='page-link'>1</span></li>
                                                {
                                                    currentPage > 2 &&
                                                    <>
                                                        <li className='page-item'><span className='page-link'>...</span></li>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage - 1)} className='page-item'><span className='page-link'>{currentPage - 1}</span></li>
                                                    </>
                                                }
                                            </>
                                        }
                                        <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage}</span></li>
                                        {
                                            currentPage < totalPageCount &&
                                            <>
                                                {
                                                    currentPage < (totalPageCount - 1) &&
                                                    <>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage + 1)} className="page-item"><span className='page-link'>{currentPage + 1}</span></li>
                                                        <li className='page-item'><span className='page-link'>...</span></li>
                                                    </>
                                                }
                                                <li style={{ cursor: "pointer" }} onClick={() => goToPage(totalPageCount)} className="page-item"><span className='page-link'>{totalPageCount}</span></li>
                                            </>
                                        }
                                        {
                                            totalPageCount === currentPage ?
                                                <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                :
                                                <li style={{ cursor: 'pointer' }} className='page-item primary' onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                        }
                                    </ul>
                                </Pagination>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
            <Row>
                {
                    listSpecialDiscounts.length > 0 &&
                    listSpecialDiscounts.map((obj, key) => {
                        return <CardItemSpecialDiscount
                            {
                            ...{
                                methodsActions,
                                handleDelete,
                                handleUpdate,
                                handleAuthorized,
                                handleShowValidCode,
                                
                            }}
                            
                            key={key}
                            items={obj}
                            {...props}
                            
                        />
                    })
                }

            </Row>
            <br />




        </>
    )
}
