import React, { Fragment } from 'react'
import { Button, Card, CardBody, Col, Container, Input, Row, Label, Table, Badge, FormGroup,ListGroup ,ListGroupItem,CardHeader,ButtonGroup} from 'reactstrap'
import { useMicSampling} from '../../../hooks/micro/useMicSampling'
import { ModalRecollected} from '../micSampling/ModalRecollected'
import { ModalPreviewSamplings } from '../micSampling/ModalPreviewSamplings'
import { CanvasComponent } from '../../../components/canvas/CanvasComponent';
import './styleMic.css';
import Select from 'react-select';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { ConfigServer } from '../../../data/config';
import { policyFood,policyWater,PolicyContainer } from '../../../constant/microsanitaria'
import FormatNumbers  from '../../../utils/formatNumbers';
import { ModalValidSamplings } from './ModalValidSamplings'
import { ModalCreateSample } from './ModalCreateSample'
import { cedimi,almas } from '../../../constant/globalConstantsTypeClients'
import { ListOptionsPaymentBy, OptionPaymentByCompany, OptionPaymentByPatient } from '../../../constant/workOrder';
import search from '../../../assets/images/search-not-found.png';




export const IndexMicSampling = () => {

    const {
       
        // * FORM SAMPLE
        loadingQuoter,formMicSampling,handleView,

        // * INPUTS
        handleNotCollected,handleToggleCollected,modalCollected,dataCollected,

        // * SAVE SAMPLINGS
        saveDetailSamplingsNotCollected,handleSearchSampling,handleResetSamplings,cancell_Samplings,

        // * SAMPLE FORM
        handleChangeSites,handleChangeArea,handleChangeSample,handleChangeQuantity,handleChangeObservations,
        handleRequiredTemperature,handleChangeThermometers,handleChangeTemperature,handleChangeSubject,

        // * LIST SAMPLES
        listSite,listArea,listSample,listThermometer,

        // * SAVE SAMPLINGS
        handleToggleSamplings,modalSamplings,handlePreviewSamplings,saveSamplings,loadingSamplings,

         // * VIEWS
        handleViewSamplings,handleDetermination,listNot,

        // * SAMPLING PLAN
        handleChangeFirm,repeatFirm,canvasRef1,canvasRef2,
        handleChangeObservationsPlan,handleChangeNumPlan,saveSamplingPlan,

        // * CALCULATE PRICES
        optionsTotal,total_tax,

         // * SAVE SAMPLINGS
        listPay,optionsPayment,handleAddPayMethod,
        handleDeletePayMethod,handleGiveChange,handleGiveMethod,saveMicroWorkorder,loadingMic,handleToggleValid,modalValid,
        handleChangePayMethod,
        
        // * INVOICES
        handleRequiredInvoice,

        // * QUATATION MIC
        handleNavigateSampling,

        // ? GLOBAL
        url_helen,nameBranch,
        
        // * MESSAGE SAMPLE
        handleNewSample,modalSample,toggleSample,handleText,saveCreateSample,

        // ! CONFIGURATION CEDIMI SAVE MICRO WORK ORDER
        saveSample,loadingCedimi,handleGoBack,customStyles
        
    } = useMicSampling();


    return (
        <Fragment>
            <Container fluid={true}>
            {loadingQuoter &&<Row className='mt-2'>
                <Col sm="12" md="12" lg="12" className='text-center'>
                <img alt="flask" className='animate__animated animate__pulse animate__infinite'  src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: "100px", height: "100px" }} />
                <br />
                <label className='txt-secondary f-w-600 f-18'  >Cargando</label>
                </Col>
            </Row>}
            {
                // ? LIST SAMPLINGS
            }
            <div className={!formMicSampling?.is_form ? "" : "d-none"}>
            {
                !loadingQuoter && <Row className='pt-2' >
                 
                    <Col sm="12" md="12" lg="12" xl={formMicSampling?.is_detail ? "8" : "8"} className='p-r-0'>
                    <Card>
                        {
                            // * MENU
                        }
                    <CardHeader style={{ paddingTop: "10px", paddingBottom: "10px" }} className='text-rigth'>
                        <Row className={formMicSampling?.is_view === 3 ? "d-none" : ""} >
							<Col sm="12" md="12" lg="4">
							<Button  type="button" color='light' onClick={() => handleView(1)} outline={formMicSampling?.is_view === 1 ? false : true}  className='btn-block round btn-air-secondary text-center'  >
								<div className='w-100'>
							<Col sm="12" style={{textAlign:"center"}}>
								<img alt="flask"  src={require("../../../assets/images/price/tab1.svg")} style={{ height: "34px" }} />
							</Col>
                                </div>
                                <span className='f-w-600 txt-secondary'>Recopilación de muestras</span>
                            </Button>
							</Col>
                            { // ! CONFIGURATION CEDIMI
                              (url_helen === cedimi && listNot.length === 0 ) && <Col sm="12" md="12" lg="4" className='pt-3' style={{textAlign:"center"}} >
                                <h6 className='txt-secondary' >Número de seguimiento:</h6>
                                <input disabled={formMicSampling?.firm_sampler_png !== null ? true : false} name="sampling_plan" value={formMicSampling?.sampling_plan} onChange={handleChangeNumPlan} className='input-borde-bajo' type="text" />
                              </Col>
                            }
                            { // ! CONFIGURATION CEDIMI
                              (url_helen === cedimi && listNot.length === 0 ) && <Col className={loadingCedimi ? "d-none" : ""}  sm="12" md="12" lg="4">
                                <Button  type="button" color='info' outline onClick={ () => saveSample()}   className='btn-block round btn-air-success text-center'  >
                                    <div className='w-100'>
                                <Col sm="12" style={{textAlign:"center"}}>
                                    <img alt="flask"  src={require("../../../assets/images/price/tab5.svg")}  style={{ height: "34px" }} />
                                </Col>
                                    </div>
                                    <span className='f-w-600 '>Generar Orden</span>
                                </Button>
							</Col>
                            }
                            <Col sm="12" md="12" lg="4" className={url_helen === cedimi ? "d-none" : "" }>
                                <Button  onClick={() => handleView(2)} outline={formMicSampling?.is_view === 2 ? false : true}  color="info" type="button"  className='btn-block round btn-air-secondary text-center'   >
								<Col className={url_helen === cedimi ? "d-none" : "" }  sm="12" style={{textAlign:"center"}}>
								<img alt="flask"  src={require("../../../assets/images/price/tab4.svg")} style={{ height: "34px" }} /> 
							</Col>
                                <span className={url_helen === cedimi ? "d-none f-w-600" : "f-w-600" } >Plan de muestreo</span>
                            </Button>
                            </Col>
                            <Col sm="12" md="12" lg="4" className={(formMicSampling?.firm_client_png  === null || formMicSampling?.firm_sampler_png === null) ? "d-none" :"" } >
                                <Button  onClick={() => handleView(3)} outline={formMicSampling?.is_view === 3 ? false : true} color="primary" type="button" disabled = {listNot.length > 0 ? true : false}className='btn-block text-center' >
								<Col sm="12" style={{textAlign:"center"}}>
								<img alt="flask"  src={require("../../../assets/images/price/tab5.svg")} style={{ height: "34px" }} />
							</Col>
                                <span className='f-w-600'>Información de pago</span>
                                </Button>
                            </Col>	
                        </Row>
                        {
                            // ? VIEW 3
                        }
                        <Row className={formMicSampling?.is_view === 3 ? "" : "d-none"}>
                            <Col sm="3" >
                            <div className="media profile-media" > 
								<img className="b-r-10 " src={ConfigServer.serverUrl + "/api/Configuration/Logo"} style={{ width: "80%" }} alt="Logo" />
								</div>
                            </Col>
                            <Col className='pt-3' sm="6" style={{textAlign:"center"}}>
                            <h6>HOJA DE CUSTODIA</h6>
                            </Col>
                            <Col sm="3" className='p-r-0 pt-3' style={{textAlign:"right"}}>
                            <label style={{fontSize:"14px",color:"#6c757dc9"}} className='f-w-600 '>{nameBranch}</label>
                            </Col>
                        </Row>
                    </CardHeader>
                    { // ? VIEW 1
                         formMicSampling?.is_view === 1 &&
                         <CardBody className='pricingtable' style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
                         <div className='table-wrapper-mic-samplings pt-4'>
                        { // ! CONFIGURATION CEDIMI -----save loading
                            (loadingCedimi && url_helen === cedimi)
                            ?
                            <>
                                <Row>
                                    <Col sm="12" md="12" lg="12" className='text-center pt-5'>
                                        <img alt='modal-preview' src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "10%" }} />
                                    </Col>
                                    <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                        <p className='txt-secondary'>Generando Admisión ...</p>
                                    </Col>
                                </Row>
                            </>
                            :
                            <Table className='b-r-5 table table-xs '>
                            <thead className='b-r-5' style={{textAlign : "center"}} >
                                <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
                                <th >Muestra</th>
                                <th >Tipo de muestra</th>
                                <th >Estado</th>
                                <th >Cancelar Muestra</th>
                                <th >Capturar Muestra</th>
                                </tr>
                            </thead>
                            <tbody>
                            {formMicSampling?.samples.length > 0 && formMicSampling?.samples.map((_item, index) => {		 									
                                return (
                                    <React.Fragment key={index}>
                                    <tr style={_item?.not_collected === true ? {backgroundColor:"#dc35452e"} : {}} >
                                        <td  style={{ textAlign: "center"}}>
                                        <Col sm="12" className='badge-detail-micro' >
                                          <Row>
                                            <Col sm="2" className='p-r-0' >
                                            <i className="fa fa-angle-right f-12"></i>
                                            </Col>
                                            <Col sm="10" style={{textAlign:"justify"}} >
                                            {_item?.folio ?? "--"}
                                            </Col>
                                          </Row>
                                          </Col>
                                        </td>
                                        <td  style={  _item?.not_collected ? { textAlign: "center", color:"#f17985 "} : { textAlign: "center", color:"#6c757dc4"}}>
                                        <label style={{fontSize:"10px"}} className='f-w-700'>{_item?.name_Sampling?.name ?? "-----"}</label>
                                        </td>	
                                        <td  style={{ textAlign: "center"}}>
                                        <Col sm="12" className={_item?.not_collected === true ? 'badge-detail-Micro-cancel': (_item?.id_mic_detail_sample ===  null) ? 'badge-detail-Micro-cancel2' : " badge-detail-Micro-status  "}  >
                                      <Row>
                                        <Col sm="2" className='p-r-0' >
                                        <i className="fa fa-circle-o "></i>     
                                        </Col>
                                        <Col sm="10" style={{textAlign:"justify"}} >
                                        {_item?.not_collected === true ? "Cancelado" : (_item?.id_mic_detail_sample ===  null) ? "Pendiente" : "Completo"  }
                                        </Col>
                                        </Row>
                                        </Col>
                                        </td>	
                                        <td  className='f-w-600' style={{ textAlign: "center", color:"#000"}}>
                                        {_item?.date_collected !== null &&  (_item?.date_collected ?? "---")}
                                        {!_item?.not_collected && _item?.id_mic_detail_sample === null  && <Button name='not_collected'  onClick={() => handleNotCollected(_item?.id_quoter_type_sample)} color=''  className='btn btn-xs btn-outline-danger'><i style={{ fontSize: 16 }} className="fa fa-square"></i></Button>}
                                        </td>	
                                        <td style={{textAlign:"center"}}>  
                                            <Badge onClick={() => _item?.id_mic_detail_sample ===  null ?  (handleSearchSampling(_item?.id_quoter_type_sample)) : handleViewSamplings(_item?.id_quoter_type_sample)}  style={ _item?.not_collected ?  { cursor: "pointer", pointerEvents: "none" ,opacity: 0.5} :  { cursor: "pointer" } }  color={_item?.id_mic_detail_sample ===  null ? 'primary' : "secondary"}  >
                                            
                                            <i style={{color:"#fff", fontSize:"11px"}} className={_item?.id_mic_detail_sample ===  null ? 'fa fa-edit' : 'fa fa-eye' }  ></i>	
                                            </Badge>		
                                       </td>
                                    </tr>    
                                    </React.Fragment>
                                    );
                                })}
                            </tbody>
                            </Table>
                        }
                         </div>
                         </CardBody>
                    }
                       { // ? VIEW 2   
                        formMicSampling?.is_view === 2 &&
                        <CardBody className='pricingtable' style={ formMicSampling?.firm_sampler_png === null ? { paddingTop: "1px", paddingLeft: "15px", paddingRight: "15px" } : {paddingTop: "1px", paddingLeft: "15px", paddingRight: "15px", height: "430px"}}>
                            <Col sm="12" className='pt-4'>
                           
                            <Row className='pt-3'>
                                <Col className='pt-3' sm="12" md="12" lg="4" style={{textAlign :"justify"}}>
                                <h6>Número de Plan de Muestreo:</h6>
                                <input disabled={formMicSampling?.firm_sampler_png !== null ? true : false} name="sampling_plan" value={formMicSampling?.sampling_plan} onChange={handleChangeNumPlan} className='input-borde-bajo' type="text" />
                                </Col>
                                <Col className='pt-3' sm="12" md="12" lg="8" style={{textAlign:"left"}}>
                                <h6>Observaciones Generales:</h6>
                                <textarea
										name="observations"
										className="form-control input-air-primary form-control-sm "
										value={formMicSampling?.observations}
										onChange={handleChangeObservationsPlan}
										style={{fontSize:"11px"}}
                                        disabled={formMicSampling?.firm_sampler_png !== null ? true : false}
									/>

                                </Col>
                            </Row>
                           
                            <Col sm="12" className='pt-4 p-l-0' style={{textAlign: "left"}}>
                            <h6>Muestras tomadas</h6>
                            </Col>
                            <div className='pt-1 mb-4' >
                                <Table className='b-r-5 table table-xs ' >
                                    <thead className='b-r-5' style={{textAlign : "center"}}>
                                        <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
										<th >N°</th>
										<th >Tipo de Muestra</th>
										<th >Descripción de la muestra</th>
										<th >Lugar de toma</th>
										<th >Hora</th>
										<th >Temperatura</th>
                                        <th >Cantidad</th>
                                        <th >Observaciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        formMicSampling?.samples.length > 0 && (
                                            (() => {
                                                let localCounter = 0;
                                                return formMicSampling.samples.map((_item, _key) => {
                                                    if (!_item?.not_collected && _item?.id_mic_sampling !== null) {
                                                        localCounter++; 
                                                        return (
                                                            <tr key={_key}>
                                                                <td>{localCounter}</td> 
                                                                <td>{_item?.name_Sampling?.name ?? "------"}</td>
                                                                <td>{_item?.name_mic_sample ?? "------"}</td>
                                                                <td>{_item?.name_mic_site ?? "------"}</td>
                                                                <td>{_item?.date_collected ?? "------"}</td>
                                                                <td>{_item?.temperature === "" ? "------" : _item?.temperature ?? "------"}</td>
                                                                <td>{_item?.quantity === "          " ? "------" : _item?.quantity ?? "------"}</td>
                                                                <td>{_item?.observations === "" ? "------" : _item?.observations ?? "------"}</td>
                                                            </tr>
                                                        );
                                                    }
                                                
                                                });
                                            })()
                                        )
                                    }
                                </tbody>
                                </Table>
                            </div> 
                            <Row className='pt-4'> 
                                <Col sm="12" md="12" lg="6" className={formMicSampling?.firm_sampler_png !== null ? "pt-4" : ""}>
                                <label className='f-w-600 txt-secondary' >Responsable del muestreo</label>&nbsp; <br />
                                {formMicSampling?.firm_sampler_png === null && <i onClick={() => repeatFirm(canvasRef1, "sampler")} id={`tooltip-responsable`} className="icofont icofont-fountain-pen txt-danger f-20">
                                    <ToolTips
                                        placement="top"
                                        dataTarget={`tooltip-responsable`}
                                        dataText={'Repetir firma del responsable del muestreo'}
                                    />
                                </i>}
                                {formMicSampling?.firm_sampler_png !== null  && <img alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + formMicSampling?.firm_sampler_png} />}
                                {formMicSampling?.firm_sampler_png === null &&<CanvasComponent
                                    canvasRef={canvasRef1}
                                    handleChange={(ref) => handleChangeFirm(ref, "responsable")}
                                    width={'820'}
                                    height={200}
                                    brushColor={"#000"}
                                    brushRadius={1}
                                    lazyRadius={3}
                                />}
                                </Col>
                                <Col sm="12" md="12" lg="6" className={formMicSampling?.firm_client_png !== null ? "pt-4" : ""} >
                                <label className='f-w-600 txt-secondary'>Firma del contacto/cliente</label>&nbsp; <br />
                                {formMicSampling?.firm_client_png === null &&<i onClick={() => repeatFirm(canvasRef2, "client")} id={`tooltip-contacto`} className="icofont icofont-fountain-pen txt-danger f-20">
                                    <ToolTips
                                        placement="top"
                                        dataTarget={`tooltip-contacto`}
                                        dataText={'Repetir firma del responsable del contacto/cliente'}
                                    />
                                </i>}
                                {formMicSampling?.firm_client_png !== null  && <img alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + formMicSampling?.firm_client_png} />}
                              
                                {formMicSampling?.firm_client_png === null && <CanvasComponent
                                    canvasRef={canvasRef2}
                                    handleChange={(ref) => handleChangeFirm(ref, "cliente")}
                                    width={'820'}
                                    height={200}
                                    brushColor={"#000"}
                                    brushRadius={1}
                                    lazyRadius={3}
                                />}
                                </Col>
                                {/* <Col sm="4">
                                <label className=' f-w-600 txt-secondary'>Firma de quien supervisa</label>&nbsp;
                                {formMicSampling.firm_sampler_png === null &&<i onClick={() => repeatFirm(canvasRef3, "superv")} id={`tooltip-supervisa`} className="icofont icofont-fountain-pen txt-danger f-20">
                                    <ToolTips
                                        placement="top"
                                        dataTarget={`tooltip-supervisa`}
                                        dataText={'Repetir firma del supervisador'}
                                    />
                                </i>}
                                {formMicSampling.firm_client_png !== null  && <img alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + formMicSampling.firm_client_png} />}
                                {formMicSampling.firm_client_png === null && <CanvasComponent
                                    canvasRef={canvasRef3}
                                    handleChange={(ref) => handleChangeFirm(ref, "supervisor")}
                                    width={'820'}
                                    height={200}
                                    brushColor={"#000"}
                                    brushRadius={1}
                                    lazyRadius={3}
                                />}
                                </Col>  */}
                            </Row>
                            </Col>
                            <Col className={formMicSampling?.firm_sampler_png === null ? "" : "d-none"}  sm="12"  style={{textAlign:"end"}}>
                            <ButtonGroup className='pt-3'>
                            <Button
								className=" btn-air-danger "
								color="secondary"
								size="sm"
								onClick={() => saveSamplingPlan()}
								><i className="icofont icofont-diskette f-14"></i>&nbsp;Guardar
							</Button>
                            <Button
								className=" btn-air-danger "
								color="danger"
								size="sm"
								><i className="icofont icofont-ui-close f-14"></i>&nbsp;Cancelar
							</Button>
                            </ButtonGroup>
                            </Col>
                        </CardBody>
                    }
                    { 
                        formMicSampling?.is_view === 3 &&
                        <>
                        {     
                            !loadingMic &&
                            <Col  sm="12" md="12" lg="12" xl="12" style={{textAlign :"left"}}  className='pricingtable'>
                             <div className='f-w-600' style={{backgroundColor:"#6c757d1a", height:"20px", textAlign:"center"}}>1.0 Datos del control</div>
                             <Row>
                                 <Col sm="6">
                                 &nbsp;<label className='f-w-600' style={{ fontSize: "11px" }}><i className="fa fa-circle text-info"></i> {"Empresa/Cliente:"}</label> <br />
                                 &nbsp;<label className='f-w-600'>N° Plan de Muestreo:&nbsp;&nbsp;{formMicSampling?.sampling_plan ?? "------"}</label>
                                 </Col>
                                 <Col sm="6">
                                 </Col>
                             </Row>
                              <br />
                             <div className='f-w-600' style={{backgroundColor:"#6c757d1a", height:"20px", textAlign:"center"}}>2.0 Datos del muestreo</div>
                             <div className='pt-4'>
                                 <Table className='b-r-5 table table-xs ' >
                                     <thead className='b-r-5' style={{textAlign : "center"}}>
                                         <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                         <th >N°</th>
                                         <th >Tipo de Muestra</th>
                                         <th >Descripción de la muestra</th>
                                         <th >Lugar de toma</th>
                                         <th >Hora</th>
                                         <th >Temperatura</th>
                                         <th >Cantidad</th>
                                         <th >Observaciones</th>
                                         </tr>
                                     </thead>
                                     <tbody>
                                     {
                                         formMicSampling?.samples.length > 0 && (
                                             (() => {
                                                 let localCounter = 0;
                                                 return formMicSampling?.samples.map((_item, _key) => {
                                                     if (!_item?.not_collected && _item?.id_mic_sampling !== null) {
                                                         localCounter++; 
                                                         return (
                                                             <tr key={_key}>
                                                                 <td>{localCounter}</td> 
                                                                 <td>{_item?.name_Sampling?.name ?? "------"}</td>
                                                                 <td>{_item?.name_mic_sample ?? "------"}</td>
                                                                 <td>{_item?.name_mic_site ?? "------"}</td>
                                                                 <td>{_item?.date_collected ?? "------"}</td>
                                                                 <td>{_item?.temperature === "" ? "------" : _item?.temperature ?? "------"}</td>
                                                                 <td>{_item?.quantity === "          " ? "------" : _item?.quantity ?? "------"}</td>
                                                                 <td>{_item?.observations === "" ? "------" : _item?.observations ?? "------"}</td>
                                                             </tr>
                                                         );
                                                     }
                                                 
                                                 });
                                             })()
                                         )
                                     }
                                 </tbody>
                                 </Table>
 
                             </div> 
                             <br /> <br />
                             <label className='f-w-600'>Observaciones generales, cambios o desviaciones:</label>&nbsp;{formMicSampling?.observations}
                              <br /> <br /> <br />   
                             <div className='f-w-600 ' style={{backgroundColor:"#6c757d1a", height:"20px", textAlign:"center"}}>3.0 Criterios de aceptación</div>
                             <label style={{fontSize:"10px"}}>{policyFood}</label>
                             <label style={{fontSize:"10px"}}>{policyWater}</label>
                             <label style={{fontSize:"10px"}}>{PolicyContainer}</label>
                             <br /> <br />
                             <Row>
                             <Col sm="12" md="12" lg="6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                 <div style={{ backgroundColor: "#6c757d1a", height: "25px", textAlign: "center", width: "100%" }}>Responsable del muestreo</div>
                                 <br />
                                 <div style={{ textAlign: "center", width: "400px", border: "1px solid #6c757d8f" }}>
                                     {formMicSampling?.firm_sampler_png !== null && 
                                     <>
                                        <img 
                                        width={"80%"} 
                                        alt="flask" 
                                        src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + formMicSampling?.firm_sampler_png}
                                        onError={(e) => {
                                            e.target.style.display = 'none'; 
                                            document.getElementById('image-not-found').style.display = 'block'; 
                                            }}
                                         />
                                          <div className="search-not-found text-center">
                                        {/* <div>
                                        <img src={search} alt="" className="second-search" />
                                        <p className="mb-0">{"No se encontro la firma"}</p>
                                        </div> */}
                                    </div>


                                     </>
                                     }
                                 </div>
                             </Col>
                             <Col sm="12" md="12" lg="6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                             <div style={{ backgroundColor: "#6c757d1a", height: "25px", textAlign: "center", width: "100%" }}>Firma del contacto/clienter</div>
                             <br />
                             <div style={{ textAlign: "center", width: "400px", border: "1px solid #6c757d8f" }}>
                                {
                                    formMicSampling.firm_client_png !== null &&
                                    <>
                                     <img
                                    width={"80%"}
                                    alt="flask"
                                    src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + formMicSampling?.firm_client_png}
                                    onError={(e) => {
                                    e.target.style.display = 'none'; 
                                    document.getElementById('image-not-found').style.display = 'block'; 
                                    }}
                                />
                                    <div className="search-not-found text-center">
                                        {/* <div>
                                        <img src={search} alt="" className="second-search" />
                                        <p className="mb-0">{"No se encontro la firma"}</p>
                                        </div> */}
                                    </div>

                                    </>
                                }
                               
                                </div>
                             </Col>
 
                             </Row>
                             <br /> <br /> <br /> <br />
                             <br /> <br /> <br /> <br />
                            </Col>
                        }
                        {
                            loadingMic &&
                            <>
                                <Row>
                                    <Col sm="12" md="12" lg="12" className='text-center'>
                                        <img alt='modal-preview' src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "10%" }} />
                                    </Col>
                                    <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                        <p>Generando admisión ...</p>
                                    </Col>
                                </Row>
                            </>
                        }
                        </>
                    }
                    </Card>
                    </Col>
                    { // ? VIEW 1
                        formMicSampling?.is_view === 1 &&
                            <Col   sm="12" md="12" lg="12" xl={formMicSampling?.is_detail ? "4" : "3"}  >
                        <Card style={{textAlign:"left", height:"490px"}} className="height-equal pricingtable pt-0 pb-0 pr-0 pl-0">
                        <div   className="contact-form">
                            <div  className="theme-form pl-0 pr-0 pt-3">
                           
                        {  formMicSampling?.is_detail && <Col sm="12" className='p-l-3 p-r-0'>
                        <Row>
                            <Col sm="8">
                            <h6 className='txt-primary' >Recolección de Muestra</h6>

                            </Col>
                            <Col sm="4">
                            <Col sm="12" style={{textAlign:"right"}} >
                                <Button outline color='dark' size='sm' onClick={handleGoBack} >Regresar</Button>
                                </Col>
                            </Col>
                        </Row>
                            <span className='d-block'>
                            <i className="fa fa-bookmark-o f-18 f-w-600"></i>&nbsp;&nbsp;<label className='f-w-600 f-14' style={{color: "#000"}} >{formMicSampling?.sample_Detail.folio}</label>   <br />
                            <i style={{color:"#6c757db8"}} className="fa fa-calendar-o f-16 f-w-600"></i>&nbsp;&nbsp;<label className='f-w-600' style={{color:"#6c757db8"}}>{formMicSampling?.sample_Detail.date_collected}</label>
                            </span>
                            <Col sm="12" >
                            <Row>
                                <Col sm="6" className='pointer' onClick={() =>handleDetermination(false)} style={{backgroundColor:"#dc3545", color:"#fff", height: "25px", padding:"3px"}}>
                                Detalle de muestra
                                </Col>
                                <Col sm="6" className='pointer' onClick={() =>handleDetermination(true)} style={{backgroundColor:"#0079C7",color:"#fff", height: "25px", padding:"3px"}}>
                                Determinaciones
                                </Col>
                            </Row>
                            </Col>
                            {!formMicSampling?.is_determination && <Col sm="12" className='p-l-0 p-r-0 pt-2' >
                            <Row>
                                <Col sm="6">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Tipo"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.name_Sampling.name ?? "---------"}</p>
                            </div>
                            </div>
                            </div>
                            
                                </Col>
                                <Col sm="6">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Sitio"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.name_mic_site ?? "-------"}</p>
                            </div>
                            </div>
                            </div>
                                </Col>
                            {
                                formMicSampling?.sample_Detail?.id_mic_site === 12 && <Col sm="12">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Sujeto Muestreado"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.subject ?? "-------"}</p>
                            </div>
                            </div>
                            </div>
                                </Col>
                            }

                                <Col sm="6">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Area"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.name_mic_site_areas ?? "-----"}</p>
                            </div>
                            </div>
                            </div>
                            
                                </Col>
                                <Col sm="6">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Descripción"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.name_mic_sample ?? "-------"}</p>
                            </div>
                            </div>
                            </div>
                                </Col>
                            
                                <Col sm="8">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-danger"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Observaciones"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.observations === "" ? "--------" : formMicSampling?.sample_Detail?.observations ?? "-------" }</p>                         
                            </div>
                            </div>
                            </div>
                                </Col>
                                <Col sm="4">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            {/* <div className="activity-dot-secondary"></div> */}
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Cantidad"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.quantity === "          " ? "------" : formMicSampling?.sample_Detail?.quantity}</p>
                            </div>
                            </div>
                            </div>
                            
                                </Col>

                            </Row>
                        {formMicSampling?.sample_Detail?.required_temperature &&<Row className='pt-3 pl-1'>
                                <Col sm="12" className='pl-4'>
                                <label style={{color:"#6c757db8"}} className='f-14 f-w-600 pl-4'>Temperatura</label> <br />
                                <Col sm="12">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-primary"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Termometro"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.name_thermometer ?? "-------"}</p>
                            </div>
                            </div>
                            </div>
                            
                                </Col>
                                <Col sm="12">
                                <div className="activity-timeline">
                            <div className="media">
                            <div className="activity-line"></div>
                            <div className="activity-dot-primary"></div>
                            <div className="media-body"><span className='txt-secondary f-w-600'>{"Temperatura"}</span>
                            <p className="font-roboto">{formMicSampling?.sample_Detail?.temperature ?? "--------"}°C</p>
                            </div>
                            </div>
                            </div>
                                </Col>
                                <br />
                                
                                </Col>
                            </Row>}

                              

                        
                            </Col>}
                            {formMicSampling?.is_determination && <Col sm="12" className='p-l-0 p-r-0 pt-2' >
                            <Col className='pt-4'>
                            {
                                formMicSampling?.sample_Detail?.list_Determinations.map((_obj, _key) => {
                                    return (
                                        <ListGroup>
                                            <ListGroupItem className="d-flex align-items-center list-group-item-action pt-1 pb-1 pr-0 pl-0">
                                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/typeService.svg")} style={{ height: "23px" }} /> <label style={{fontSize:"10px"}} className='f-w-500'>{_obj?.name ?? "-----"}</label> 
                                            </ListGroupItem>
                                        </ListGroup>
                                    )
                                })
                            }
                            </Col>
                            </Col> }              
                    </Col>}
                            </div>
                        </div>
                        </Card>
                            </Col> 
                    }  
                    {
                        // ? VIEW 2
                        formMicSampling?.is_view === 2 && 
                        <Col sm="12" md="12" lg="12" xl="3" className='p-r-0' >
                            <Card>
                            <div className="pricingtable pl-1 pt-1 pr-0 pb-0">
                                <div className="pricingtable-header">
                                <h6 className="title txt-secondary">{"Criterios de Aceptación"}</h6>
                                </div>
                                <ul className="pricing-content ">
                                    <li>{policyFood}</li>
                                    <li>{policyWater}</li>
                                    <li>{PolicyContainer}</li>
                                </ul>
                            </div>
                         </Card>
                        </Col>
                    } 
                    {   // ? VIEW 3 
                        formMicSampling?.is_view === 3 && 
                        <Col sm="12" md="12" lg="12" xl="4" className='p-r-0' >
                             <Card style={ loadingMic ? {filter : "opacity(0.5)" } : {}}   >
                             <Col sm="12" md="12" lg="12" xl="12"  style={{textAlign :"left"}}  className='pricingtable p-r-0 p-b-0' >
                             <Col sm="12" md="12" lg="12" className='pt-1'>
                             <h6 className='txt-secondary pt-2'>Detalles de movimiento</h6>
                             <div className='hr-detail-patient '></div>
                                    {formMicSampling?.payment_by_company && formMicSampling?.payment_by_patient &&<label className='txt-secondary pt-4'>Selecciona a quien se le cargara el pago</label> }
                                    { 
                                        (formMicSampling?.payment_by_company && formMicSampling?.payment_by_patient) &&
                                        <Select
                                            isDisabled={loadingMic }
                                            classNamePrefix='select'
                                            name="payment_by"
                                            options={ListOptionsPaymentBy}
                                            value={formMicSampling?.payment_company ? OptionPaymentByCompany : OptionPaymentByPatient }
                                            onChange={(e) => handleChangePayMethod(e)}
                                            placeholder={"Selecciona una opcion"}
                                            valid
                                            theme={
                                                (theme) => ({
                                                ...theme,
                                                colors: {
                                                ...theme.colors,
                                                primary25: '#898989'
                                                    }
                                                })
                                            }
                                        />
                                    }
                                    <Col sm="12" className='p-l-0 p-r-0 pb-5' >
                                    <Row className={formMicSampling?.is_company !== null ? "d-none" : ""}>
                                        <Col sm="12" className='p-l-0' >
                                        <Row>
                                            <Col sm="5" style={{textAlign:"center"}} className='p-r-0' >
                                            <i className='fa fa-circle txt-info'></i>
                                            <Label className='labelPatientSize'>&nbsp;Requiere factura</Label>&nbsp;
                                            </Col>
                                            <Col sm="6" className="p-l-0" >
                                             <Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input disabled={loadingMic ? true : false}  type="checkbox" name="require_invoice" onClick={handleRequiredInvoice} checked={formMicSampling.require_invoice} /><span className="slider round"></span>
                                            </Label>
                                            </Col>
                                        </Row>
                                        </Col>

                                    </Row>
                                    <Row className={formMicSampling?.is_company !== null ? "" : "d-none"} >
                                        <Col sm="6" className={formMicSampling.payment_company !== null ? 'pt-3' : 'pt-3 pb-4'}>
                                        <i className='fa fa-circle txt-info'></i>
                                        <Label className='labelPatientSize'>&nbsp;  Generar pago a</Label>&nbsp;
                                        <br />
                                        {
                                            (formMicSampling?.payment_company !== null && formMicSampling?.payment_company) && <b className='txt-info'>Empresa</b>
                                        }
                                        {
                                            formMicSampling?.payment_company !== null && !formMicSampling?.payment_company && <b className='txt-info'>Paciente</b>
                                        }       
                                        </Col>
                                       
                                      
                                        <Col sm="6" className={formMicSampling.payment_company !== null  ? 'pt-3' : 'pt-3 pb-4'}>
                                        <i className='fa fa-circle txt-info'></i>
                                        <Label className={formMicSampling?.payment_company !== null && !formMicSampling?.available_invoice_patient ? 'labelPatientSize' : "d-none"} >&nbsp;  Generar factura a</Label>&nbsp;
                                        <Label  className={formMicSampling?.payment_company !== null && !formMicSampling?.available_invoice_patient ? ' d-none' : "labelPatientSize"}>&nbsp;  Generar factura</Label>&nbsp;

                                        <br />
                                        {
                                       
                                        }
                                        {
                                            (formMicSampling?.payment_company !== null && formMicSampling?.require_invoice  && !formMicSampling?.available_invoice_patient) && <b className='txt-secondary'>Empresa</b>
                                        }
                                        {
                                            ( formMicSampling.is_company === null && formMicSampling?.payment_company !== null && !formMicSampling?.available_invoice_patient  )&& <b className='txt-secondary'>Paciente</b>
                                        }  
                                         <Label className={formMicSampling?.payment_company !== null && formMicSampling?.available_invoice_patient ? "switch" : "d-none"}  style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input disabled={loadingQuoter ? true : false}  type="checkbox" name="require_invoice" onClick={handleRequiredInvoice} checked={formMicSampling.require_invoice} /><span className="slider round"></span>
                                            </Label>
                                        </Col>
                                    </Row>
                                    </Col>
                                     
                                    </Col>
								
                              
                                
                             </Col>
                            </Card>
                             <Card>
                                <Col sm="12" md="12" lg="12" xl="12" style={{textAlign:"left"}} className='pl-4 pr-4 pt-4 p-b-0 pricingtable' >
                               
                                <Row className='pt-4' >
                                    <Col sm="12">
                                    <h6 className='txt-secondary pt-2'>Resúmen</h6>
								<div className='hr-detail-patient '></div>
                                <Row className="pb-4 pt-2">
                              
                                    <Col sm="12" md="12" lg="12" className='pt-1'>
                                    <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Acciones</label> <br /> 
									{
								   <div className='pointer' style={{backgroundColor :"#f8d62b", color:"#fff", paddingBottom:"5px", paddingTop:"1px", textAlign:"center"}} onClick={() => handleNavigateSampling(formMicSampling?.code)} ><i className="fa fa-file-pdf-o"></i> Editar Muestreo</div>
									}
                                    </Col>
                                </Row>
								<Row className='pt-1'>
									<Col sm="12" md="12" lg="12">
									<label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Resúmen del pago</label> 
									</Col>
								</Row >
								<div  className="table-responsive pt-1">
									<Table style={{ fontSize: "11px" }}>
										<thead>
											<tr style={{ backgroundColor: "#46AFE5" }}>
												<td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
													Detalle de movimientos
												</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td style={{ padding: "5px" }}>
													<label className='f-12'>Subtotal</label>
												</td>
												<td className="text-center" style={{ padding: "5px" }}>
													<label>$ {FormatNumbers(optionsTotal.subtotal,2)}</label>
												</td>
											</tr>
											<tr>
												<td style={{ padding: "5px" }}>
                                                <label style={{fontSize:"13px"}} className={total_tax === 16 ? 'f-w-600 txt-primary' : 'f-w-600 txt-secondary' }  >I.V.A({total_tax}%)</label>
												</td>
												<td className="text-center" style={{ padding: "5px" }}>
													<label>$ {FormatNumbers(optionsTotal.tax,2)}</label>
												</td>
											</tr>
											<tr>
												<td style={{ padding: "5px" }}>
													<label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
												</td>
												<td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
													<label style={{fontSize:"13px"}} className='f-w-600'>$ {FormatNumbers(optionsTotal.total,2)}</label>
												</td>
											</tr>
										</tbody>
									</Table>
								</div> <br />
                                    </Col>
                                   

                                    <br />
                                    <Col sm="12"  className='p-l-0 pt-4 pl-1 pr-1 pt-4' >
                                    <Row className={formMicSampling.payment_company !== null ? "" :"d-none"}>
                                        <Col sm="12" className='pb-4' style={{textAlign:"right"}} >
                                        <Button disabled={loadingMic ? true : false} type='button' className='btn-air-primary' outline color='primary' size='sm' onClick={handleAddPayMethod}>
                                            <i className='fa fa-plus'></i> Método de pago
                                        </Button>
                                        </Col>
                                    </Row>
                                    {
                                        formMicSampling.payment_company !== null &&
                                        <Table >
                                            <thead>
                                                <tr className='text-center border-bottom-info'>
                                                <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Método de pago</label></th>
                                                <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Monto</label></th>
                                                <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Acciones</label></th>
                                                <th className='p-1'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    optionsPayment?.listPayMethods.map((_payMethod,_key) => {
                                                    return(
                                                        <tr key={`method-${_key}`} className={_payMethod?.deleted ? 'd-none' : ''}>
                                                            <td>
                                                            <Input disabled={ (_payMethod.id_pay_method === 10 || loadingMic) ? true : false} type="select" required={true} className='form-control form-control-sm input-air-primary p-1' name='id_pay_method' data-id={_key}  onChange={(e) => handleGiveMethod(e, 'id_pay_method', 'listPayMethods')} value={_payMethod?.id_pay_method} >
                                                                <option value={0}>Selecciona una opción</option>
                                                                    {
                                                                    listPay.map(_method => {
                                                                        return (
                                                                        <option   value={_method?.id_pay_method} key={`method-${_method?.id_pay_method}`}>{_method?.name ?? "------"}</option>
                                                                        )  
                                                                    })
                                                                    }
                                                            </Input>
                                                            </td>
                                                            <td>
                                                            <Input
                                                                disabled={ (loadingMic || _payMethod.id_pay_method === 10) ? true : false}                                        
                                                                data-id={_key}
                                                                name="amount"
                                                                className='form-control input-air-primary form-control-sm'
                                                                placeholder='$ 0.0'
                                                                value={_payMethod.amount || ''}
                                                                onChange={(e) => handleGiveChange(e, 'amount', 'listPayMethods')}
                                                            />
                                                            </td>
                                                            <td style={{textAlign:"center"}}>
                                                                <i className='fa fa-trash-o txt-danger f-24 pointer' onClick={() => handleDeletePayMethod(_key)} ></i>
                                                            </td>
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                            </tbody>                     
                                        </Table>
                                          }
                                        <Col sm="12" className={formMicSampling.payment_company !== null ? "pt-2 pb-4" :"d-none"}  style={{textAlign:"right"}} >
                                        <Button className={ loadingMic ?"progress-bar-animated progress-bar-striped" :""}  disabled={loadingMic ? true: false} outline color='primary' size='sm' onClick={() => saveMicroWorkorder(2) }>
                                            Generar Orden
                                        </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                </Col> 
                            </Card>
                        </Col>
                    }
                </Row>
            }
            </div>
            {
                // ? SAMPLE FORM
            }
            {formMicSampling?.is_form  && <Col sm="12" className=" p-l-0 p-r-0 pt-5">
           
            {
                // ? FORM SAMPLINGS SAVE
            }
            <Card className='shadow pt-1'>
                <div className="pt-0 ribbon ribbon-clip-right ribbon-right ribbon-secondary"><label className='f-14'>{">"}</label>&nbsp;{formMicSampling?.sample_Form?.folio}</div>
                <Col sm="12" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {
                    // ? SAMPLE DATA
                } 
                <Row  >
                <Col sm="12" md="12" lg="9" className='p-l-0 p-r-0' >
                    <div style={{padding:"20px"}}  className="contact-form pt-0">
                        <div  className="theme-form pl-3" style={{ border: "1px solid #f4f4f4"}}>
                        <div  className="form-icon"><img alt="flask" className=" text-info mr-2" src={require("../../../assets/images/price/microType.svg")} style={{ width: "65px", height: "605px" }} />
                        </div>
                        <FormGroup>
                            <Label className='txt-primary f-14'>{"Datos de la muestra"}</Label>
                        </FormGroup>
                        <Col sm="12">
                        <Row>
                            <Col sm="12" md="12" lg="12" xl="3" className='p-r-0'>
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Tipo de muestra"}</label>
                                <Input disabled={true} style={{fontSize:"12px",backgroundColor:"#6c757d17",color:"#46afe5", fontWeight:"500", height:"40px"}} value={formMicSampling?.sample_Form?.name_Sampling.name} className="form-control"  type="text"  />
                            </FormGroup>
                            </Col>
                            <Col sm="12" md="12" lg="12" xl="3">
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Sitio"}</label>
                                <Select
									classNamePrefix="select"
									name="id_mic_site"
									value={formMicSampling?.sample_Form?.id_mic_site}
									placeholder="Selecciona un sitio"
									options={listSite}
									onChange={(e) => handleChangeSites(e)}		
                                    styles={customStyles}						
								/>
                            </FormGroup>
                            </Col>
                            {
                                formMicSampling?.sample_Form?.id_mic_site?.value === 12 && <Col>
                                   <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Sujeto Muestreado"}</label>
                                <Input value={formMicSampling?.sample_Form?.subject} onChange={handleChangeSubject}  style={{fontSize:"12px",height:"40px",color:"#46afe5"}} className="form-control" type="text"  />
                            </FormGroup>
                                </Col>

                            }
                            {url_helen !== almas && <Col sm="12" md="12" lg="12" xl="3">
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Area"}</label>
                                <Select
									classNamePrefix="select"
									name="id_mic_site_areas"
									value={formMicSampling?.sample_Form?.id_mic_site_areas}
									placeholder="Selecciona un sitio"
									options={listArea}
									onChange={(e) => handleChangeArea(e)}
                                    styles={customStyles}							
								/>
                            </FormGroup>
                            </Col>}
                            <Col sm="12" md="12" lg="12" xl="3" className={(formMicSampling?.sample_Form?.id_mic_site?.value === 12 && url_helen === cedimi) ? "pt-3" : ""} >
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Descripción de la muestra"} <i className="fa fa-edit f-18 pointer txt-secondary " onClick={handleNewSample}></i>  </label>
                                <Select
									classNamePrefix="select"
									name="id_mic_sample"
									value={formMicSampling?.sample_Form?.id_mic_sample}
									placeholder="Selecciona un sitio"
									options={listSample}
									onChange={(e) => handleChangeSample(e)}		
                                    styles={customStyles}					
								/>
                            </FormGroup>
                            </Col>
                            <Col sm="12" md="12" lg="12" xl="3" className={formMicSampling?.sample_Form?.id_mic_site?.value === 12 ? "pt-3" : ""}>
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Cantidad"}</label>
                                <Input value={formMicSampling?.sample_Form?.quantity} onChange={handleChangeQuantity}  style={{fontSize:"12px",height:"40px",color:"#46afe5"}} className="form-control" type="text"  />
                            </FormGroup>
                            </Col>
                            <Col className={formMicSampling?.sample_Form?.id_mic_site?.value === 12 ? "pt-3" : ""} sm="12" md="12" lg="12" xl={url_helen === almas ? "9" : (formMicSampling?.sample_Form?.id_mic_site?.value === 12 ) ? "6" :   "9"} >
                            <FormGroup>
                                <label style={{color:"#6c757db3"}} >{"Observaciones"}</label>
                                <Input value={formMicSampling?.sample_Form?.observations} onChange={handleChangeObservations} style={{fontSize:"12px",height:"40px",color:"#46afe5"}} className="form-control" type="text"  />
                            </FormGroup>
                            </Col>
                        </Row>
                        </Col>
                        </div>
                    </div>
                </Col>
                <Col sm="12" md="12" lg="12" xl="3" className='p-r-0 pt-3 p-l-0'>
                    <div style={{padding:"20px"}}  className="contact-form pt-0">
                        <div  className="theme-form pr-0 pl-2" style={{ border: "1px solid #f4f4f4"}}>
                        <div  className="form-icon"><img alt="flask" className=" text-info mr-2" src={require("../../../assets/images/price/determinations_Micro.svg")} style={{ width: "58px", height: "605px" }} />
                        </div>
                        <FormGroup>
                            <Label className='txt-primary f-14'>{"Lista de Determinaciones"}</Label>
                        </FormGroup>
                        <Col sm="12">
                        {
                            formMicSampling?.sample_Form?.list_Determinations.map((_obj,_key) => {
                                return (
                                    <ListGroup>
                                        <ListGroupItem className="d-flex align-items-center list-group-item-action pt-1 pb-1 pr-0 pl-0">
                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/typeService.svg")} style={{ height: "23px" }} /> <label style={{fontSize:"10px"}} className='f-w-500'>{_obj?.name ?? "-----"}</label> 
                                        </ListGroupItem>
                                    </ListGroup>
                                )
                            })
                        }
                        </Col>
                        </div>
                    </div>
                    <Col sm="12" style={{textAlign:"right"}} > <br />             
                        <ButtonGroup className='pt-3' >
                        <Button
							className=" btn-air-danger "
							color="secondary"
							size="sm"
                            onClick={() => handlePreviewSamplings()}
							><i className="icofont icofont-diskette f-14"></i>&nbsp;Guardar
						</Button>
                        <Button
							className=" btn-air-danger "
							color="danger"
							size="sm"
                            onClick={() => handleResetSamplings()}

							><i className="fa fa-reply f-14"></i>&nbsp;Regresar
						</Button>
                           
                            
                        </ButtonGroup>                      
                    </Col>
                </Col>
                </Row>
                <Col sm="12" lg="12" xl="12" md="12" className='p-l-0 p-r-0' >
                <Row>
                    <Col sm="12" md="12" lg="12" xl="9">
                    <div style={{padding:"20px"}}  className="contact-form pt-0 pl-0 pr-0 pb-2">
                        <div  className="theme-form pt-0" style={{ border: "1px solid #f4f4f4"}}>
                        <div  className="form-icon"><img alt="flask" className=" text-info mr-2" src={require("../../../assets/images/price/temperature_micro.svg")} style={{ width: "58px", height: "605px" }} />
                        </div>
                        <Col sm="12" className='p-l-0'>
                            <Row>
                                <Col sm="2" className='p-l-0'>
                                    <Label className='txt-primary f-14 f-w-600'>{"Temperatura"}</Label> <br />
                                    <Label className="switch mb-0">
                                    <Input
                                        type="checkbox"
                                        name="optionTermo"
                                        onChange={handleRequiredTemperature}
                                        checked={formMicSampling?.sample_Form?.required_temperature}
                                    />
                                <span className="slider round"></span>
                            </Label>
                        </Col>
                        {
                            formMicSampling?.sample_Form?.required_temperature  && <Col sm="10">
                            <Row>
                            <Col sm="12" md="12" lg="12" xl="6" className='p-r-0'>
                                <FormGroup>
                                    <label style={{color:"#6c757db3"}} >{"Termometro"}</label>
                                    <Select
                                        classNamePrefix="select"
                                        name="id_thermometer"
                                        value={formMicSampling?.sample_Form?.id_thermometer}
                                        placeholder="Selecciona un termometro"
                                        options={listThermometer}
                                        onChange={(e) => handleChangeThermometers(e)}		
                                        styles={customStyles}						
                                    />
                                </FormGroup>
                               
                                </Col>
                                <Col sm="12" md="12" lg="12" xl="3" className='p-r-0'>
                                <FormGroup>
                                    <label style={{color:"#6c757db3"}} >{"Temperatura °C."}</label>
                                    <Input onChange={handleChangeTemperature}  value={formMicSampling?.sample_Form?.temperature} disabled= {formMicSampling?.sample_Form?.id_thermometer ? false : true} style={ formMicSampling?.sample_Form?.id_thermometer ? {fontSize:"16px",color:"#46afe5", fontWeight:"500", height:"40px",textAlign:"center"} : {backgroundColor:"#6c757d17",height:"40px"}}   className="form-control" type="text"  />
                                </FormGroup>
                               
                                </Col>
                                <Col sm="12" md="12" lg="12" xl="3">
                                
                                <FormGroup>
                                    <label style={{color:"#6c757db3"}} >{"Temperatura Corregida °C"}</label>
                                    <Input disabled={true} style={{fontSize:"16px",backgroundColor:"#6c757d17",color:"#46afe5", fontWeight:"500", height:"40px", textAlign:"center"}} className="form-control"  value={ formMicSampling?.sample_Form?.temperature !== "" ? formMicSampling?.sample_Form?.factor_correction2 : formMicSampling?.sample_Form?.factor_correction} type="text"  />
                                </FormGroup>
                                </Col>
                              
                            </Row>
                            </Col>
                        }
                        
                        
                        </Row>
                        </Col>
                      <FormGroup>
                        </FormGroup>
                       
                     
                        </div>
                    </div>
                    </Col>
                 
                </Row>
                  
                </Col>  

       


                </Col>

            </Card>
            {
                // ? END FORM SAMPLINGS SAVE
            }
            </Col>
            }
            </Container>
                <ModalRecollected
                {
                    ...{modalCollected,handleToggleCollected,dataCollected,saveDetailSamplingsNotCollected}
                } 
                />
                <ModalPreviewSamplings
                {
                    ...{modalSamplings,handleToggleSamplings,saveSamplings,loadingSamplings,formMicSampling,cancell_Samplings}
                } 
                />
              <ModalValidSamplings
              {
                ...{ handleToggleValid,modalValid,listNot}
              }
              /> 
                <ModalCreateSample
                {
                    ...{modalSample,toggleSample,handleText,saveCreateSample}
                }

                />
        </Fragment>
    )
}