import React, { Fragment } from 'react'
import { Container, Card, } from 'reactstrap';
import './stylePbi.css';
import { usePowerBi } from '../../../hooks/Pbi/usePowerBi';

export const IndexPbi6 = () => {

    const {
        dataLink
    } = usePowerBi();

    const _order5 = dataLink.find(item => item.order === 5)?.link;
    console.log("🚀 ~ IndexPbi4 ~ _order4:", _order5)


    return (
         <Container fluid={true} >
                {
                    (_order5 === undefined || _order5 === '' ) ?
                        <Card className='shadow' >
                            <div style={{ textAlign: "-webkit-center" }} >
                                <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                                    <label className='f-w-600 f-14 badge badge-light-primary'>No se ha Cargado algún reporte que mostrar</label>
                            </div>
                        </Card>
                        :
                        <div className='embed-container'>
                            <iframe
                                id="inlineFrameExample"
                                src={dataLink.length > 0 && _order5}
                                frameborder="0"
                            >
                            </iframe>
                        </div>
                }
            </Container>
    )
}

