const validateNegative = (_price) => {

    let _negative = false

    if (_price < 0) {
        _negative = true
    }

    return _negative;
}
    
export default validateNegative;

