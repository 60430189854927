import React, { Fragment, useState } from 'react';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, CardHeader } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { IndexDoctors as ComponentDoctors } from '../../doctors/IndexDoctors';
import { TabDoctor, TabSpeciality } from '../../../constant';
import { IndexSpecialties } from '../../specialties/IndexSpecialties';

export const IndexDoctors = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}> <br />
                <Row>
                    <Col sm="3" className="tabs-responsive-side">
                        <Col sm="12">
                            <Card className="shadow">
                                <CardHeader className="p-2 bg-primary" >
                                    <h6>Médicos</h6>
                                </CardHeader>
                                <nav className="flex-column nav-pills border-tab nav-left">
                                    <NavLink
                                        href="#javascript" 
                                        role="tab" 
                                        className={`${activeTab === "1" ? 'active' : ''} mt-2`}

                                        onClick={() => toggleTab("1")}
                                    >
                                        <i className="fa fa-user-md"></i> { TabDoctor }
                                    </NavLink>
                                    <NavLink
                                        href="#javascript" 
                                        role="tab" 
                                        className={`${activeTab === "2" ? 'active' : ''} mt-2`} 
                                        onClick={() => toggleTab("2")}
                                    >
                                        <i className="fa fa-stethoscope"></i>{TabSpeciality}
                                    </NavLink>
                                    <br />

                                </nav>

                            </Card>
                        </Col>
                    </Col>
                    <Col sm="9">
                    <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <ComponentDoctors
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="2">
                                    <IndexSpecialties
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                            </TabContent>
                    </Col>








                    {/* <Col md="12">
                        <Card>
                            <Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className="fa fa-user-md"></i> { TabDoctor }</NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="fa fa-stethoscope"></i>{TabSpeciality}</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <ComponentDoctors
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="2">
                                    <IndexSpecialties
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Col> */}
                </Row>
            </Container>
        </Fragment>
    )
}
