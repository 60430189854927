import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { sendRequest } from "../requests/useRequest";
import { useSweetAlert } from "../sweetAlerts/useSweetAlert";
import { useSignalR } from '../signalR/useSignalR';

export const useGlobalNotifications = () => {
    const history = useHistory();
    const [notifications, setNotifications] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);  

    const [createSweet] = useSweetAlert();

    useEffect(() => {
        fetchNotifications();  
    }, [history]);

    const fetchNotifications = async () => {
        const response = await sendRequest("GET", "Notification/GetNotifications");
        if (response && response.data && Array.isArray(response.data.id)) {
            const transformedNotifications = response.data.id.map((id, index) => ({
                id: id,
                message: response.data.message[index],
                time: response.data.time[index],
                titleAlert: response.data.titleAlert[index],
            }));
            
            setNotifications(transformedNotifications);
            setUnreadCount(transformedNotifications.length); 
        } else {
            console.error("Respuesta inválida del servidor", response);
            setNotifications([]); 
            setUnreadCount(0);  
        }
    };
    
    const removeNotification = async (id) => {
        await handleRequest("POST", `Notification/CheckNotification/${id}`);
        
        setNotifications(notifications.filter((notification) => notification.id !== id));
        setUnreadCount(notifications.filter((notification) => notification.id !== id).length);  
    };
    

    const handleMessage = (message) => {
        if (message === "refresh") {
            fetchNotifications();
        } else if (message && message.notification) {
            setNotifications((prevNotifications) => [
                ...prevNotifications,
                message.notification,
            ]);
            setUnreadCount((prevCount) => prevCount + 1);  
        }
    };
   
    const { conexion } = useSignalR({
        url: "/hub/notification",
        funcionRespuesta: handleMessage,
    });

    const markAllAsRead = () => {
        setUnreadCount(0);  
    };

    
  const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
    let requestOptions = {
      method: method
    };

    if (body !== null) {
      requestOptions = {
        ...requestOptions,
        body: JSON.stringify(body)
      };
    }

    const response = await sendRequest(requestOptions, methodUrl);

    if (response.code === 200) {
      return response.data;
    }
    else if (response.code === 404) {
      createSweet("info", "info", "Atención", response.data.msg);
    }
    else if (response.code === 500) {
    }
    else {
    }
    return null;
  }


    return {
        notifications,
        hoveredId,
        setHoveredId,
        removeNotification,
        unreadCount, 
        markAllAsRead, 
    };
};













