const validatePrice = (_price) => {

    let _validate = false

    if (_price   === 0 || _price === "" || _price ===  null  || _price === undefined || _price === "0") {
        _validate = true
    }

    return _validate;
}
    
export default validatePrice;

