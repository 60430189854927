import React from 'react';
import { Col, Form, FormGroup, FormText, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { SearchAsync } from '../../components/elements/SearchAsync';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ModalButtonCancel } from '../../constant';

export const FormPrices = (props) => {

    const {
        toggle, loading, handleInputChange, price, from, to,
        validacionesPrice, methodAction, id_examProfile,
        examProfileState, handlePickerValues, validInputDate, validToInputDate, button,
        isCurve, curves, addCurve, editCurve
    } = props;

    const {
        id_examProfile_valid,
        price_valid,
        from_valid
    } = validacionesPrice;

    // ////console.log(curves);

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Nombre de la prueba <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <SearchAsync
                                value={id_examProfile}
                                name="id_examProfile"
                                method={handleInputChange}
                                loincState={examProfileState}
                                url={`Profiles/SearchProfileExam?just_exams=true`}
                                maxLenght={3}
                            />
                            <br />
                            <FormText color="danger" hidden={!id_examProfile_valid}>Debes seleccionar una opcion</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className={`${isCurve ? "d-none" : ""}`}>
                        <FormGroup>
                            <Label className="col-form-label">Precio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary"
                                type="number"
                                //placeholder="$ 0.00"
                                step="any"
                                min="0"
                                name="price"
                                value={price}
                                invalid={price_valid}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === '+' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    isCurve &&
                    <>
                        <Row>
                            <Col md="12">
                                <Button type='button' onClick={addCurve}>Agregar curva +</Button>
                            </Col>
                        </Row>
                        {
                            curves.map(obj => {
                                return (<Row key={obj.id_exam_curve_price}>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="col-form-label">Número de toma <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input type="number" min={2} step="1" name={`number_sampling_${obj.id_exam_curve_price}`} value={obj.number_sampling} onChange={(e) => editCurve(e, obj.id_exam_curve_price, "number")} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="col-form-label">Precio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input type="number" placeholder="$ 0.00" step="any" name={`price_${obj.id_exam_curve_price}`} value={obj.price} onChange={(e) => editCurve(e, obj.id_exam_curve_price, "price")} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                )
                            })
                        }
                    </>
                }
                {/* <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Precio <span style={{ color: "#ff0000" }}>*</span></Label>
                            <Input type="number" placeholder="$ 0.00" step="any" name="price" value={price} invalid={price_valid} onChange={handleInputChange} />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row> */}
                <Row>
                    <Col md="6">
                        <Label className="col-form-label"> Valido desde <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Datetime
                            inputProps={{
                                placeholder: 'dd-mm-aaaa hh:mm:ss',
                                name: 'from',
                                autoComplete: "off",
                                className: "form-control digits form-control-sm"
                            }}
                            timeFormat="HH:mm:ss"
                            timeConstraints={{
                                hours: { min: 0, max: 23 },
                                minutes: { min: 0, max: 59 },
                                seconds: { min: 0, max: 59 }
                            }}
                            value={from}
                            dateFormat={true}
                            locale="es"
                            onChange={(e) => handlePickerValues(e, "from")}
                            isValidDate={validInputDate}
                        />
                        <FormText color="danger" hidden={!from_valid}></FormText>
                    </Col>
                    <Col md="6">
                        <FormGroup className='p-1'>
                            <Label className="col-form-label"> Valido hasta</Label>
                            <Datetime
                                inputProps={{
                                    placeholder: 'dd-mm-aaaa hh:mm:ss',
                                    name: 'to',
                                    autoComplete: "off",
                                    className: "form-control digits form-control-sm",
                                    min: { from }
                                }}
                                timeFormat="HH:mm:ss"
                                timeConstraints={{
                                    hours: { min: 0, max: 23 },
                                    minutes: { min: 0, max: 59 },
                                    seconds: { min: 0, max: 59 }
                                }}
                                value={to}
                                dateFormat={true}
                                locale="es"
                                onChange={(e) => handlePickerValues(e, "to")}
                                isValidDate={validToInputDate}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
            
        </Form>
        
    )
}
