import React, { Fragment,useRef,useState} from 'react'
import Chart from 'react-apexcharts'
import {  Card, CardBody, CardHeader,Col,Media, Row,Table ,Container,NavItem,NavLink,Nav,TabContent,TabPane} from 'reactstrap'
import 'animate.css';
import { ToolTips } from '../../components/tooltips/ToolTips';

export const ComponentDetailPatient = (props) => {

    const {patient_excluded,handleClosedInformations,type_filter_person,number_format,total_patient,filterCon,filterSin,totalP,exportToExcel,navigateDetailPatient} = props;


    //* CONFIGURACIÓN DEL GRÁFICO DE BARRAS
    const nims = [];
    const data = [];
    const colors1 = [];

    patient_excluded.forEach(_find => {

      const amount = parseFloat(_find.amount) || 0;
      const nim = _find.nim ?? "";
      const isTrue = _find.patient_invoice === "False";

      data.push(amount);
      nims.push(nim);
      colors1.push(isTrue ? "#dc3545" : "#51bb25");
    });


    const apexMixedCharts = {
      series: [{
        name: 'Total',
        type: 'column',
        data: data,
        colorByPoint: true,  
      }],
      options: {
        chart: {
          height: 250,
          type: 'column', 
          stacked: false,
          toolbar: {
            show: true
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
          fill: {
          colors:[function ({ dataPointIndex }) {
              let colores = colors1
              return colores[(dataPointIndex ) % colores.length];
          }],
          type: 'gradient',
          gradient: {
              shade: 'light',
              type: 'vertical',
              shadeIntensity: 0.1,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 0.6,
              stops: [0, 100]
          }
      },
        xaxis: {
          labels: {
            show: false
          }
        },
        labels: nims,
        markers: {
          size: 0
        },
        tooltip: {
          shared: false,
          intersect: false,
        }
      }
    };

    //*CONFIGURATION TABLE
    const [activeTab, setActiveTab] = useState('1');

    const totalRow2 = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td ></td>
        <td ></td>
        <td style={{textAlign: "end"}} className='labeltheadInvoice'>Total:</td>
        <td style={{textAlign: "center"}}  className='labeltheadInvoice'>${number_format(totalP, 2)}</td>
        <td ></td>
        <td ></td>
        <td ></td>

    </tr> 
    );

    return (
        <Fragment>
        <Container fluid={true} className='p-l-0 animate__animated animate__fadeInRight ' >
          <Row> 
            {
              //!DETAIL PATIENTS 
            }
            <Col xl="9" md="12" className="box-col-12 p-r-0 scroll-container-B" >
              <div className="file-content">
                <Card className='shadow-detail-patient-table' style={{ height: "520px" }}  >
                  <CardHeader>
                  <Row>
                        <Col sm="9">
                        </Col>
                        <Col sm="1" className='p-l-0' >
                          <div id='btn-tooltip-excel' className='icon-div-user bg-success pointer' onClick={() => exportToExcel(data)}>   
                          <i className="fa fa-file-excel-o f-18">
                          <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-excel'
                            dataText={"Desargar Excel"}
                          />
                          </i>
                          </div>
                        </Col>
                        <Col sm="1" className='p-l-0' >
                          <div id='btn-tooltip-filter-public' className='icon-div-user bg-warning pointer' >
                          <i className="fa fa-users f-18">
                          <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-filter-public'
                            dataText={"Total:" + " " + total_patient + " " + "Pacientes"}
                          />
                          </i>
                          </div>
                        </Col>
                        <Col sm="1" className='p-l-0' >
                        <div id='btn-tooltip-detail' className='icon-div-user bg-secondary pointer' onClick={handleClosedInformations} >
                        <i className=" icofont icofont-arrow-left bg-secondary f-20 ">
                        <ToolTips
                            placement="top"
                            dataTarget='btn-tooltip-detail'
                            dataText='Regresar'
                          />
                        </i>
                        </div>
                        </Col>
                      </Row>
                  </CardHeader>
                    {
                      type_filter_person ==="P" ?
                      <div style={{backgroundColor: "#fff",  borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}}>
                         <Table striped size="sm" >
                                  <thead className='b-r-5' style={{textAlign : "center"}} >
                                   <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
                                     <th style={{ backgroundColor: "#e6f3ff", color: "#343a40d6", fontWeight: "bold" }}>Fecha</th>
                                     <th style={{ backgroundColor: "#e6f3ff", color: "#343a40d6", fontWeight: "bold" }}>Nim</th>
                                     <th  style={{ backgroundColor: "#94bcd5", color: "#fff", fontWeight: "bold" }}>Paciente</th>
                                     <th  style={{ backgroundColor: "#94bcd5", color: "#fff", fontWeight: "bold" }}>Sucursal</th>
                                     <th style={{ backgroundColor: "#002940b3", color: "#fff", fontWeight: "bold" }}>Total</th>
                                     <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold" }}>Solicito</th>
                                     <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold" }}> Autorizo</th>
                                     <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold",textAlign:"center" }}>Factura</th>
                                   </tr>
                                 </thead>
                                 <tbody>
                                  {
                                  patient_excluded.map((_find, _key) => (
                                  
                                    (filterCon && _find.patient_invoice === "True") ||
                                    ((!filterCon && !filterSin) || (_find.patient_invoice === "False" && filterSin)) && (
                                      <tr key={_key}>
                                        <td>{_find?.date_workorder ?? "------"}</td>
                                        <td className={ _find.patient_invoice === "False" ?  "f-w-500 txt-danger" : _find.patient_invoice === "False" ? 'f-w-500 text-warning ' : 'f-w-700 text-warning pointer ' }  onClick={ _find.patient_invoice === "False" ? "": () => navigateDetailPatient(_find?.id_workOrder) }  >{_find?.nim ?? "----"}</td>
                                        <td>{_find?.patient ?? "------"}</td>
                                        <td style={{textAlign: "center"}}>{_find.name_branch ?? "------"}</td>
                                        <td style={{textAlign: "center"}} className={_find.patient_invoice === "False" ? "f-w-700 txt-danger" : "f-w-700 txt-success"}>
                                          ${number_format(_find?.amount, 2) ?? 0}
                                        </td>
                                        <td style={{textAlign:"center"}} >{_find?.user_required ?? "------"}</td>
                                        <td style={{textAlign:"center"}}>{_find?.authorized ?? "------"}</td>
                                        <td style={{textAlign:"center"}}>{_find.patient_invoice === "False"  ? "SI" : "NO" ?? false}</td>
                                      </tr>
                                    )
                                  ))
                                  }
                                  {totalRow2}
                                  </tbody>
                                  </Table>

                      </div>
                      :
                      <div>
                      </div>

                    }                   
              
                </Card>
              </div>
            </Col>
            {
              //!CONFIGURATION GRAPH AND HISTORIC 
            }
            <Col xl="3" md="12" className="box-col-12 p-r-0">
              <div id="mixedchart">
              <Chart options={apexMixedCharts.options} series={apexMixedCharts.series} type="line" height={250} />
              </div>
              <Card className='shadow' style={{ height: '255px', overflowY: "scroll"}} >
              <Nav tabs className="nav  border-tab nav-success">
                  <NavItem  id="myTab" role="tablist">
                    <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                      {"Historico"}
                    </NavLink>
                  </NavItem>
                  <NavItem  id="myTab" role="tablist">
                    <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                      {"Detallado"}
                    </NavLink>
                  </NavItem>             
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                <div className="people-list">
                    <ul className="list digits custom-scrollbar">
                      {
                      patient_excluded?.map((_invoices,_key) => {
                        if (_invoices.patient_invoice === "True") {
                          return;
                        }
                      return(
                      <li className="clearfix" key={_key} >
                        <div className='contenedor-prueba-1 '>
                        <Media style={{width :"80px" , filter:"grayscale(1)" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                        </div>
                        <div className="about" style={{textAlign : "center"}} >
                          <div className="name">{_invoices?.nim ?? "----"}
                            <div className="status-patient">
                            <i className="fa fa-reply font-danger"></i> {"Paciente que requirio factura"}
                            </div>
                          </div>
                        </div>
                      </li>
                      )
                        } )
                      }    
                      </ul>
                      </div>
                  </TabPane>
                  <TabPane tabId="2">
                      <div className="status">
                      <hr />            
                      <hr />
                      </div>
                  </TabPane> 
                  </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>   
        </Fragment>
    )
}
