import React, { Fragment ,useState,useEffect } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Alert,Media,Nav, NavLink,Table ,FormGroup,Badge } from 'reactstrap'
import ApexCharts from 'react-apexcharts'
import { useBankingDeposits } from '../../hooks/reports/useBankingDeposits'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactApexChart from 'react-apexcharts'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ModalAutorizationInvoices } from './ModalAutorizationInvoices';
import { ModalCredit } from './ModalCredit';



export const IndexBankingDeposits = () => {


      const {
            //*FILTER DAY
            start_date, end_date,handleInputChange,

            //*FORMVALUES DEPOSITS
            received,delivere,amount,observation, date_1,date_2,one,range,

            //*METHODS 
            GetReportDeposits,listMovements,indicatorAmountInvoice,listMovementsCalcule,

            //*CHART BANKING
            CurrentlyDeposits2,listMovementsInvoice,

            //* FORMAT NUMBERS
            number_format,

            //*LOADINGS
            loading,

            //*SAVE CALCULATE RECEIVED
            saveReceived,disabledSaveButton,
            
            //*VALIDATION
            validationsSG,

            //*ONCHANGE CALENDER
            date,onChange,validNotification,tileClassName,

            //*VIEW RECEIVED
            GetDeposits,

            //*VIEW CREDIT, FULLY PAID
            indicadorFullyPaid,ChartPaymentCancell,handleChangeTypeAction,type_action, listFilter,
            
            //*EXPORT EXCEL
            MakeExcelInvoices,
            
            //*FILTER COMPANY
          totalDepositSend,

            //*DETAIL BRANCH
            listDetailBranch,brach,listDetailBranchInvoice,

            //*CHART BRACH
            ChartPaymentConditionsTable,

            //*CHART AND INFO DEPOSITS
            arrayDeposits,handleActions,_validationFinance,detailBranch,loadingFinance,navigateCalender,

            // ? PAYMENT
            dataComplement,totalComplement,handleViewDetalil,isComplement,handleClosedViewDetalil,
            invoiceBranch,monthInvoices,

            // ? ------
            handleSelectBranch,handleSelectMonth,handleSelectAge,getInformationInvoices,listInvoices,
            // ?
            checkedItems,selectAll,handleCheckboxChange,handleSelectAllChange,exportToExcel,saveInvoices, modalAutorization,toggleModalAutorization,handleInvoices,
            handleSelecUser,handleSelectPassword,validatePassword,loadingServices,username,
            
            // ? CORTESY
            dataCortesy,

            // ? CONFIGURATION
            close,modalProps,modalFullRef,handleReport,
            // ?
            GetReportCredit,companyInfo,formatter,navigateNim



        } =useBankingDeposits();

      
        //!TOTAL PRICE

        const [totalCollected, setTotalCollected] = useState(0);
        const [totalGlobal, setTotalGlobal] = useState(0);
        const [totalRecovered, setTotalRecovered] = useState(0);
        const [totalProgramming, setTotalProgramming] = useState(0)
        const [totalAmount, setTotalAmount] = useState(0);
        const [paymentMethod, setPaymentMethod] = useState('');
        const [isGeneralPublic, setIsGeneralPublic] = useState('');
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);





        useEffect(() => {
          const calculateTotals = () => {
            let collected = 0;
            let global = 0;
            let recovered = 0;
            let totalB = 0;

            detailBranch.flatMap((_branch) => 
              _branch.children.map((_child) => {
                const branchDetails = _child.id && listDetailBranch[_child.id]
                  ? listDetailBranch[_child.id].filter(_detail => _detail.id_branch === _child.id && _detail.id_pay_method === 1)
                  : [];

                const detail = branchDetails.length > 0 ? branchDetails[0] : null;
                const total = detail ? (detail.amount - _child.amount_collected) : 0;

                collected += _child.amount_collected;
                recovered += detail ? detail.amount : 0;
                global += total;
                totalB += _child.amount;


                return _child; 
              })
            );
            setTotalCollected(collected);
            setTotalGlobal(global);
            setTotalRecovered(recovered);
            setTotalProgramming(totalB)
          };


          calculateTotals();
        }, [detailBranch, listDetailBranch]);

        const financeAmount = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer'>
            <td className='label-finance'>Totales Globales:</td>
            <td className='label-finance'>
            ${number_format(totalRecovered ?? 0, 2)}
            </td>
            <td className='label-finance'>
            ${number_format(totalProgramming ?? 0, 2)}
            </td>
            <td className='label-finance'>
            ${number_format(totalCollected ?? 0, 2)}
            </td>
            <td className='label-finance'>
            ${number_format(totalGlobal ?? 0, 2)}
            
            </td>
          </tr>
        );
      

        //!CONFIGURATION AMOUNT
        useEffect(() => {
          let sum = 0;
          brach.forEach((_find) => {
            if (_find.id_branch && listDetailBranch[_find.id_branch]) {
              listDetailBranch[_find.id_branch].forEach((item) => {
                if (item?.id_pay_method === 29) {
                  return;
                }
               
                sum += item.amount;
              });
            }
          });
          setTotalAmount(sum);
        }, [brach, listDetailBranch]);


        //*VALID SIZE
        let _valid2 = ChartPaymentCancell?.series.length === 1 ? 480 : 610


        //*VALID CLASS PAYMETHOD
        //!CONFIGURATION PAYMETHOD
        function getClassForPayMethod(id_pay_method) {
          switch (id_pay_method) {
            case 1:
              return "efectivo";
            case 2:
              return "tarjeta-credito";
            case 4:
              return "tarjeta-debito";
            case 8:
              return "transferencia-electronica";
              case 10:
                return "credito-pay"
              case 27:
                return "metodo-ppd";
            default:
              return "paypal-method"; 
          }
        }

        //!CHART PAYMETHOD
        function getClassForPayMethod1(id_pay_method) {
          switch (id_pay_method) {
            case 1:
              return  "#11ff00";
            case 2:
              return "#ff3c00";
            case 4:
              return '#ffd900';
            case 8:
              return "#8c00ff";
              case 10:
                return "#fa182e";
              case 27:
              return "#fc6271d1";
            default:
              return "#183462"; 
          }
        }

        //!VALIDATION FINALLY
        //*VALID AMOUNT CASH
        const totalRow = (
                <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
                <td ></td>
                <td className='labeltheadInvoice'>Total:</td>
                <td  className='labeltheadInvoice'>${number_format(totalAmount, 2)}</td>
                <td ></td>
            </tr> 
        );
        const totalRowComplement = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
          <td ></td>
          <td ></td>
          <td className='labeltheadInvoice'>Total:</td>
          <td  className='labeltheadInvoice'>${number_format(totalComplement, 2)}</td>
          <td ></td>
          <td ></td>
      </tr> 
       );
        

        //*VALID AMOUNT INVOICE
        const totalRow2 = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
          <td ></td>
          <td className='labeltheadInvoice'>Total:</td>
          <td  className='labeltheadInvoice'>${number_format(indicatorAmountInvoice, 2)}</td>
          <td ></td>
      </tr> 
        );
    
    
        const handleFilterChange = (e) => {
          setPaymentMethod(e.target.value);
        };

        const handlePublicChange = (e) => {
          setIsGeneralPublic(e.target.value);
        };

        const paymentMethods = {
          1: "Efectivo",
          2: "Tarjeta de Credito",
          4: "Tarjeta de Débito",
          8: "Transferencia electronica",
          0: "Otro"
        };

        
        const _filter_invoices = (Array.isArray(listFilter) ? listFilter : []).filter(_find => {
          const _type_invoices = paymentMethod ? paymentMethods[_find?.id_pay_method] === paymentMethod : true;
          const _type_person = isGeneralPublic ? (isGeneralPublic === "Publico General" ? _find?.isGeneralPublic : !_find?.isGeneralPublic) : true;
          return _type_invoices && _type_person;
        });

        const _totalAmount = _filter_invoices.reduce((acc, item) => acc + (item.total_i || 0), 0);

        const invoicesAmount = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer'>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className='label-finance'>Totales Globales:</td>
              <td className='label-finance'>
                 ${number_format(_totalAmount ?? 0, 2)}
              </td>
              <td></td>

          </tr>
        );
    
        const _totalByPaymentMethod = _filter_invoices.reduce((_ind, _total) => {
            let id_pay_method = _total?.id_pay_method;
            let total = _total?.total_i || 0;
        
            if (_ind[id_pay_method]) {
              _ind[id_pay_method] += total;
            } else {
              _ind[id_pay_method] = total;
            }      
            return _ind;
        }, {});

  
      
      
      
      const filteredInvoices = selectedPaymentMethod
        ? listInvoices.filter(invoice => {
            const payMethod = Array.isArray(invoice.pay_methods) ? invoice.pay_methods[0] : invoice.pay_methods;
            return payMethod === selectedPaymentMethod;
          })
        : listInvoices;

        const totalAmount2 = filteredInvoices.reduce((sum, invoice) => sum + (invoice.amount ?? 0), 0);

        const invoicesAmount2 = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer'>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className='label-finance'>Totales Globales:</td>
              <td className='label-finance'>
                 ${number_format(totalAmount2 ?? 0, 2)}
              </td>

          </tr>
        );

        const _totalByPaymentMethods = filteredInvoices.reduce((acc, invoice) => {
          const payMethod = Array.isArray(invoice.pay_methods) ? invoice.pay_methods[0] : invoice.pay_methods;
          const methodName = paymentMethods[payMethod] || 'Otro'; 
          acc[methodName] = (acc[methodName] || 0) + (invoice.amount ?? 0);
          return acc;
        }, {});

        // ? CHART   ------new 
        const paymentMethods2 = {};

        filteredInvoices.forEach(invoice => {
          const payMethod = Array.isArray(invoice?.pay_methods) ? invoice?.pay_methods[0] : invoice?.pay_methods;
          let methodName;
        
          switch (payMethod) {
            case 1:
              methodName = 'Efectivo';
              break;
            case 2:
              methodName = 'Tarjeta de Crédito';
              break;
            case 4:
              methodName = 'Tarjeta de Débito';
              break;
            case 8:
              methodName = 'Transferencia Electrónica';
              break;
            case 10:
              methodName = 'Crédito';
              break;
            case 27:
              methodName = 'Método PPD';
              break;
            default:
              methodName = 'Mercado Pago';
              break;
          }
        
          paymentMethods2[methodName] = (paymentMethods2[methodName] || 0) + invoice.amount;
        });
        
        const labels = Object.keys(paymentMethods2);
        const data = Object.values(paymentMethods2);
               
      const chartOptions = {
        chart: {
          type: 'donut',
          height: 350,
        },
        labels: labels,
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
              total: {
                show: false,  
              },
            },
          },
        },
        tooltip: {
          y: {
            formatter: (val) => `$${val.toLocaleString()}`, 
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
          },
        },
        fill: {
          colors: ['#FFC107', '#0079C7', '#07e357', '#f55463', '#D0E6A5', '#FFC107'],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
      };

      const chartSeries = data;

      const chartOptionss = {
        chart: {
          type: 'column', 
        },
        title: {
          text: null, 
        },
        xAxis: {
          categories: ['Ingreso Total','Ingreso B'], 
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Monto', 
          },
          labels: {
            formatter: function () {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(this.value); 
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.series.name}: ${number_format(this.y)}`;
          },
        },
        series: [
          {
            name: 'Ingreso Total', 
            data: [totalRecovered], 
          },
          {
            name: 'Ingreso B', 
            data: [totalCollected],
          },
        ],
      };


      




      
   

    return (
        <Fragment>
          {
           (_validationFinance && loadingFinance) && 
                <Col sm="12" md="12" lg="12" className='text-center pt-4' style={{textAlign :"center"}}>
                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'120px' }} /> <br /> <br />
                <label className='txt-secondary f-18'>Cargando.....</label>
                </Col>
          }
            <Container fluid={true} className={ _validationFinance ? loadingFinance ? "d-none" : ' p-r-0 mb-5' : ' p-r-0 mb-5'  }>
              {
                // !MENU ACTION
              }
              <div className="customizer-links">
                  <Nav className="flex-column nac-pills">
                      <NavLink  onClick={() => handleChangeTypeAction("R")} >
                          <div className="settings"  >
                          <i className="icofont icofont-file-pdf"></i>               
                                     </div>
                          <span>{"Pendientes a facturar"}</span>
                      </NavLink>
                      <NavLink  onClick={() => handleChangeTypeAction("F")} >
                          <div className="settings" >
                          <i className="fa fa-file-pdf-o"></i>
                          </div>
                          <span>{"Detallado de Facturas"}</span>
                      </NavLink>
                      <NavLink onClick={() => handleChangeTypeAction("D")}  >
                          <div className="settings" >
                          <i className="fa fa-credit-card"></i>
                          </div>
                          <span>{"Generar Deposito"}</span>
                      </NavLink>
                      <NavLink onClick={() => handleChangeTypeAction("A")}  >
                          <div className="settings" >
                          <i className="fa fa-calendar"></i>
                          </div>
                          <span>{"Dashboard"}</span>
                      </NavLink>
                    
                  </Nav>
              </div>
              {
                //*DASHBOARD CASH AND INVOICE
              }
              <Row className={type_action === "A" ? 'dashboard-sec box-col-12' :  (["F", "R", "C", "D"].includes(type_action) ? 'd-none' : 'dashboard-sec box-col-12')} >
                <Col sm="12" style={{textAlign: "center"}}>
                    { 
                      loading &&    <i className="fa fa-spin fa-spinner font-primary f-46"></i>
                    }
                   
                  </Col>
                 
                  {
                    //* CHART EGRESS
                  }
                  <Col sm="12" >
                  <Row>
                
                    <Col sm="12" className=''>
                    <Card style={ {height :"500px"}} >
                      <Row>
                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 1 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 1 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-E'>
                              <b className='f-14'>E</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 1 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary '>
                                      {
                                        _validationFinance === "True" ?
                                        <b className='f-12'> ${number_format(totalGlobal, 2)}</b>
                                        :
                                        <div>
                                           <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                        <br />
                                        {_validationFinance &&<b className='f-12 f-w-600 txt-success' >${number_format(totalGlobal,2)}</b>}

                                        </div>
                                       

                                      }
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 1 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col>
                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 10 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 10 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div ref={modalFullRef}   onClick={() => handleReport()}  className='card-validateFinance-C pointer'>
                              <b className='f-14'>C</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 10 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary '>
                                      {
                                        _validationFinance === "True" ?
                                        <b className='f-12'> ${number_format(totalGlobal, 2)}</b>
                                        :
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      }
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 10 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col>
                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 2 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 2 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-TC'>
                              <b className='f-12'>T.C</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 2 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary'>
                                      <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 2 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col>
                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 4 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 4 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-TD'>
                              <b className='f-12'>T.D</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 4 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary'>
                                      <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 4 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'> 
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col>

                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 8 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 8 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-TE '>
                              <b className='f-12'>T.E</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 8 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary'>
                                      <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 8 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                            
                          </Row>
                        </Col>
                      </Card>
                      </Col>

                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 18 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 18 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-MP '>
                              <b className='f-12'>M.P</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 18 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary'>
                                      <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 12 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col>
                      <Col sm="2" className='pt-3' style={{ display: ( 
                      listMovements?.some(movement => movement.id_pay_method === 28 && movement.amount !== null && movement.amount !== undefined) ||
                      listMovementsInvoice?.some(movement => movement.id_pay_method === 28 && movement.amount !== null && movement.amount !== undefined)) ? 'block' : 'none' }}>
                      <Card className='shadow pt-2' style={{ height: "60px" }}>
                        <Col sm="12">
                          <Row>
                            <Col sm="3" className='pt-2'>
                            <div className='card-validateFinance-MP '>
                              <b className='f-12'>F.D</b>
                            </div>
                            </Col>
                            <Col sm="9">
                              {listMovements?.map((movement) => {
                                if (movement.id_pay_method === 28 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                    <div key={movement.id_pay_method} className='txt-primary'>
                                      <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                              {listMovementsInvoice?.map((movement) => {
                                if (movement.id_pay_method === 28 && movement.amount !== null && movement.amount !== undefined) {
                                  return (
                                      <div key={movement.id_pay_method} className='txt-danger pt-2'>
                                        <b className='f-12'> ${number_format(movement.amount, 2)}</b>
                                      </div>
                                  );
                                }
                                return null;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Card>
                      </Col> 
                      
                      </Row>
                      {
                        isComplement ? 
                      
                        <Col  sm="12" >
                       
                          <Col sm="12">
                          <Row>
                            <Col sm="11">
                            <h5>Montos </h5>
                            </Col>
                            <Col sm="1" className='p-l-0' >
                          <div onClick={ () => handleClosedViewDetalil() }  id='btn-tooltip-excel' className='icon-div-user bg-dark pointer'  >   
                          <i className= "icofont icofont-ui-close" ></i>
                          </div>   
                          </Col>
                          </Row>

                          </Col>
                         
                          <Col sm="11" className='pt-3 p-l-0 p-r-0'>
                          <div className='table-wrapper-finance'>
                            <Table className='b-r-5 table table-xs'>
                            <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                  <th style={{textAlign:"center"}}>Fecha</th>
                                  <th style={{textAlign:"center"}}>Folio</th>
                                  <th style={{textAlign:"center"}}>Receptor</th>
                                  <th style={{textAlign:"center"}}>Monto</th>
                                  <th style={{textAlign:"center"}}>UUID</th>
                                  <th style={{textAlign:"center"}}>F. de Pago</th>
                              </tr>
                          </thead>
                          <tbody style={{ textAlign: "center" }}>
                          {dataComplement?.map((_find, _key) => {
                                  return (
                                      <tr key={_key}>
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>{_find.payment_date ?? "-----"}</td>
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>
                                          <Col sm="12" className='badge-detail-Finance' >
                                          <i className="fa fa-angle-right f-12"></i> {_find?.folio ?? "--"}
                                          </Col>
                                          </td>
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }} >
                                            {_find?.nameBussines ?? "----"}
                                          </td>
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }} >
                                          ${number_format(_find?.payment_amount, 2) ?? ""}
                                          </td>
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>{_find.uuid ?? "-----"}</td>    
                                          <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }} >
                                          <Col sm="12" className='badge-detail-Finance-Complement'>
                                          <i className="fa fa-angle-right f-12"></i>{_find?.folioReceptor ?? "-----"}
                                        </Col>
                                          </td>
                                      </tr>
                                  );
                              })}
                               {totalRowComplement}
                          </tbody>
                            </Table>
                          </div>
                          </Col>
                        </Col>
                        : 
                        <Row>
                        <Col sm="12" style={{ position: 'relative' }}>
                        <Row>
                          <Col sm="5">
                          {dataCortesy?.length > 0 &&<Table className='b-r-5 ' size='xs'  style={{ position: 'absolute', top: 0 }}>
                          <thead>
                          <th colSpan={3} >Membresias aplicadas al mes</th>


                          </thead>
                          <thead className='b-r-5'>
                            <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                              <th>Fecha</th>
                              <th>nim</th>
                              <th>paciente </th>
                              <th>Monto</th>
                            </tr>
                          </thead>
                          <tbody style={{backgroundColor:"#dc354578"}} >
                            {dataCortesy?.length > 0 && dataCortesy?.map((_find, _key) => {
                              return (
                                <tr key={_key}>
                                  <td>{_find?.date ?? ""}</td>
                                  <td>{_find?.nim ?? ""}</td>
                                  <td>{_find?.namePatient ?? ""}</td>
                                  <td>${number_format(_find?.amount ?? "")}</td>
                                </tr>
                              )
                            })}
                            <tr style={{ height: '20px' }}></tr>
                            <tr style={{ backgroundColor: 'rgb(0 26 40)' }}>
                              <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></td>
                              <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></td>

                              <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }} className='labeltheadInvoice'>Total:</td>
                              <td  style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }} className='labeltheadInvoice'>
                                ${number_format(dataCortesy.reduce((acc, curr) => acc + (curr?.amount ?? 0), 0), 2)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>}
                          </Col>
                          <Col sm="1">
                          </Col>
                          
                          {arrayDeposits?.length > 0 &&<Col sm="5" className='p-r-0 ' >
                            <div style={{ position: 'relative', zIndex: 1 }}>
                            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 20, maxHeight: '250px', overflowY: 'auto' }}>
                              <Table className='b-r-5' size='xs'>
                                <thead>
                                  <th>Deposito Bancario</th>
                                </thead>
                                <thead className='b-r-5'>
                                  <tr style={{ backgroundColor: "#f3f3f3", borderBottom: "#e3ebf3", borderTop:"#e3ebf3" }}>
                                    <th>Usuario</th>
                                    <th>Monto </th>
                                    <th>Comentario</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {arrayDeposits?.length > 0 && arrayDeposits?.map((_find, _key) => {
                                    return (
                                      <tr key={_key}>
                                        <td>{_find?.user_received ?? ""}</td>
                                        <td className='transferencia-electronica'>${number_format(_find?.amount, 2) ?? ""}</td>
                                        <td>{_find?.observation ?? ""}</td>
                                      </tr>
                                    );
                                  })}
                                  <tr style={{ height: '20px' }}></tr>
                                  <tr style={{ backgroundColor: 'rgb(0 26 40)' }}>
                                  <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)', textAlign:"right" }} className='labeltheadInvoice'>Total:</td>
                                    <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }} className='labeltheadInvoice'>
                                      ${number_format(arrayDeposits.reduce((acc, curr) => acc + (curr?.amount ?? 0), 0), 2)}
                                    </td>
                                    <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></td>

                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Col>}
                        <Col sm="1">
                        </Col>
                        </Row>
                        <ApexCharts id="chart-currently" options={CurrentlyDeposits2.options} series={CurrentlyDeposits2.series} type='area' height={310} />
                      </Col>
                        </Row>
                      }
                      <Col sm="12" className='p-l-0'>  
                      <Row>
                        <Col sm="3" className='p-r-0'>
                        <Row>
                          <Col sm="3">
                          <div id='btn-tooltip-excel' className='icon-div-user bg-primary pointer' >   
                          <i className="icofont icofont-cur-dollar f-18"></i>
                          </div>   
                          </Col>
                          <Col sm="9">
                          <label className='label-invoice-Indications'>Ingreso Total</label> <br />
                          <b className='f-14'> ${number_format(totalAmount,2)}</b> <br />
                          {_validationFinance && <b className='f-14 txt-primary'> ${number_format(totalAmount - totalCollected ,2)}</b>}
                          </Col>
                        </Row>
                        </Col>
                        <Col sm="3" className='p-r-0 p-l-0'>
                        <Row>
                          <Col sm="3">
                          <div id='btn-tooltip-excel' className='icon-div-user bg-danger pointer' >   
                          <i className="icofont icofont-cur-dollar f-18"></i>
                          </div>   
                          </Col>
                          <Col sm="9">
                          <label className='label-invoice-Indications'>Total Facturado</label> <br />
                          <b className='f-14'> ${number_format(indicatorAmountInvoice,2)}</b>
                          </Col>
                        </Row>
                        </Col>
                        <Col sm="3" className='p-l-0'>
                        <Row>
                          <Col sm="3">
                          <div id='btn-tooltip-excel' className='icon-div-user bg-primary pointer' >   
                          <i className="icofont icofont-cur-dollar f-18"></i>
                          </div>   
                          </Col>
                          <Col sm="9">
                          <label className='label-invoice-Indications'>Ingreso Total en cuenta</label> <br />
                          <b className='f-14'> ${number_format(totalDepositSend,2)}</b>
                          </Col>
                        </Row>
                        </Col>
                        <Col sm="3" className='p-l-0 p-r-0'>
                        <Row>
                          <Col sm="3">
                          <div onClick={ () =>  isComplement ? handleClosedViewDetalil() : handleViewDetalil() }  id='btn-tooltip-excel' className={isComplement ? 'icon-div-user bg-dark pointer' : 'icon-div-user bg-warning pointer' }  >   
                          <i className={isComplement ? "icofont icofont-ui-close" : "icofont icofont-cur-dollar f-18" } ></i>
                       
                          </div>   
                          </Col>
                          <Col sm="9">
                          <label className='label-invoice-Indications'>Ingreso de otros meses</label> <br />
                          <b className='f-14'> ${number_format(totalComplement,2)}</b>
                          </Col>
                        </Row>
                        </Col>
                        
                      </Row>
                                      
                      </Col>
                    </Card>
                    </Col>
                  </Row>
                  </Col>
                  {
                    // ? COFIGURATION MENU DASHBOARD
                    <Col sm="12" className='pt-1' >
                       <div style={{backgroundColor: "#0029408f",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
                        <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center", }}>
                            Filtro de busqueda
                        </div>
                        <Col sm="12">
                        <Row>
                          <Col sm="2" className='p-l-0' >
                          <label htmlFor="">fecha inicio</label>
                          <FormGroup>
                            <Input
                              style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                              type='date'
                              name='start_date' 
                              value={start_date} 
                              onChange={(e) => handleInputChange(e)}
                            >
                            </Input>
                          </FormGroup>
                          </Col>
                          <Col sm="2">
                          <label htmlFor="">Fecha fin</label>
                          <FormGroup>
                            <Input
                              style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                              type='date'
                              name='end_date' 
                              value={end_date} 
                              onChange={(e) => handleInputChange(e)}
                            >
                            </Input>
                          </FormGroup>
                          </Col>
                          <Col sm="1" className='pt-3' >
                          <button onClick={() => handleActions()} style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}>
                            <i className='icofont icofont-filter f-14' ></i>Buscar
                          </button>
                          </Col>
                        </Row>
                        </Col>
                        </div>
                      <div style={{backgroundColor: "#fff",  borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}}>
                        <Col sm="12">
                        <Row className = { _validationFinance ? 'd-none' : '' } >
                          <Col sm="6"  >
                            <b className='f-14 pt-2'>Resúmen de Ingresos generados</b>
                          <div className='hr-detail-patient pt-2' >
                          </div> <br /> 
                            <Table className='b-r-5 table table-xs'>
                            <thead className='b-r-5'>
                                <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                    <th style={{textAlign:"center"}}>Sucursal</th>
                                    <th style={{textAlign:"center"}}>Metodo de pago</th>
                                    <th style={{textAlign:"center"}}>Monto</th>
                                    <th style={{textAlign:"center"}}>Estatus</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                {brach?.map((_find, _key) => {
                                    return (
                                        <tr key={_key}>
                                            <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>
                                            <Col sm="12" className='badge-detail-Finance' >
                                            <Row>
                                              <Col sm="3" className='p-r-0' >
                                              <i className="fa fa-angle-right f-12"></i>
                                              </Col>
                                              <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                                              {_find?.name ?? "--"}
                                              </Col>
                                            </Row>
                                            </Col>
                                        
                                            </td>
                                            <td style={{border:" 1px solid #e3ebf3" , padding: '10px'}}  >
                                                {_find.id_branch && listDetailBranch[_find.id_branch] && listDetailBranch[_find.id_branch].map((item, index) => (
                                                    <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                                        {(() => {
                                                            switch (item.id_pay_method) {
                                                                case 1:
                                                                    return "Efectivo";
                                                                case 2:
                                                                    return "Tarjeta de Crédito";
                                                                case 4:
                                                                    return "Tarjeta de Débito";
                                                                    case 10:
                                                                    return "Credito";
                                                                case 8:
                                                                    return "Transferencia Electronica";
                                                                case 28: 
                                                                    return "Ficha de Deposito"
                                                                case 29: 
                                                                    return "Cortesia"
                                                                default:
                                                                    return "Mercado Pago";
                                                            }
                                                        })()}
                                                    </div>
                                                ))}
                                            </td>
                                            <td style={{border:" 1px solid #e3ebf3"}}>
                                                {_find.id_branch && listDetailBranch[_find.id_branch] && listDetailBranch[_find.id_branch].map((item, index) => (
                                                    <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                                        ${number_format(item.amount, 2)}
                                                    </div>
                                                ))}
                                            </td>
                                            <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }} >
                                            <Col sm="12" className='badge-detail-Finance-status'>
                                        <Row>
                                          <Col sm="4" className='p-r-0' >
                                          <i className="fa fa-circle-o "></i>     
                                          </Col>
                                          <Col sm="8" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                                          {"Activo" ?? "-----"}
                                          </Col>
                                          </Row>
                                          </Col>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {totalRow}
                            </tbody>
                            </Table>
                            <Col sm="12" >
                              {
                                Object.values(listDetailBranch).map((branch, _key) => {
  
                                  //*VALID SIZE CHARTS
                                  let _calculateSize = branch.map(_find => _find.amount).length
  
                                  let _valid1 = _calculateSize === 4 ? 430 : 420
                                  let _valid2 = _calculateSize === 4 ? 430 : 420
                                  //*END VALID SIZE CHARTS
                                  
                                  let _findBranch = brach.find(info => info.id_branch === branch[0].id_branch);
                                  let _branchName = _findBranch ? _findBranch.name : 'Nombre de sucursal no encontrado';
  
                                  let _amountBranchs = branch.reduce((total, item) => total + item.amount, 0);
                                  let _find = [...new Set(branch.map(item => item.id_pay_method))];
  
                                  const labels = _find.map((_id_pay_method) => {
                                      let _namePayMethod;
                                    switch (_id_pay_method) {
                                      case 1:
                                        _namePayMethod = "Efectivo";
                                        break;
                                      case 2:
                                        _namePayMethod = "Tarjeta de Crédito";
                                        break;
                                      case 4:
                                        _namePayMethod = "Tarjeta de Débito";
                                        break;
                                      case 8:
                                        _namePayMethod = "Transferencia Electrónica";
                                        break;
                                        case 10:
                                          _namePayMethod = "Credito";
                                          break;
                                        case 27:
                                          _namePayMethod = "Metódo PPD";
                                          break;
                                          case 28:
                                          _namePayMethod = "Ficha de Deposito";
                                          break;
                                          case 29:
                                          _namePayMethod = "Cortesia";
                                          break;
                                      default:
                                        _namePayMethod = "Mercado Pago";
                                    }
                                    return {
                                      name: _namePayMethod,
                                      color: getClassForPayMethod1(_id_pay_method) 
                                    };
                                  });
  
                                  let chartOptions = {
                                      ...ChartPaymentConditionsTable.options,
                                      labels: labels.map(label => label.name),
                                      colors: labels.map(label => label.color),
                                      legend: {
                                        show: true, 
                                        position: 'right', 
                                      }
                                    };
                                return (
                                  <div key={_key}>
                                      <Col sm="12" style={{textAlign:"center"}} >
                                        <b className='f-16'  > Total:${number_format(_amountBranchs,2)} / {_branchName} </b>
                                        </Col>
                                        {
                                          //*COMPONENT CHART
                                        }
                                        <ReactApexChart
                                          options={chartOptions}
                                          series={branch.map(_find => _find.amount)}
                                          type='donut'
                                          height={_valid1}
                                          width={_valid2}
                                        />
                                  </div>
                                );
                              })}
                            </Col>
                          </Col>
                          <Col sm="6">
                          <h5 className='card-title f-14 f-w-600'>Resúmen de facturas generadas</h5>
                              <div className='hr-detail-patient' >
                              </div> <br /> 
                              <Table className='b-r-5 table table-xs'>
                              <thead className='b-r-5'>
                                      <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                        <th style={{textAlign:"center"}}>Sucursal</th>
                                        <th style={{textAlign:"center"}}>Metodo de pago</th>
                                        <th style={{textAlign:"center"}}>Monto</th>
                                        <th style={{textAlign:"center"}}>Estatus</th>
                                      </tr>
                                </thead>
                                <tbody>
                                {
                                brach?.map((_find, _key) => {
                                  const details = _find.id_branch && listDetailBranchInvoice[_find.id_branch];

                                  return(
                                    <tr key={_key}>
                                      <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>
                                      <Col sm="12" className='badge-detail-Finance' >
                                          <Row>
                                            <Col sm="3" className='p-r-0' >
                                            <i className="fa fa-angle-right f-12"></i>
                                            </Col>
                                            <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                                            {_find?.name ?? "--"}
                                            </Col>
                                          </Row>
                                          </Col>
                                        </td>
                                      <td style={{border:" 1px solid #e3ebf3" , padding:"10px" }} >
                                      {details && details.length > 0 ? (
                                        details.map((item, index) => (
                                          <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                            {(() => {
                                              switch (item.id_pay_method) {
                                                case 1:
                                                  return "Efectivo";
                                                case 2:
                                                  return "Tarjeta de Crédito";
                                                case 4:
                                                  return "Tarjeta de Débito";
                                                case 8:
                                                  return "Transferencia Electrónica";
                                                  case 10:
                                                    return "Credito";
                                                  case 27:
                                                    return "Metódo PPD";
                                                default:
                                                  return "Mercado Pago";
                                              }
                                            })()}
                                          </div>
                                        ))
                                      ) : (
                                        <div className='card-validateFinance-na' >{"----------"}</div>
                                      )}
                                    </td>
                                      <td style={{border:" 1px solid #e3ebf3" , alignContent:"center" }}>
                                      {details && details.length > 0 ? (
                                        details.map((item, index) => (
                                          <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                            ${number_format(item?.amount ?? 0, 2)}
                                          </div>
                                        ))
                                      ) : (
                                        <div className='card-validateFinance-na' >{"----------"}</div>
                                      )}
                                      </td>
                                      <td style={{border:"1px solid #e3ebf3", alignContent:"center"}}>
                                      <Col sm="12" className={details && details.length > 0 ? 'badge-detail-Finance-status' : "badge-detail-Finance-cancel" }     >
                                        <Row>
                                          <Col sm="4" className='p-r-0'>
                                            <i className="fa fa-circle-o "></i>
                                          </Col>
                                          <Col sm="8" className='p-l-0 p-r-0' style={{ textAlign: "justify" }}>
                                            {details && details.length > 0 ? (
                                              <div>Activo</div>
                                            ) : (
                                              <div>Inactivo</div>
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                      </td>
                                    </tr>
                                  );
                                })}
                                 {totalRow2}
                                </tbody>
                              </Table>
                              <Col sm="12" >
                            {
                              Object.values(listDetailBranchInvoice).map((branch, _key) => {

                                //*VALID SIZE CHARTS
                                let _calculateSize = branch.map(_find => _find.amount).length

                                let _valid1 = _calculateSize === 4 ? 430 : 420
                                let _valid2 = _calculateSize === 4 ? 430 : 420
                                //*END VALID SIZE CHARTS

                                let _findBranch = brach.find(info => info.id_branch === branch[0].id_branch);
                                let _branchName = _findBranch ? _findBranch.name : 'Sucursal no encontrada';

                                let _amountBranchs = branch.reduce((total, item) => total + item.amount, 0);
                                let _find = [...new Set(branch.map(item => item.id_pay_method))];

                                //!type method payments

                                const labels = _find.map((_id_pay_method) => {
                                    let _namePayMethod;
                                  switch (_id_pay_method) {
                                    case 1:
                                      _namePayMethod = "Efectivo";
                                      break;
                                    case 2:
                                      _namePayMethod = "Tarjeta de Crédito";
                                      break;
                                    case 4:
                                      _namePayMethod = "Tarjeta de Débito";
                                      break;
                                    case 8:
                                      _namePayMethod = "Transferencia Electrónica";
                                      break;
                                      case 27:
                                        _namePayMethod = "Pago PPD";
                                        break;
                                      
                                    default:
                                      _namePayMethod = "Mercado Pago";
                                  }
                                  return {
                                    name: _namePayMethod,
                                    color: getClassForPayMethod1(_id_pay_method) 
                                  };
                                });

                                //!options chart donut
                                let chartOptions = {
                                    ...ChartPaymentConditionsTable.options,
                                    labels: labels.map(label => label.name),
                                    colors: labels.map(label => label.color),
                                    legend: {
                                      show: true, 
                                      position: 'right', 
                                    }
                                  };
                              return (
                                <div key={_key}>
                                    <Col sm="12" style={{textAlign:"center"}} >
                                      <b className='f-16'  > Total:${number_format(_amountBranchs,2)} / {_branchName} </b>
                                      </Col>
                                      {
                                        //*COMPONENT CHART
                                      }
                                      <ReactApexChart
                                        options={chartOptions}
                                        series={branch.map(_find => _find.amount)}
                                        type='donut'
                                        height={_valid1}
                                        width={_valid2}
                                      />
                                </div>
                              );
                            })}
                          </Col>
                            
                          </Col>
                        </Row>
                        </Col>

                      </div>



                    </Col>
                  }
                {
                  //*CONFIGURATION FINANCE 
                  <Col sm="12" className={_validationFinance ? "" : "d-none"}>
                    <Row>
                     
                      <Col sm="8" >
                      <Card className='shadow' style={{height : "400px"}}>
                        <Col sm="12">
                        <br />
                        <b className='f-14 pt-2'>Efectivo en serie B</b>
                        <div className='hr-detail-patient pt-2' >
                        </div> <br /> 
                     
                        <Col sm="12">
                        <Row>
                          <Col sm="10">
                          </Col>
                          <Col sm="2" className='p-l-0 p-r-0 pointer '>
                          <Card className='shadow custom-card1' style={{height : "40px"}} onClick={ () => navigateCalender()} >
                            <Col sm="12">
                            <Row>
                              <Col sm="4" className='pt-2'>
                              <i className='fa fa-calendar f-22 ' style={{color:"blueviolet"}}></i>
                              </Col>
                              <Col sm="8" className='pt-2 p-l-0 '>
                              <label className='f-w-600'>Detallado</label>
                              </Col>
                            </Row>
                            </Col>
                          </Card>
                          </Col>
                          <Col sm="2" className='p-r-0 pointer'>
                          </Col>
                        </Row>
                        </Col>
                        <Table className='b-r-5 table table-xs'>
                        <thead className='b-r-5'>
                          <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                            <th style={{textAlign:"center"}} >Sucursales</th>
                            <th style={{textAlign:"center"}} >Ingresado en el sistema</th>
                            <th style={{textAlign:"center"}} >Programado en serie B</th>
                            <th style={{textAlign:"center"}} >Recuperado en serie B</th>
                            <th style={{textAlign:"center"}} >Ingreso con flujo B</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                        {detailBranch.length > 0 && detailBranch.map((_branch) => {
                          return _branch.children.map((_child, _index) => {
                            const _branchDetails = _child.id && listDetailBranch[_child.id]
                            ? listDetailBranch[_child.id].filter(_detail => _detail.id_branch === _child.id && _detail.id_pay_method === 1)
                            : [];
                            const _detail = _branchDetails.length > 0 ? _branchDetails.find(_find => _find.id_branch === _child.id) : null;
                            const _total = _detail ? (_detail.amount - _child.amount_collected) : 0;
                            const _status = _child?.amount_collected !== 0; 

                            let _valid_color = _child?.amount === _child?.amount_collected ? "txt-success" : "txt-danger"

                            return (
                              <tr key={_child.id}>
                                <td style={{border:" 1px solid #e3ebf3"}} >
                                    <Col sm="12" className='badge-detail-Finance' >
                                      <Row>
                                      <Col sm="3" className='p-r-0' ><i className="fa fa-angle-right f-12"></i></Col>
                                      <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >{_child?.text ?? "--"}</Col>
                                      </Row>
                                    </Col>
                                </td>
                                <td style={{border:" 1px solid #e3ebf3"}}><b style={{color:"#818a91"}}>${number_format(_detail?.amount,2) ?? 0}</b> </td>
                                <td style={{border:" 1px solid #e3ebf3"}}><b style={{color:"#818a91"}} >${number_format(_child?.amount,2) ?? 0 }</b></td>

                                <td style={{border:" 1px solid #e3ebf3"}}><b className={_valid_color} >${number_format(_child?.amount_collected,2) ?? 0 }</b></td>
                                <td style={{border:" 1px solid #e3ebf3"}}><b style={{color:"#818a91"}}>${number_format(_total,2) ?? 0}</b> </td>
                                
                              </tr>
                            );
                          });
                        })}
                        {financeAmount}
                      </tbody>
                      </Table>
                        </Col>
                      </Card>
                      </Col>
                      <Col sm="4">
                      <Card>
                      <HighchartsReact highcharts={Highcharts} options={chartOptionss} />
                      </Card>
                      </Col>
                    </Row>
                  </Col>
                }
                {
                //* END CHART EGRESS
                }
                <br />
              
              </Row>  
              {
                //*END DASHBOARD CASH AND INVOICE
              } 
              {
               // ? REQUIRED INVOICES 
              } 
              <Col sm="12" className={type_action === "R" ? 'p-l-0' : 'd-none'} >
              <Col sm="12" className='p-l-0 p-r-0 scroll-container-div' >
              <div style={{backgroundColor: "#0029408f",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
              <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center", }}>
                  Pacientes pendientes de facturar
              </div>
              <Col sm="12">
                <Row>
                  <Col sm="2" className='p-l-0' >
                  <label htmlFor="">Selecciona la sucursal</label> <br />
                  <FormGroup>
                    <Input
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      type='select'
                      name="id_tax_regime_business_name"
                      onChange={(e) => handleSelectBranch(e.target.value)}
                    >
                      <option value={0}>Selecciona una sucursal</option>
                      {invoiceBranch.length > 0 && invoiceBranch.map(_branch => (
                        <option key={`branchs-${_branch.value}`} value={_branch.value}>{_branch.label}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  {
                  }
                  </Col>
                  <Col sm="2" className='p-l-0 ' >
                  <label htmlFor="">Selecciona el periodo</label> <br />
                  <FormGroup>
                  <Input
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      type='select'
                      name="id_tax_regime_business_name"
                      onChange={(e) => handleSelectMonth(e.target.value)}
                  >
                    <option value={0}>Selecciona una periodo</option>
                      {monthInvoices.length > 0 && monthInvoices.map(_month => (
                        <option key={`monthIn-${_month.value}`} value={_month.value}>{_month.label}</option>
                      ))}
                  </Input>
                  </FormGroup>
                  </Col>
                  <Col sm="2">
                  <label htmlFor="">Selecciona el año</label> <br />
                  <FormGroup>
                  <Input
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      type='select'
                      name="id_tax_regime_business_name"
                      onChange={(e) => handleSelectAge(e.target.value)}
                  >
                    <option value={0}>Selecciona el año</option>
                    <option value={"2024"}>2024</option>  
                    <option value={"2025"}>2025</option>                          
                        
                  </Input>
                  </FormGroup>
                  </Col>
                  <Col sm="2">
                  <label htmlFor="">Selecciona el método de pago</label> <br />
                  <FormGroup>
                  <Input
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      type='select'
                      name="id_tax_regime_business_name"
                      disabled={true}
                  >         
                    <option value={"01"}>Particular</option>      
                  </Input>
                  </FormGroup>
                  </Col>
                  <Col sm="1">
                  </Col>
                  <Col sm="1" className='pt-4' style={{textAlign:"end"}} >
                  <button onClick={() => getInformationInvoices()} style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}>
                    <i className='icofont icofont-filter f-14' ></i>Buscar
                  </button>
                  </Col>
                  <Col sm="1" className={listInvoices.length > 0 ? 'pt-4' : "d-none" } >
                  <button onClick={() => handleInvoices()} style={{backgroundColor:"#002940", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}>
                   Guardar
                  </button>
                  </Col>
                  <Col sm="1" className={listInvoices.length > 0 ? 'pt-4 p-l-0' : "d-none" }  >
                  <button onClick={() => exportToExcel(listInvoices)} style={{backgroundColor:"#0a0178", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}>
                    <i className='icofont icofont-file-excel  f-14' ></i>Reporte
                  </button>
                  </Col>
                </Row>
                </Col>
              </div>
              <div style={{backgroundColor: "#fff",  borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}}>
                <Col sm="12" className='p-l-0 p-r-0 pt-1' >   
                <Col sm="4" className={listInvoices.length > 0 ? 'pb-4 p-l-0' : "d-none" }  >
                <label className='f-w-500' style={{color:"#0029408f"}} >Filtrar método de pago</label> <br />
                <select
                style={{borderColor: "#0029408f",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                onChange={(e) => setSelectedPaymentMethod(Number(e.target.value))}>
                <option value="">Todos los métodos de pago</option>
                {Object.keys(paymentMethods).map(key => (
                  <option key={key} value={key}>{paymentMethods[key]}</option>
                ))}
              </select>

                </Col>


                <Table striped size='xs'>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>#</th>
                      <th style={{ backgroundColor: "#002940d1", color: "#fff", fontWeight: "bold", alignItems: 'center', justifyContent: 'space-between' }}>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                        />
                      </th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Nim</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Nombre</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Sucursal</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Fecha</th>
                      <th style={{ backgroundColor: "#6c757d", color: "#fff", fontWeight: "bold" }}>Solicito factura</th>
                      <th style={{ backgroundColor: "#e63949", color: "#fff", fontWeight: "bold" }}>Paciente facturado</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Método de pago</th>
                      <th style={{ backgroundColor: "#000", color: "#fff", fontWeight: "bold" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#f0f8ff" }}>
                    {filteredInvoices.length > 0 && filteredInvoices.map((_invoice, index) => {
                      // ? TYPE METHOD
                      let _namePayMethod;
                      const payMethod = Array.isArray(_invoice?.pay_methods) ? _invoice?.pay_methods[0] : _invoice?.pay_methods;

                      switch (payMethod) {
                        case 1:
                          _namePayMethod = "Efectivo";
                          break;
                        case 2:
                          _namePayMethod = "Tarjeta de Crédito";
                          break;
                        case 4:
                          _namePayMethod = "Tarjeta de Débito";
                          break;
                        case 8:
                          _namePayMethod = "Transferencia Electrónica";
                          break;
                        case 10:
                          _namePayMethod = "Crédito";
                          break;
                        case 27:
                          _namePayMethod = "Método PPD";
                          break;
                        default:
                          _namePayMethod = "Mercado Pago";
                      }

                      const idWorkOrder = _invoice?.id_work_order;
                      const isChecked = !!checkedItems[idWorkOrder]; 

                      return (
                        <tr key={_invoice?.nimm}>
                          <th className={isChecked ? 'selected' : ''}>{index + 1}</th>
                          <th  style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              id={`checkbox-${idWorkOrder}`} 
                              checked={!!checkedItems[idWorkOrder]} 
                              onChange={() => handleCheckboxChange(idWorkOrder)} 
                              style={{ display: 'none' }}
                            />
                            <label htmlFor={`checkbox-${idWorkOrder}`} style={{
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              borderRadius: '50%',
                              border: `2px solid #065c88`,
                              backgroundColor: 'transparent',
                              cursor: 'pointer',
                              position: 'relative'
                            }}>
                              <span style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '12px',
                                height: '12px',
                                backgroundColor: '#065c88',
                                borderRadius: '50%',
                                transform: 'translate(-50%, -50%)',
                                opacity: checkedItems[idWorkOrder] ? 1 : 0, 
                                transition: 'opacity 0.2s'
                              }}></span>
                            </label>
                          </th>
                          <th className={isChecked ? 'selected' : ''} >{_invoice?.nimm}</th>
                          <th className={isChecked ? 'selected' : ''}>{_invoice?.name}</th>
                          <th className={isChecked ? 'selected' : ''}>{_invoice?.branch}</th>
                          <th className={isChecked ? 'selected' : ''}>{_invoice?.fech}</th>
                          <th className={isChecked ? 'selected' : ''}  style={{ textAlign: "center" }}>{_invoice?.invoices ? <i className='fa fa-check txt-success f-14'></i> : null}</th>
                          <th className={isChecked ? 'selected' : ''} style={{ textAlign: "center" }}><i className='fa fa-times text-danger f-14'></i></th>
                          <th className={isChecked ? 'selected' : ''}>{_namePayMethod}</th>
                          <th className={isChecked ? 'selected' : ''}>${number_format(_invoice?.amount ?? 0, 2)}</th>
                        </tr>
                      );
                    })}
                  {invoicesAmount2}
                  </tbody>
                </Table>
                <Col sm="12" className='pt-4' >
                <Row>
                <Col sm="3" className='p-l-0'>
                <h5>Metódos pendientes a facturar</h5>
                  {
                    Object.keys(_totalByPaymentMethods).map((key) => (
                      <Col sm="12" key={key} className='pt-4' >
                        <Card className='shadow'>
                          <Row>
                            <Col sm="3">
                              <img
                                alt="flask"
                                className="img-fluid text-info mr-2"
                                src={require("../../assets/images/ecommerce/invoices-dollar.svg")}
                                style={{ width: "60px", height: "60px" }}
                              />
                            </Col>
                            <Col sm="9">
                              <label style={{ color: "#0029408f", fontSize: "13px", fontWeight: "bold" }}>
                                {key} 
                              </label>
                              <br />
                              <label className='f-w-500 f-14'>
                                ${number_format(_totalByPaymentMethods[key], 2)} 
                              </label>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  }
                  </Col>
                  <Col sm="6" className='pt-1' >
                  <ReactApexChart options={chartOptions} series={chartSeries} type="donut" height={350} />
                  </Col>
                </Row>
                </Col>
                </Col>
              </div>
            </Col>   
            </Col>
              {
               //*END SALDOS
              } 
              {
                //*CREDIT
              }
              <Col sm="12" className={type_action === "C" ? '' : 'd-none'} >
                <Row>
                <Col sm="12" md="6" lg="6">
                  <Row>
                      <Col sm="6" className='p-l-0' >  
                        <Card className="cardFully"  style={{ height: "120px" }} > 
                          <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                                <br />
                                <label className='f-16 labelFullyPaid' >Facturas Globales</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(indicadorFullyPaid,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                      <Col sm="6" className='p-l-0' > 
                        <Card className="cardFully" style={{ height: "120px" }}> 
                        <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                              <br />
                                <label className='f-16 labelFullyPaid' >Facturas solicitadas</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(0,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                    
                  </Col>
                <Col  sm="12" md="6" lg="6">
                    <Card className='shadow' >
                    <CardHeader className='text-center p-1 bg-danger'>
                        <h6 className='pt-1'>Facturas canceladas</h6>
                    </CardHeader>
                            <br /> 
                            {
                              ChartPaymentCancell?.series.length ===  0 ?
                              <div style={{ textAlign: "-webkit-center" }}>
                                  <p className='f-w-600 f-14 badge badge-light-primary'>Sin resultados.&nbsp; &nbsp; {start_date} &nbsp;   /&nbsp;   {end_date} </p> <br />

                                  <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "62%" }} alt="" />
                                </div>
                                :
                                <ApexCharts options={ChartPaymentCancell?.options} series={ChartPaymentCancell?.series} type='donut' height={380} width={_valid2} />


                            }
                    </Card>
                  <br />
                  </Col>
                </Row>
              </Col>
              {
                //*END CREDIT
              }
              {
                //*INVOICES GLOBALES AND PATIENT
              }
              <Col sm="12" className={type_action === "F" ? "p-l-0" : "d-none"} >
                <Col sm="12" className='p-l-0 scroll-container-div' >
                <div style={{backgroundColor: "#0029408f",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
                <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center", }}>
                  Detallado de Facturas
                </div>
                <Col sm="12">
                <Row>
                  <Col sm="2" className='p-l-0' >
                    <label>Fecha inicio</label> <br />
                    <FormGroup>
                      <Input       
                        style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                        type='date' 
                        className='border-0 mr-2' 
                        name='start_date' 
                        value={start_date} 
                        onChange={(e) => handleInputChange(e)}>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="2" className='p-l-0' >
                    <label>Fecha fin</label> <br />
                    <FormGroup>
                    <Input 
                        style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                        type='date'  
                        className='border-0 mr-2' 
                        name='end_date' 
                        value={end_date} 
                        onChange={(e) => handleInputChange(e)}> 
                    </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="2" className='p-l-0' >
                  <label >Metódo de pago</label>
                  <FormGroup>
                    <select                         
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      onChange={handleFilterChange} value={paymentMethod}>
                      <option value="">Todos</option>
                        {Object.entries(paymentMethods).map(([key, value]) => (
                          <option key={key} value={value}>{value}</option>
                        ))}
                    </select>
                  </FormGroup>
                  </Col>
                  <Col sm="2" className='p-l-0' >
                  <label htmlFor="">Tipo de factura</label>
                  <FormGroup>
                  <select 
                      style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                      onChange={handlePublicChange} value={isGeneralPublic}>
                      <option value="">Todos</option>
                      <option value="Publico General">Público General</option>
                      <option value="Particular">Particular</option>
                  </select>

                  </FormGroup>
                  </Col>
                  <Col sm="1" className='pt-4' >
                    <button 
                        onClick={() => GetReportDeposits()}
                        style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                    >
                    <i className='icofont icofont-filter f-14' ></i>Buscar
                  </button>
                  </Col>
                  <Col sm="1" className='pt-4' >
                    <button 
                        onClick={() => MakeExcelInvoices(paymentMethods,paymentMethod,isGeneralPublic)}
                        style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                    >
                    <i className='icofont icofont-filter f-14' ></i>Reporte
                  </button>
                  </Col>
                  
                </Row>
                </Col>
                </div>
                <div style={{backgroundColor: "#fff", borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}}>
                  <Col sm="12" className='p-l-0 p-r-0 pt-1'>
                  <Row>
                    <Col sm="3" className='p-l-0' >
                    {
                        Object.keys(_totalByPaymentMethod).map((key) => (
                          <Col sm="12">
                            <Card className='shadow'>
                              <Row>
                                <Col sm="3">
                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/ecommerce/invoices-dollar.svg")} style={{ width: "60px", height: "60px" }} />

                                </Col>
                                <Col sm="9">
                                <label style={{color:"#0029408f", fontSize :"13px", fontWeight:"bold"}} >{paymentMethods[key]} </label> 
                                <br />
                                <label className='f-w-500 f-14'  >${number_format(_totalByPaymentMethod[key], 2)}</label>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))
                    }
                    </Col>
                    <Col sm="9" className='p-l-0' >
                    <Table striped size='xs'>
                        <thead>
                          <tr>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>#</th>
                          <th style={{ backgroundColor: "#002940ba", color: "#fff", fontWeight: "bold" }}>Folio y Serie</th>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Fecha</th>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Tipo de factura</th>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>UUID</th>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Método de Pago</th>
                          <th style={{ backgroundColor: "#000", color: "#fff", fontWeight: "bold" }}>Monto</th>
                          <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Estatus</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#6c757d08" }}>
                          {
                            _filter_invoices !== null && _filter_invoices.map((_find, _key) => (
                              <tr key={_key}>
                                <th style={{ color: "#495057cc" }}>{_key + 1}</th>
                                <th style={{ color: "#495057cc" }}>{_find?.serieFolio}</th>
                                <th style={{ color: "#495057cc" }}>{_find?.dateCreated}</th>
                                <th style={{ color: "#495057cc" }}>{_find?.isGeneralPublic ? "Publico General" : "Particular"}</th>
                                <th style={{ color: "#495057cc" }}>{_find?.uuid}</th>
                                <th style={{ color: "#495057cc" }}>{paymentMethods[_find?.id_pay_method]}</th>
                                <th style={{ color: "#495057cc" }}>${number_format(_find?.total_i ?? 0, 2)}</th>
                                <th style={{ color: "#495057cc" }}> <Badge color={_find?.statuaInvoice === "Cancelada" ? "danger" : "success"} >{_find?.statuaInvoice}</Badge> </th>
                              </tr>
                            ))
                          }
                          {invoicesAmount}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                     
                  </Col>
                </div>
                </Col>
              </Col>
              {
                //*END INVOICES GLOBALES AND PATIENT
              }
              {
                //*CALENDAR DEPOSITS
              }
              <Row className={type_action === "D" ? '' : 'd-none'} >
                <Col sm="12" className='p-l-0' >
                <div style={{backgroundColor: "#0029408f",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
                  <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center"}}>
                  Depositos Bancarios
                  </div>
                  <Col sm="12">
                  <Row>
                    <Col sm="4">
                    <label htmlFor="">Buscar deposito bancario </label> 
                    <button 
                        onClick={() => GetDeposits()}
                        style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                      >
                      <i className='icofont icofont-filter f-14' ></i>Buscar
                    </button>
                    </Col>
                    <Col sm="8">
                    <label htmlFor="">Guardar nuevo deposito bancario</label> 
                    <button 
                        onClick={() => saveReceived()}
                        style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                      >
                    Guardar
                    </button>
                    </Col>
                  </Row>

                  </Col>
                </div>
                <div style={{backgroundColor: "#fff", borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}} >
                  <Col sm="12" className='p-l-0 p-r-0 pt-1' >
                  <Row>
                    <Col sm="4">
                    <Calendar
                          onChange={onChange}
                          value={date}
                          tileClassName={tileClassName}
                          className="p-t-0 p-b-0"
                        />
                    </Col>
                    <Col sm="7" style={{backgroundColor:"#6c757d17"}} >
                    {
                       validNotification === false ?
                       <Col sm="12" className={disabledSaveButton === true ? "disabled disableLabel": ""}>
                        <Row>
                          <Col sm="5">
                          </Col>
                        <Col sm="3"  >
                              {  
                            range === true ?<input  type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? '' : '')}`} name='date_2' value={date_2} onChange={(e) => handleInputChange(e)}></input>: ""
                              }
                            </Col>
                            <Col sm="3"  >  
                                {
                                one === true ? "" : <input  type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'b' : '')}`} name='date_1' value={date_1} onChange={(e) => handleInputChange(e)}></input> 
                              }
                            </Col>

                        </Row>
                        <Row className='pt-4' >
                          <Col sm="4">
                          <label htmlFor="">Quien recibio:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                          <input type='text' className='form-control form-control-sm input-air-primary' style={{height: "35px", borderRadius: "15px", fontSize:"11px"}}    name='received' value={received} onChange={(e) => handleInputChange(e)}  ></input>
                          </Col>
                          <Col sm="4">
                          <label className='f-14' >Quien entrego:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                          <input type='text' className='form-control form-control-sm input-air-primary' style={{ height: "35px", borderRadius: "15px", fontSize:"11px"}}  name='delivere' value={delivere} onChange={(e) => handleInputChange(e)} ></input>

                          </Col>
                          <Col sm="2">
                          <label htmlFor="">Total a depositar:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                          <input type='text' style={{borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}} className='form-control form-control-sm input-air-primary' name='amount' value={amount} onChange={(e) => handleInputChange(e)} ></input>
                          </Col>
                        </Row>
                        <Row className='pt-4' >
                          <Col sm="6">
                          <label htmlFor="">Observaciones</label>
                          <textarea
                                  className='form-control form-control-sm input-air-primary '
                                  name="observation"
                                  rows="3"
                                  value={observation}
                                  onChange={handleInputChange}
                              >
                            </textarea>

                          </Col>
                          <Col sm="2">
                          </Col>
                          <Col sm="4" className='pt-5' style={{textAlign:"end"}} >
                          <Media body className="rounded-circle" style={{ width: "40%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")}  alt="" />

                          </Col>

                        </Row>
                       </Col>
                       :
                       <Col sm="12" className={disabledSaveButton === true ? "disabled disableLabel": ""}>
                       <Row>
                         <Col sm="5">
                         </Col>
                       <Col sm="3"  >
                             {  
                           range === true ?<input  type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? '' : '')}`} name='date_2' value={date_2} onChange={(e) => handleInputChange(e)}></input>: ""
                             }
                           </Col>
                           <Col sm="3"  >  
                               {
                               one === true ? "" : <input  type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'b' : '')}`} name='date_1' value={date_1} onChange={(e) => handleInputChange(e)}></input> 
                             }
                           </Col>

                       </Row>
                       <Row className='pt-4' >
                         <Col sm="4">
                         <label htmlFor="">Quien recibio:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                         <input type='text' className='form-control form-control-sm input-air-primary' style={{height: "35px", borderRadius: "15px", fontSize:"11px"}}    name='received' value={received} onChange={(e) => handleInputChange(e)}  ></input>
                         </Col>
                         <Col sm="4">
                         <label className='f-14' >Quien entrego:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                         <input type='text' className='form-control form-control-sm input-air-primary' style={{ height: "35px", borderRadius: "15px", fontSize:"11px"}}  name='delivere' value={delivere} onChange={(e) => handleInputChange(e)} ></input>

                         </Col>
                         <Col sm="2">
                         <label htmlFor="">Total a depositar:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                         <input type='text' style={{borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}} className='form-control form-control-sm input-air-primary' name='amount' value={amount} onChange={(e) => handleInputChange(e)} ></input>
                         </Col>
                       </Row>
                       <Row className='pt-4' >
                         <Col sm="6">
                         <label htmlFor="">Observaciones</label>
                         <textarea
                                 className='form-control form-control-sm input-air-primary '
                                 name="observation"
                                 rows="3"
                                 value={observation}
                                 onChange={handleInputChange}
                             >
                           </textarea>

                         </Col>
                         <Col sm="2">
                         </Col>
                         <Col sm="4" className='pt-5' style={{textAlign:"end"}} >
                         <Media body className="rounded-circle" style={{ width: "40%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")}  alt="" />

                         </Col>

                       </Row>
                      </Col>



                      //  <Col sm="12">
                      //   {
                      //    listReceived.length > 0 && listReceived.map((_find) => {
                      //       return (
                      //         <div>
                      //            <Row className='pt-4' >
                      //     <Col sm="4">
                      //     <label htmlFor="">Quien recibio:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                      //     <input type='text' className='form-control form-control-sm input-air-primary' style={{height: "35px", borderRadius: "15px", fontSize:"11px"}}    name='received' value={_find.user_received} ></input>
                      //     </Col>
                      //     <Col sm="4">
                      //     <label className='f-14' >Quien entrego:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label> <br />
                      //     <input type='text' className='form-control form-control-sm input-air-primary' style={{ height: "35px", borderRadius: "15px", fontSize:"11px"}}  name='delivere' value={_find.user_delivere} ></input>

                      //     </Col>
                      //     <Col sm="2">
                      //     <label htmlFor="">Total a depositar:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                      //     <input type='text' style={{borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}} className='form-control form-control-sm input-air-primary' name='amount' value={amount} onChange={(e) => handleInputChange(e)} ></input>
                      //     </Col>
                      //   </Row>
                      //   <Row className='pt-4' >
                      //     <Col sm="6">
                      //     <label htmlFor="">Observaciones</label>
                      //     <textarea
                      //             className='form-control form-control-sm input-air-primary '
                      //             name="observation"
                      //             rows="3"
                      //             value={_find.observation}

                      //         >
                      //       </textarea>

                      //     </Col>
                      //     <Col sm="4">
                      //     <Media body className="rounded-circle" style={{ width: "80%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")}  alt="" />

                      //     </Col>

                      //   </Row>

                      //         </div>
                      //       )
                      //     })
                      //   } 
                      


                      //  </Col>


                    }
                    </Col>
                  </Row>
                  </Col>

                </div>
                </Col>
              
              </Row>
              {
                //*END CALENDAR DEPOSITS
              }
            </Container>
            {
              <ModalAutorizationInvoices
              {
                ...{ modalAutorization,toggleModalAutorization,saveInvoices, handleSelecUser,handleSelectPassword,validatePassword,loadingServices,username}
              }

              />
            }
             <ModalCredit
              
              {...modalProps}
              close={close}
              companyInfo={companyInfo}
              formatter={formatter}
              navigateNim={navigateNim}
                      
          >
          </ModalCredit>
        </Fragment>
    )
}