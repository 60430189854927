import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllPbi ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `WorkOrder/DetailPbi`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}