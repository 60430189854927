import { useCallback, useEffect, useState } from 'react';

import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
// import { validarSesion } from '../tokens/useToken';
// import { useHistory } from 'react-router';
import { usePagination } from '../pagination/usePagination';
import { ModalCreateService, ModalUpdateService, ModalDeleteService } from '../../constant';


export const useService = () => {


    const [service, setService] = useState([]);
    //Modal
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");
    /// Alert
    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);

    const [serviceData, setServiceData] = useState([]) ;
    const [idTypeService, setIdTypeService] = useState(0);


    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
    });

    const { name } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
    });

    ///Table
    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(service.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listService = [];
        service.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listService.push({
                ...data,
                visibleTable: visibleTable


            });
        });

        setService(listService);
    }, [totalPageCount, currentPage])

    const toggle = () => {
        setModal(!modal);
    }

    const getService = useCallback(
        async () => {
            let requestOptions = {
                method: 'GET'
            };

            let listService = [];

            const respuesta = await sendRequest(requestOptions, "WorkOrderServiceType/List");

            if (respuesta.code === 200) {

                if (respuesta.data.length >= 1) {

                    let startItems = 0;
                    let endItems = numberItems;
                    let aux = numberItems * currentPage;

                    endItems = aux;

                    startItems = endItems - numberItems;

                    respuesta.data.forEach((obj, index) => {

                        let posicion = index + 1;
                        let visibleTable = false;

                        if (posicion > startItems && posicion <= endItems) {
                            visibleTable = true;
                        }

//to
                        listService.push({
                            id_work_order_type_service: obj.id_work_order_type_service,
                            name: obj.name,
                            is_interfaced: obj.true,
                            visibleTable: visibleTable
                        });
                    });

                    setService(listService);
                    setServiceData(listService);

                }
                else {
                    setService([]);
                }
            }

        },
    )

    useEffect(() => {
        getService();
    }, []);
    

    // Modal
    const handleCreate = () => {
        handleUpdateValues({
            name: ""
        });

        setValidaciones({
            name_valid: false
        });

        setIdTypeService(0);
        setMethod("create");
        setModalTitle(ModalCreateService);
        toggle();
    }


    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let data = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrderServiceType/Create");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se creo correctamente");

            toggle();
            getService();
            handleUpdateValues({
                name: ""
            });
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Servico", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Servicio", respuesta.data.msg);
            setLoading(false);
        }

    }

    const handleUpdate = (id_work_order_type_service) => {
        let services = service.find(p => p.id_work_order_type_service === parseInt(id_work_order_type_service));

        handleUpdateValues({
            name: services.name
        });

        setValidaciones({
            name_valid: false,
        });

        setIdTypeService(id_work_order_type_service);
        setMethod("update");
        setModalTitle(ModalUpdateService);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let mValues = {
            id_work_order_order_type_service: idTypeService,
            name: formValues.name,                   
            priority: formValues.priority || 0, 
        };

        let data = JSON.stringify(mValues);
        let requestOptions = {
            method: 'PUT',
            body: data
        };

        console.log("Payload enviado:", mValues);

        
        const respuesta = await sendRequest(requestOptions, "WorkOrderServiceType/Update");
        // console.log("🚀 ~ saveUpdate ~ respuesta:", respuesta)
        

        if (respuesta.code === 200) {
            getService();
            createSweet("update", "info", "Exito!", "Se actualizo el Servicio");
            setLoading(false);
            toggle();
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Servicio", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Servicio", respuesta.data.msg);
        }
    }

    const handleDelete = (id_work_order_type_service) => {
        let services = service.find(p => p.id_work_order_type_service === parseInt(id_work_order_type_service));

        handleUpdateValues({
            name: services.name
        });

        setIdTypeService(id_work_order_type_service);
        setMethod("delete");
        setModalTitle(ModalDeleteService);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `WorkOrderServiceType/Delete/${idTypeService}`);
        if (respuesta.code === 200) {
            getService();
            setLoading(false);
            createSweet("delete", "success", "Exito!", "Se elimino correctamente");
            toggle();
        }
    }


    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }


    // Service crud search method
    const handleSearchService = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        serviceData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setService(newMethod);
    }


    return {
        // pagination method
        nextPage, previousPage, goToPage, totalPageCount, currentPage, 

        method, name, service, handleInputChange, validaciones, loading,

        //Method of creating
        handleCreate, saveCreate,

        //Editing method
        handleUpdate, saveUpdate,

        //Method of elimination
        handleDelete, saveDelete,

        //Modal
        toggle, modal, modalTitle,

        //Search method
        handleSearchService,


    }
}