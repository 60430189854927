import React, { Fragment, useEffect, useRef, useState } from 'react';
import Blur from 'react-css-blur';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { Button, ButtonGroup, CardBody, Col, Container, Row, FormGroup, Label, Input, Pagination } from 'reactstrap';
import { useSpecialDiscount } from '../../hooks/specialDiscounts/useSpecialDiscount';
import { ModalInformation } from '../../components/specialDiscounts/ModalInformation';
import { ModalSpecialDiscount } from '../../components/specialDiscounts/ModalSpecialDiscount';
import { ListCardSpecialDiscounts } from '../discounts/ListCardSpecialDiscount';
import List from 'list.js';
import Select from 'react-select';

import './StyleDiscount.css'

export const IndexSpecialDiscount = () => {

    const {
          //MODALS
        modalTitle,
        modalInformation, toggleInformation, methodInformation, 
        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount,
        
        //SPECIAL DISCOUNT MODEL        
        loading, listSpecialDiscounts, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
        id_user, code, id_exam_profile, percentage, percentage_global, 
        id_exam_include, id_profile_include, password,
        validationsSpecialDiscount, idSpecialDiscount,
        validCode,

        //SPECIAL DISCOUNTS METHODS
        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes,
        handleInputPercentageIndividual, 
        handleCreate, saveCreate,
        handleRemoveTest,
        handleAuthorized, saveAuthorized,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleShowValidCode, getInformationSpecialDiscount,dataCode, validName,valid_percentage,

        //Filter 
        mDateRange1, mDataRange2, selectedStatus,setSelecUsed, getFilter,handleClean,

         //Pagination
         totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,handleSearchChange,
        } = useSpecialDiscount();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row style={{backgroundColor: "#fff", padding: "15px",}}>
                    <Col sm="2" className='p-1 mt-1'>
                        <FormGroup>
                            <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Fecha de inicio</Label>
                            <Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                        </FormGroup>
                    </Col>

                    <Col sm="2" className='p-1 mt-1' >
                        <FormGroup>
                            <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Fecha de cierre</Label>
                            <Input type='date' value={mDataRange2}  name='mDataRange2' onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                        </FormGroup>
                    </Col>

                    <Col sm="2" >
                        <FormGroup>
                        <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Estatus</Label>
                            <Select
                                name="status"
                                isClearable={true}
                                placeholder="Selecciona un estatus"
                                options={[
                                    { value: "used", label: "Reclamado" },
                                    { value: "unused", label: "Disponible" }
                                ]}
                                value={selectedStatus}
                                onChange={(option) => setSelecUsed(option ? option.value : null)}
                                styles={{ zIndex: "999999999999" }}
                            />

                        </FormGroup>
                    </Col>

                    <Col sm="1">
                        <Label className='f-w-600 f-12 badge badge-light-primary text-center'>Acciones Rapidas</Label> <br />
                        <Button 
                            type="button" 
                            outline 
                            color="primary" 
                            size='sm' 
                            className={loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1"} 
                            onClick={(e) => getFilter(e)} 
                        >
                            Filtrar
                        </Button>                 
                    </Col>
                    <Col sm="1">
                    </Col>

           

                    <Col sm="3">
                        <Label className='f-w-600 f-12 badge badge-light-primary text-center'>Busqueda</Label> <br />
                            <Input placeholder='Búsqueda por código...' className='form-control' size='sm' onChange={handleSearchDiscount} />
                    </Col>

              
                </Row>
                <hr />
            
                <Row>
                    <Col sm="2" className='p-1'>
                        <div div className='ribbon ribbon-clip ribbon-primary'>
                            {"Descuentos Especiales"}
                        </div>
                    </Col>
                    <Col>
                        <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                            <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreate}>
                                <span className='fa fa-plus-circle'></span>
                            </Button>
                        </div>
                    </Col>
                   
            
                    <Col sm="12" md="12" lg="12" xl="12" className='mt-4'>
                    {
                        listSpecialDiscounts.length === 0
                        ?
                            <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "20%" }} alt="" />   <br />                                         
                                <br /> <p className="f-18">{"No hay ningun descuento en el rango de fechas establecido."}</p>
                            </div>
                        :
                        
                        
                        <ListCardSpecialDiscounts
                            methodsActions={true}
                            listSpecialDiscounts={listSpecialDiscounts}
                            {...{ handleUpdate, handleDelete,handleShowValidCode,handleAuthorized }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    }
                    </Col>
                </Row>
            </Container>
            <ModalInformation
                {
                    ...{
                        modalInformation, toggleInformation, validCode, password, handleInputChange, getInformationSpecialDiscount,validName,valid_percentage
                    }
                }
            />
            <ModalSpecialDiscount
                {
                    ...{
                        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount, modalTitle,
                        loading, percentage, percentage_global, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
                        idSpecialDiscount, id_exam_include, id_profile_include,
                        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes, handleInputPercentageIndividual,
                        validationsSpecialDiscount,
                        saveCreate, 
                        handleRemoveTest,
                        saveAuthorized, saveUpdate, saveDelete,validName, handleSearchChange
                    }
                }
            />
        </Fragment>
    )
}
