import React from 'react'
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FormatNumbers from '../../utils/formatNumbers';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';

export const ModalComplementPayment = (props) => {
  
	const {
		loadingPreview, showPreviewInvoice, handlePreviewInvoice, listInvoices, handleChangeAmount,handleChangeType, clientInformation,
		formComplement, setFormComplement, listPayForms, listCommercialLines, handleSaveComplement, disbleButton
	} = props;

	const [ createSweet ] = useSweetAlert();

  return (
		<Modal isOpen={showPreviewInvoice} toggle={handlePreviewInvoice} centered={true} size={loadingPreview ? 'sm' : 'lg'} scrollable={true} backdrop="static" keyboard={false}>
			{
				!loadingPreview && (
					<ModalHeader toggle={handlePreviewInvoice}>Añadir un complemento</ModalHeader>
				)
			}
			<ModalBody>
				{
      	    loadingPreview && (
      	        <>
      	            <Row>
      	                <Col sm="12" md="12" lg="12" className='text-center'>
      	                    <img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "20%" }} />
      	                </Col>
      	                <Col sm="12" md="12" lg="12" className='text-center mt-1'>
      	                    <p>Cargando ...</p>
      	                </Col>
      	            </Row>
      	        </>
      	    )
      	}
				{
					!loadingPreview && (
						<>
							<Row>
								<Col sm="12" md="6" lg="6">
									<FormGroup>
										<Label>Emisor</Label>
										<Input type="select" size={'sm'} className='form-control form-control-sm input-air-primary' value={formComplement?.commercialLineId ?? ''} onChange={(e) => {
											setFormComplement({
												...formComplement,
												commercialLineId: e.target.value,												
											})
										}}>
											<option value=''>Selecciona una opción</option>
											{
												listCommercialLines?.map((company, index) => (
													<option key={index} value={company.value}>{company.label}</option>
												))
											}
										</Input>
									</FormGroup>
								</Col>
								<Col sm="12" md="6" lg="6">
									<FormGroup>
										<Label>Receptor</Label>
										<Input type="select" size={'sm'} className='form-control form-control-sm input-air-primary' value={formComplement?.companyBusinessNameId ?? ''} onChange={(e) => {
											setFormComplement({
												...formComplement,
												companyBusinessNameId: e.target.value,
												taxRegimeId: clientInformation?.listCompanyBusinessNames?.find(company => company.id_company_business_name === e.target.value)?.id_tax_regime ?? '',
    										type_person: clientInformation?.listCompanyBusinessNames?.find(company => company.id_company_business_name === e.target.value)?.type_person ?? '',  
											})
										}}>
											<option value=''>Selecciona una opción</option>
											{
												clientInformation?.listCompanyBusinessNames?.map((company, index) => (
													<option key={index} value={company.id_company_business_name}>{company.business_name}</option>
												))
											}
										</Input>
									</FormGroup>
								</Col>
								<Col sm="12" md="3" lg="3">
									<FormGroup>
										<Label>Fecha de pago</Label>
										<Input type="date" size={'sm'} className='form-control form-control-sm input-air-primary' value={formComplement.paymentDate} onChange={(e) => {
											setFormComplement({
												...formComplement,
												paymentDate: e.target.value
											})
										}} />
									</FormGroup>
								</Col>
								<Col sm="12" md="4" lg="4">
									<FormGroup>
										<Label>Forma de pago</Label>
										<Input type="select" size={'sm'} className='form-control form-control-sm input-air-primary' value={formComplement.paymentFormId} onChange={(e) => {
											setFormComplement({
												...formComplement,
												paymentFormId: e.target.value
											})
										}}>
											<option value=''>Selecciona una opción</option>
											{
												listPayForms.map((payForm, index) => (
													<option key={index} value={payForm.code}>{payForm.label}</option>
												))
											}
										</Input>
									</FormGroup>
								</Col>
								<Col sm="12" md="5" lg="5">
									<FormGroup>
										<Label>Comentarios adjuntos en el PDF</Label>
										<Input type="text" autoComplete='off' size={'sm'} className='form-control form-control-sm input-air-primary' value={formComplement.comments} onChange={(e) => {
											setFormComplement({
												...formComplement,
												comments: e.target.value
											})
										}} />
									</FormGroup>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col sm="12" md="12" lg="12">
									{
										listInvoices.map((invoice, index) => (
											<Row key={index} className='b-b-1 b-light '>
												<Col sm="12" md="2" lg="3" className='pt-1'>
													<Button color={invoice?.paymentBy === "amount" ? 'light' : 'dark'} size='sm' onClick={() => {
														handleChangeType( invoice?.paymentBy === "amount" ? 'liquided' : 'amount',invoice?.id_invoice)
													}}>
														{
															invoice?.paymentBy === "liquided" ? '* Por monto' : 'Liquidar'
														}
													</Button>
												</Col>
												<Col sm="12" md="2" lg="2" className='text-center m-0'>
													<p className='m-0 f-w-600'>Serie/Folio</p>
													<p className='m-0'>{ invoice?.serie ?? ''} {invoice?.folio ?? ''} </p>
												</Col>
												<Col sm="12" md="2" lg="2" className='text-center'>
													<p className='m-0 f-w-600'>Total</p>
													<p className='m-0'>${ FormatNumbers(invoice?.total ?? 0, 2)} </p>
												</Col>
												<Col sm="12" md="2" lg="2" className='text-center'>
													<p className='m-0 f-w-600'>Saldo</p>
													<p className='m-0'>{ FormatNumbers(invoice?.outside_balance ?? 0, 2)} </p>
												</Col>
												<Col sm="12" md="2" lg="3" className='pt-1'>
													<Input type="number" disabled={ invoice?.paymentBy === "liquided" ? true : false } size={'sm'} className='form-control form-control-sm input-air-primary' 
														value={
															invoice?.paymentBy === "liquided" ? invoice?.outside_balance ?? 0: invoice?.amount
														} 
														onChange={(e) => {
															if (e.target.value !== "")
															{
																if (isNaN(+parseFloat(e.target.value))) return;

																if (parseFloat(e.target.value) <= 0 || parseFloat(e.target.value) > invoice?.outside_balance)
																{
																	createSweet("warning", "warning", "Ingrese una cantidad inferior o igual al saldo");
																	return;
																}
															}
															handleChangeAmount( e.target.value, invoice?.id_invoice);
														}}
														invalid={ invoice?.paymentBy === "liquided" ? false : ( invoice.amount === "" || invoice.amount === "0" || invoice.amount === 0  ) ? true : false }
														
													/>
													<FormFeedback>El monto debe ser mayor a cero</FormFeedback>
												</Col>
											</Row>
										))
									}
								</Col>
							</Row>
						</>
					)
				}
			</ModalBody>
			{
				!loadingPreview && (
					<ModalFooter>
						<Button color='light' size='sm' onClick={handlePreviewInvoice}>Cerrar</Button>
						<Button color='secondary' size='sm' disabled={disbleButton} onClick={handleSaveComplement}>Crear complemento</Button>
					</ModalFooter>
				)
			}
		</Modal>
  )
}
