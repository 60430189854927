import React, { useEffect, useState, useRef } from 'react'
import { useHistory,useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import 'moment/locale/es';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';



export const useViewCalender = () => {


      //*CONFIGURATION CALENDER
      const [weekendsVisible, setWeekendsVisible] = useState(true);
      const [modal, setModal] = useState(false);
      
      //*VARIABLES AND REF COMPONENTS
      let id_commercial_line_l = localStorage.getItem('commercialLineId');
      let viewsEventsActivated = false;
      const history = useHistory();
      const calendarRef = useRef(null);
      const treeViewRef = useRef(null);

      //*VARIABLES GLOBLALES CALENDER
      const [start, setStart] = useState(new Date());
      const [end, setEnd] = useState(new Date());
      const [state, setState] = useState({});

      //*VARIABLES CALENDER
      const [loadingPreview, setLoadingPreview] = useState(false);

      //*VARIABLES MODAL
      const [modalTitle, setModalTitle] = useState("");
      const [modalPreviewInvoice, setModalPreviewInvoice] = useState(false);
      const [countSelected, setCountSelected] = useState(0);

      //*SAVE METHOD
      const [loadingSave, setLoadingSave] = useState(false);

      //*DETAIL VIEW CALENDER
      const [eventView, setEventView] = useState([]);
      const [loadingViews, setLoadingViews] = useState(false);
      const [viewProgramming, setViewProgramming] = useState(null);

      //*DETAIL BRANCH
      const [listBranchesTree, setListBranchesTree] = useState([])

      //*CONFIGURATION VIEWS
      const [dateFilter, setDateFilter] = useState("");
      const [listAmounts, setListAmounts] = useState([])
      const [validInformation, setValidInformation] = useState(false);
      const [loadingProgramming, setLoadingProgramming] = useState(false);

      //*DETAIL INFORMATION CALENDER
      const [detailInformation, setDetailInformation] = useState([])

      //*DATA TABLE
      const [sortKey, setSortKey] = useState(null);
      const [sortOrder, setSortOrder] = useState('asc');
      const [hoveredColumn, setHoveredColumn] = useState("");
      const [isHovered, setIsHovered] = useState(false);
      const [isHovered2, setIsHovered2] = useState(false);
      const [isHovered3, setIsHovered3] = useState(false);
      const [isHovered4, setIsHovered4] = useState(false);

      //*TOTAL
      const [total_Programming, setTotal_Programming] = useState(0);
      const [total_Recollected, setTotal_Recollected] = useState(0);

      //*CONFIGURATIONS BRANCHES
      //!SUM BRANCHES
      const [setserieIndications, setSetserieIndications] = useState([]);
      //!TYPE VALIDATIOS PREVIEW
      const [valid_visible, setValid_visible] = useState(false);
      const [feche_event, setFeche_event] = useState("");
      const [valid_loading_patient, setValid_loading_patient] = useState(false);
      const [patient_excluded, setPatient_excluded] = useState([]);
      //!CONFIGURATION PROGRAMING
      const [branches_programming, setBranches_programming] = useState([]);
      const [data1, setData1] = useState([]);
      const [sum_branches, setSum_branches] = useState(0);
      //!CONFIGURATION RECOLECTED
      const [branches_recolected, setBranches_recolected] = useState([]);
      const [data2, setData2] = useState([]);
      const [sum_branches_recolected, setSum_branches_recoleted] = useState(0);

      //*CONFIGURATIONS CHARTS
      //!CONFIGURATION CHART PROGRAMMING
      const [chartData, setChartData] = useState(null);

      //*CONFIGURATIONS FILTER
      const [state_filter, setState_filter] = useState(true);
      const [viewMonth, setViewMonth] = useState(false);
      const [viewRange, setViewRange] = useState(false);
      //!FILTER TABLE PATIENT
      const [filterCon, setFilterCon] = useState(false);
      const [filterSin, setFilterSin] = useState(false);

      
      //*VIEWS PENDING
      const [loadinfProgramming, setLoadinfProgramming] = useState(false);

      //!RANGE DATE
      const [rangeStart, setRangeStart] = useState(new Date());
      const [rangeEnd, setRangeEnd] = useState(new Date());

      //*EXCLUDED PATIENTS
      const [patient_Include, setPatient_Include] = useState(0);
      const [patient_Excluded, setPatient_Excluded] = useState(0);
      const [total_patient, setTotal_patient] = useState(0);
      const [totalP, setTotalP] = useState(0);

      //*TYPE PERSON
      const [type_filter_person, setType_filter_person] = useState("P");
      const [typePeriod, setTypePeriod] = useState("");

      //*CHART FILTER PERIOD
      const [recoveredBalance, setRecoveredBalance] = useState([]);
      const [programmedAmount, setProgrammedAmount] = useState([]);
      const [labelsBranchs, setLabelsBranchs] = useState([]);
      const dataRecovered = [];
      const dataProgrammed = [];
      const dataLabels = [];


        //*useparams
    const { _start_date, _end_date } = useParams();
    console.log(_start_date);
    console.log(_end_date);


      //*TOOGLE MODAL
      const toggleModalEvent = () => {
        setModalPreviewInvoice(!modalPreviewInvoice);
      }

      const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett,] = useSweetAlert();


      //*FORM CALENDER
      const [formCalender, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        date_1: start,
        date_2: end,
        amount_required: 0,
        title: "",
        observation: "",
        tolerance: 0,
        date1_view: new Date(),
        date1_view2:new Date()
      });

      const {date_1, date_2, amount_required, title, observation,tolerance,date1_view,date1_view2} = formCalender;


  //#region validations
  const [validationsCreate, setValidationsCreate] = useState({
    date_1: false,
    amount_required: false,
    title: false
  });
  //#endregion validations

    //*LIST PROGRAMMING AMOUNT
    useEffect(() => {
      setTimeout(() => {
        calendarRef.current.getApi().updateSize()
      }, 1000);
      return true
  }, [])


  useEffect(() => {
    if (_start_date !== undefined && _end_date !== undefined) {
      handleView(_start_date,_end_date)
    }
}, [_start_date,_end_date])


    //#region Configuration Branches
  //*LIST BRACH
      const getBranches = async () => {

        const _list = await GlobalRequest("GET", "CommercialLine/ListCommercialBranchA", "Sucursales");

        if (_list !== null && _list.length > 0) {

            let _list_branches = [];

            _list.forEach(_branch => {
                _list_branches.push(_branch);
            });

            setListBranchesTree(_list_branches);
        }
        else {
            setListBranchesTree([]);
        }
      }

    //*CONFIGURATION TREE VIEW
      const onRenderItem = (_find) => {
        return (
            <div className="treeview-item-example">
                {
                    
                        <div> <br />
                            {
                                _find.isLeaf === false ?
                                    <span className='color-data'>
                                        <i className="icofont icofont-building-alt f-20 "></i> <label>{_find.text}</label>
                                    </span>
                                    :
                                    <span className='color-data'>
                                        <i className="icofont icofont-building-alt f-20 "></i> <label >{_find.text}</label>
                                    </span>
                            }
                        </div>
                      
                }
            </div>
        )
      }

      const onChangeItemtree = (e) => {

        // *SELECTED BY CHECKBOX COMMERCIAL LINE (ALL)
        if (e.children !== undefined)
        {
          e.children.map(branch => {
            branch.amount = 0;
            branch.state = e.state;
            return branch;
          });

          e.expanded = true;

          setListBranchesTree(
            [
              ...treeViewRef?.current?.api?.getItems(),
              {...e}
            ]    
          ) 
        }
        //* UNIQUE SELECTED BY CHECKBOX BY BRANCH
        if (treeViewRef !== null) 
        {
          const listItems = treeViewRef?.current?.api?.getItems()?.map(branch => {
            
            const findBranch = branch?.children?.find(b => b?.id == e?.id);
            if (findBranch !== undefined)
            {
              findBranch.state = e?.state;
              findBranch.amount = 0;

            } 
            branch.expanded = true;  
          
            return branch;
          })

          setListBranchesTree(      
            [...listItems]
          )  
        }
      }


  //#endregion
  
    //#region Configuration Event
     //*SELECTED CALENDER EVENT
     const handleEvent = (selectInfo) => {
    
      let _findEvent = eventView.find(x => x.date === selectInfo.startStr);
      
      if (_findEvent === undefined && !viewsEventsActivated && selectInfo.view.type === "dayGridMonth") {
        setCountSelected(selectInfo.startStr.length)
        setState({ selectInfo, state: "create" });
        setStart(selectInfo.startStr); 
        setEnd(selectInfo.endDate); 
        setModal(true); 
        setLoadingPreview(true);
        setModalTitle("Programación de Saldos")
        toggleModalEvent();
        getBranches();
        setTimeout(() => {
          setLoadingPreview(false);
        }, 1000);
        viewsEventsActivated = true;
      }
    }

    //*SELECTED GENERAL EVENT
    const handeGeneralEvent = () => {

      setLoadingPreview(true);
      setModalTitle("Programación de saldos mensuales")
      toggleModalEvent();

      setTimeout(() => {
        setLoadingPreview(false);
      }, 1000);

      setCountSelected(0)
      handleUpdateValues({
        ...formCalender,
        amount_required: 0,
        title: "",
        observation: ""
      });
      getBranches();
      setViewMonth(false);
      setViewRange(false);
    }

    //*SELECT DATE AND CALENDER

    useEffect(async () => {
  

      if (_start_date === undefined &&  _end_date === undefined) {
        let _Year = new Date().getFullYear();
        let _Month = (new Date().getMonth() + 1);
        _Month = _Month < 10 ? `0${_Month}` : _Month;
        
        let startDate = `${_Year}-${_Month}-01`;
        let lastDate = new Date(_Year, _Month, 0);
        let lastDateYear = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate()); 
  
        await GetProgrammingAmount({
          startStr: startDate, 
          endStr: lastDateYear
        });
      }
          
  }, [_start_date,_end_date]);

  const handleFilterDate = async() => 
    {
        if (dateFilter !== "") {
      
            let dateIni = dateFilter + "-01";

            let _Year = new Date(dateIni).getUTCFullYear();
            let _Month = (new Date(dateIni+"T00:00:00").getMonth() + 1);
            _Month = _Month < 10 ? `0${_Month}` : _Month;
            
            let lastDate = new Date(_Year, _Month, 0);
            let lastDateYear = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate());
      
            await GetProgrammingAmount({
              startStr: dateIni, 
              endStr: lastDateYear
            });
            calendarRef.current.calendar.gotoDate(new Date(lastDateYear));
        }        
    }

    function handleSelectDate(arg) {

      handleUpdateValues({
        ...formCalender,
        date_1: arg.dateStr,
        date_2: arg.dateStr
      });
      setStart(arg.dateStr);
      setEnd(arg.dateStr);
    }


    //#endregion
    //*END SELECT DATE AND CALENDER

    //*CALCULATE AMOUNT BRANCH
    const handleChangeAmount = (e, _id_branch, amount_required) => {
      const _value = e.target.value;
      let _name_input = e.target.name;

      const api = treeViewRef.current.api;
      const selected_branch = api.getItems();

      selected_branch.forEach(element => {

        let _find_test = element.children.find(x => x.id === _id_branch);

        if (_find_test !== undefined) {
          switch (_name_input) {
            case "amount":
              _find_test.amount = _value;
              break; 
          }
        }
        setListBranchesTree([...selected_branch]);
      });
    };

    const handleClose = () => {

      handleUpdateValues({
        ...formCalender,
        amount_required: 0,
        title: "",
        observation: ""
      });

      setModal(false)
      setStart(new Date());
      setEnd(new Date());
      setState({});
      toggleModalEvent();
      setLoadingViews(false)
    }

    //*FIND EVENT
    const GetProgrammingAmount = async (_valid, _in) => {
     // setLoadingProgramming(true);
      let _validViewTable = _valid.validFech;
    
      if (_valid.startStr === undefined || _valid.startStr === "") {
        return;
      }
    
      let _from = _valid.startStr;
      let _to = _valid.endStr;
      setValidInformation(true);
      setLoadinfProgramming(true)
    
      let _method2 = `ProgrammingSerie/ListProgrammingAmount/${_from}/${_to}`;
    
      const request = await GlobalRequest("GET", _method2, "Reporte Bancario");
    
      if (request != null) {
       // setLoadingProgramming(false);
        setValidInformation(false);
        setLoadinfProgramming(false)

    
        let _validArray = [...eventView];
        let _new_Detail_Information = [];
    
        request.forEach((_find, _key) => {
          let _totalM = 0;
          let _totalR = 0;
    
          _find.commercialBranchTreeView.forEach(_branch => {
            _branch.children.forEach(_find => {
              _totalM += _find.amount;
              _totalR += _find.amount_collected;
            });
          });
    
          let _arrayNew = {
            title: _find.title,
            date: _find.start_date,
            id: _find.id_programming_serie,
            id_event: _validViewTable ? _find.id_programming_serie : _find.id_programming_serie,
            commercialBranchTreeView: _find.commercialBranchTreeView,
            start_date: _find.start_date,
            end_date: _find.start_date,
            sum_branches: _totalM,
            sum_branches_collected: _totalR
          };

    
          setListBranchesTree(_arrayNew.commercialBranchTreeView);
          _validArray.push(_arrayNew);
    
          _new_Detail_Information.push({
            id: _key + 1,
            id_event: !_validViewTable ? _find.id_programming_serie :   request.map(item => item.id_programming_serie),
            title: _find.title,
            start_date: _validViewTable ? `${_from}-${_to}` : _find.start_date,
            date: _find.start_date,
            commercialBranchTreeView: _find.commercialBranchTreeView,
            sum_branches: _totalM,
            sum_branches_collected: _totalR,
            valid: _validViewTable === undefined ? false : true
          });
        });
    
   
    
     
    
        const sumPrograming = _new_Detail_Information.reduce((total, currentItem) => total + currentItem.sum_branches, 0);
        const sumRecollected = _new_Detail_Information.reduce((total, currentItem) => total + currentItem.sum_branches_collected, 0);
    
        setEventView([..._validArray]);
        setListAmounts([]);
        setDetailInformation(_new_Detail_Information);
        setTotal_Programming(sumPrograming);
        setTotal_Recollected(sumRecollected);
      } else {
        setEventView([]);
        setListAmounts([]);
      }
    };

   
    const ViewsEvents = (_viewProgramming) => {
      setLoadingViews(true)
    
        let _findEvent = eventView.find(x => x.id.toString() === _viewProgramming.event.id);
      
        setViewProgramming({
          ..._findEvent
        });

        //!VALID EXPANDED BRANCH
        let _list_branches = 0;
        if (_findEvent.commercialBranchTreeView.length > 0) {
          _list_branches = _findEvent.commercialBranchTreeView.map(_branch => {

              _branch.expanded = true;
              return _branch
          });
        }
    
      setModal(true); 
      setLoadingPreview(true);
      setModalTitle("Programación de Saldos")
    
      setTimeout(() => {
        setLoadingPreview(false);
        setCountSelected(1);
        toggleModalEvent();
    
        handleUpdateValues({
          ...formCalender,
          amount_required: _findEvent.amount,
          title: _findEvent.title,
          observation: _findEvent.observation,
          date_1: _findEvent.date,
          date_2: _findEvent.start_date,
        });
        setStart(_findEvent.start_date); 
        setEnd(_findEvent.end_date); 
        setListBranchesTree(_findEvent.commercialBranchTreeView)
        viewsEventsActivated = false; 

      }, 1000);
    }


    //*CREATE AND UPDATE PROGRAMMING EVENT
    const saveProgrammingDiary = async () => {

      setLoadingProgramming(true);

      if (validationProgramming()) {
        return;
      }

      let _valid_Update = viewProgramming;
      const api = treeViewRef.current.api;
      const selected_branch = api.getItems();
  
      let _validRange = state.selectInfo?.allDay || false;
      let _detailEvent = [];
      let _valid = ""
      let _valid2=""
      let _valid_Create_Update = "false";

        //!VALID RANGE FECH
        if (!_validRange && _valid_Update === null) {
          let startDate = moment(start);
          let endDate = moment(end);
    
          _valid =startDate.format("YYYY-MM-DD")
          _valid2 =endDate.format("YYYY-MM-DD")
            while (startDate.isSameOrBefore(endDate, 'day')) {
              _detailEvent.push({
                "title": title,
                "amount": amount_required,
                "observation": observation,
                "allDay": state.selectInfo?.allDay || false,
                "start_date": startDate.format("YYYY-MM-DD"),
                "end_date": startDate.format("YYYY-MM-DD"),
                "commercialBranchTreeView": selected_branch,
                "id_commercial_line": id_commercial_line_l,
              });
              startDate.add(1, 'day');
            }
          }
        else {
          if (_valid_Update === null) {
            //!VALID ALLDAY 
            _detailEvent.push({
              "title": title,
              "observation": observation,
              "allDay": state.selectInfo?.allDay || false,
              "start_date": moment(date_1).format("YYYY-MM-DD"),
              "end_date": moment(date_2).format("YYYY-MM-DD"),
              "commercialBranchTreeView": selected_branch,
              "id_commercial_line": id_commercial_line_l,
            })
        }
        else {
          _valid_Create_Update = "true"
          _detailEvent.push({
            "title": title,
            "observation": observation,
            "allDay": state.selectInfo?.allDay || false,
            "start_date": moment(date_1).format("YYYY-MM-DD"),
            "end_date": moment(date_2).format("YYYY-MM-DD"),
            "commercialBranchTreeView": _valid_Update.commercialBranchTreeView,
            "id_commercial_line": id_commercial_line_l,
            "id_programming_serie" : _valid_Update.id,
          })
        }
        }
      
      let listTmp = await GlobalRequest("POST", "ProgrammingSerie/CreateAndUpdateProgramming", "Agenda", _detailEvent);
      
      if (listTmp !== null) { 
        setLoadingProgramming(true);
        setModal(false)
        handleClose();

        //!REFRESH EVENT MENSUAL
        if (!_validRange && _valid_Update === null) {
          await GetProgrammingAmount({
            startStr: _valid, 
            endStr: _valid2
          });
        }
        else {
          if (_valid_Update === null) {
            await GetProgrammingAmount({
              startStr: date_1, 
              endStr: date_2
            });
          }
          else {  
            await GetProgrammingAmount({
              startStr: date_1, 
              endStr: date_2
            });
          }
        }
        
        createSweett("", _valid_Create_Update === "true" ? "Se actualizo Correctamente" : "Se genero Correctamente", "success");


      handleUpdateValues({
        ...formCalender,
        amount_required: 0,
        title: "",
        observation: ""
  
      });
      setStart(new Date());
      setEnd(new Date());
      setState({});
      window.location.href = `${process.env.PUBLIC_URL}/visualizador/calender`


      }
    }

    //*CONFIGURATIONS TABLES
     //#region Tables
   
     const handleSort = (key) => {
       let order = 'asc';
       if (sortKey === key && sortOrder === 'asc') {
         order = 'desc';
       }
       const sorted = [...detailInformation].sort((a, b) => {
         if (order === 'asc') {
           return a[key] < b[key] ? -1 : 1;
         } else {
           return a[key] > b[key] ? -1 : 1;
         }
       });
       setDetailInformation(sorted);
       setSortKey(key);
       setSortOrder(order);
     };
 
     const renderSortIcon = (key) => {
       if ((sortKey === key || hoveredColumn === key) && sortOrder === 'asc') {
         return <i className="fa fa-sort-down"></i>;
       }
       if ((sortKey === key || hoveredColumn === key) && sortOrder === 'desc') {
         return <i className="fa fa-sort-asc"></i>;
       }
       return null;
     };
 
     const handleMouseEnter = (key) => {
       setIsHovered(true);
     };
 
     const handleMouseLeave = () => {
       setIsHovered(false);
     };


     const handleMouseEnter1 = (key) => {
      setIsHovered2(true);
    };

    const handleMouseLeave2 = () => {
      setIsHovered2(false);
    };

    //*
    const handleMouseEnter3 = (key) => {
      setIsHovered3(true);
    };

    const handleMouseLeave3 = () => {
      setIsHovered3(false);
    };
    const handleMouseEnter4 = (key) => {
      setIsHovered4(true);
    };

    const handleMouseLeave4 = () => {
      setIsHovered4(false);
    };
    
   //#endregion
    
    //*HANDLE OPEN AND CLOSED DETAIL PATIENTS 
   
    const handleViewDetails = (_id) => {
    
      const ids = Array.isArray(_id) ? _id : [_id];
    
      ids.forEach(id => {
        let _findEvent = eventView.find(x => x.id_event === id);
        let _validAmount = [];
        let _branches_Amount = [];
        let _branches_Amount_Programming = [];
        let _validAmount2 = [];
    
        let totalAmount = 0;
        let totalAmountCollected = 0;
        
        _findEvent.commercialBranchTreeView.forEach(_f => {
          _f.children.forEach(_x => {
            if (_x.amount === 0) {
              return;
            }
            _validAmount.push([_x.amount]);
            _branches_Amount.push(` ${_x.text} : ${_x.amount_collected}`);
            _branches_Amount_Programming.push(` ${_x.text} : ${_x.amount}`);
            _validAmount2.push([_x.amount_collected]);
        
            totalAmount += _x.amount;
            totalAmountCollected += _x.amount_collected;
          });
        });
        
        const _amountProgramming = _validAmount.flatMap(amount => amount);
        const _textProgramming = _branches_Amount.flatMap(text => text);
        const _textProgramming2 = _branches_Amount_Programming.flatMap(text => text);
        const _amountRecolected = _validAmount2.flatMap(amount => amount);

        setSetserieIndications([_findEvent.sum_branches])
        setData1(_amountProgramming)
        setBranches_recolected(_textProgramming)
        setBranches_programming(_textProgramming2)
        setData2(_amountRecolected)
        setValid_visible(true)
        setSum_branches(_findEvent.sum_branches)
        setSum_branches_recoleted(_findEvent.sum_branches_collected)
        setFeche_event(_findEvent.date)
      });
    }

    const handleCloseViewDetails = (_id) => {

      setValid_visible(false)
      setSum_branches(0)
      setSum_branches_recoleted(0)
      setPatient_excluded([])
      setValid_loading_patient(false)
    }

    //#region Configurations Charts
     //*CONFIGURATIOS CHARTS
    
    //!CHART RECOLECTED
      const totalRecolected = data2.reduce((total, currentValue) => total + currentValue, 0);

      const filteredData = [];
      for (let i = 0; i < data2.length; i++) {
        if (totalRecolected >= 0) {
          const percent = (data2[i] / totalRecolected) * 100;
          let label;
          if (percent <= 0) {
            label = 'nada';
          } else if (percent <= 30) {
            label = 'Bajo';
          } else if (percent <= 66) {
            label = 'Medio';
          } else if (percent <= 100) {
            label = 'Alto';
          } else {
            label = 'na';
          }
          filteredData.push({ percent: percent, label: label });
        } else {
          filteredData.push({ percent: data2[i], label: 'Sin clasificar' });
        }
      }

      const colors = filteredData.map(data => {
        if (data.label === 'nada') {
          return '#6c757d';
        } else if (data.label === 'Bajo') {
          return '#00A878';
        } else if (data.label === 'Medio') {
          return '#f7b125';
        } else if (data.label === 'na') {
          return '#6c757d';
        } else {
          return '#f75e25';
        }
      });

      const options1 = {
        series: filteredData.map(data => data.percent),
        chart: {
          height: 590,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        colors: colors,
        labels: branches_recolected, 
          legend: {
          show: true,
          floating: true,
          fontSize: '12px',
          position: 'left',
          offsetX: 50,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            return seriesName;
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
                show: false
            }
          }
        }]
      };

      const ChartProgrammingAmount = {
        series: data1,
        options: {
            labels: branches_programming,
            dataLabels: {
                enabled: true
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                formatter: function (val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            },
            legend: {
                show: true,
                position: "left"
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val
                    }
                },
            },
        },
      }
    //!END CHART RECOLECTED


    //!CHART GENERAL BRANCHES
    function CalculatePercentage(_programming, _recolected) {  
      const _value = _recolected / _programming;
    
      switch(true) {
        case (_value <= 0):
          return 'nada';
        case (_value <= 0.1):
          return 'bajo';
        case (_value <= 0.4):
          return 'medio bajo';
        case (_value <= 0.5):
          return 'medio';
        case (_value <= 1):
          return 'alto';
        default:
          return 'muy alto';
      }
      
    }
    
    const classifications = CalculatePercentage(sum_branches, sum_branches_recolected);
    
    const options = {
      chart: {
        type: 'bar',
        height: 100,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [
        classifications === 'nada' ? '#BDBDBD' :
        classifications === 'bajo' ? '#FF0000' :
        classifications === 'medio bajo' ? '#FFFB46' :
        classifications === 'medio' ? '#FFA500' :
        classifications === 'alto' ? '#00FF00' : '#1da750'
      ],
      xaxis: {
        categories: ['']
      },
      legend: {
        show: false,
        
      },
    };
    //!END CHART GENERAL BRANCHES


    //!CHART BAR INDICATOR
    const percentage = sum_branches_recolected / sum_branches;

    const series = [
      {
        name: 'Nada',
        data: [classifications === 'nada' ? sum_branches * percentage : 0]
      },
      {
        name: 'Bajo',
        data: [classifications === 'bajo' ? sum_branches * percentage : 0]
      },
      {
        name: 'Medio Bajo',
        data: [classifications === 'medio bajo' ? sum_branches * percentage : 0]
      },
      {
        name: 'Medio',
        data: [classifications === 'medio' ? sum_branches * percentage : 0]
      },
      {
        name: 'Alto',
        data: [classifications === 'alto' ? sum_branches * percentage : 0]
      },
      {
        name: 'Muy Alto',
        data: [classifications === 'muy alto' ? sum_branches * percentage : 0]
      }
    ];
    //!END CHART BAR INDICATOR

    //*END CONFIGURATIOS CHARTS
    //#endregion 


    //#region Report Detail Patients 

    //*GET REPORT EXCLUDED
    const handleGeneralPatients = (_id_branch,_type) => {
    
      setValid_loading_patient(true)

      let _valid_event = feche_event
      let _valid_branch = _id_branch

      let _valid_type = _type === "" ? false : true

      GetReportExcluded(_valid_event, _valid_branch,_valid_type,rangeStart,rangeEnd);
    }

    //*REPORT
    const GetReportExcluded = async (_dateStart, _idBranch, _typeFilter,_start,_end) => 
    {
        var Response = {}

        if (_typeFilter) {
          Response = await GlobalRequest("GET", `ProgrammingSerie/GetReportDetailPatientsFilter/${_start}/${_end}?id_branch=${_idBranch}`, "Reporte");
        }
        else {
          Response = await GlobalRequest("GET", `ProgrammingSerie/GetReportDetailPatients/${_dateStart}?id_branch=${_idBranch}`, "Reporte");
        }

        if (Response !== null) {

          let _totalA = 0;
          let _totalB = 0;
          let _totalP = 0;
          let _totalPA = 0;

          Response.forEach(_find => {
            if (_find.patient_invoice === "False") {
              return;
              
            }
            _totalPA += _find.amount?? 0;

            _totalP = Response.length;

            if (_find.patient_invoice === "True") {
              _totalA += _find.amount?? 0;
            }

            if (_find.patient_invoice === "False") {
              _totalB += _find.amount?? 0;
            }
          });

          setValid_loading_patient(true)
          setPatient_excluded(Response)
          setPatient_Include(_totalA)
          setPatient_Excluded(_totalB)
          setTotal_patient(_totalP)
          setTotalP(_totalPA)
          
        }
        else {
            createSweett("Detallado de Pacientes", "No se encontro ningún paciente que cumpla las caracteristicas para este modelo","warning");
            setValid_loading_patient(false)
            setPatient_excluded([])
        }
    }

    function exportToExcel(nimDataExcel, title) {

      let _Report = [];
      let _array = nimDataExcel;
    
      _array.forEach(_find => {
          _Report.push({
              "Nim": _find.nim,
              "Nombre": _find.patient,
              "Condición de Pago": "EFECTIVO",
              "Monto":_find.amount
            
          })
      });
    
    
      const worksheet = XLSX.utils.json_to_sheet(_Report);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileName = 'Repote_Pacientes_Serie_B.xlsx';
    
      if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
      } else {
          const url = window.URL.createObjectURL(dataBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
      }
    }
    
    //#endregion 

    //*CONFIGURATION RANGE INPUT
    const handleView = (_start,_end) => {

     let _1 = moment(_start).format("YYYY-MM-DD");
     let _2 = moment(_end).format("YYYY-MM-DD");

     GetProgrammingAmountFilter({ startStr: _1, endStr: _2, validFech: true });
     //!VALIDATE DATE RANGE
     setRangeStart(_1)
     setRangeEnd(_2)
    }

    //*CONFIGURATIONS FILTER
    const handleFilter = (_a) => {
      setState_filter(_a);
    }
    
    const handleClosedFilter = (_a) => {
      setState_filter(_a);
    }

    const handleClosedInformations = () => {
      setValid_loading_patient(false) 
    }

    
    const handleChangeTypePerson = (typeFilter) => {

      setType_filter_person(typeFilter);
  
  }

  
    const validationProgramming = () => {
      let statusValidation = false;
      let newValidations = {};

      if (formCalender.amount_required === "") {
        newValidations = {
          ...newValidations,
          amount_required: true,
        };

        statusValidation = true;
      }
      else {
        newValidations = {
          ...newValidations,
          amount_required: false,
        };
      }

      if (formCalender.title === "") {
        newValidations = {
          ...newValidations,
          title: true,
        };

        statusValidation = true;
      }
      else {
        newValidations = {
          ...newValidations,
          title: false,
        };
      }


      setValidationsCreate(newValidations);
      return statusValidation;
    }


    //*configurations
    const handleViewMonth = () => {
      setViewMonth(true);
      setViewRange(false);
    }

    const handleViewRange = () => {
      setViewRange(true);
      setViewMonth(false);
    }


    const handleClosedViewRange = async () => {
      setViewRange(false);

      let _Year = new Date().getFullYear();
      let _Month = (new Date().getMonth() + 1);
      _Month = _Month < 10 ? `0${_Month}` : _Month;
      
      let startDate = `${_Year}-${_Month}-01`;
      let lastDate = new Date(_Year, _Month, 0);
      let lastDateYear = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate()); 

      await GetProgrammingAmount({
        startStr: startDate, 
        endStr: lastDateYear
      });

      
    }

    const handleClosedViewMonth = () => {
      setViewMonth(false);
    }     

    const AutorizationFilter = () => toast.success('Carga de datos correcta.', {
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: 'larger-toast'
    });

    //!CONFIGURATION FILTERS
    const GetProgrammingAmountFilter = async (_valid, _in) => {
      setLoadinfProgramming(true);
      let _validViewTable = _valid.validFech;
    
      if (_valid.startStr === undefined || _valid.startStr === "") {
        return;
      }
    
      let _from = _valid.startStr;
      let _to = _valid.endStr;
      setValidInformation(true);
    
      let _method2 = `ProgrammingSerie/ListProgrammingAmount2/${_from}/${_to}`;
    
      const request = await GlobalRequest("GET", _method2, "Reporte Bancario");
    
      if (request != null) {
        setLoadinfProgramming(false)
        AutorizationFilter();

        let _validArray = [...eventView];
        let _new_Detail_Information = [];
    
        request.forEach((_find, _key) => {
          let _totalM = 0;
          let _totalR = 0;
    
          _find.commercialBranchTreeView.forEach(_branch => {
            _branch.children.forEach(_find => {
              _totalM += _find.amount;
              _totalR += _find.amount_collected;
            });
          });
    
          let _arrayNew = {
            title: _find.title,
            date: _find.start_date,
            id: _find.id_programming_serie,
            id_event: _validViewTable ? _find.id_programming_serie : _find.id_programming_serie,
            commercialBranchTreeView: _find.commercialBranchTreeView,
            start_date: _find.start_date,
            end_date: _find.start_date,
            sum_branches: _totalM,
            sum_branches_collected: _totalR
          };

    
          setListBranchesTree(_arrayNew.commercialBranchTreeView);
          _validArray.push(_arrayNew);
    
          _new_Detail_Information.push({
            id: _key + 1,
            id_event: !_validViewTable ? _find.id_programming_serie :   request.map(item => item.id_programming_serie),
            title: _find.title,
            start_date: _validViewTable ? `${_from}-${_to}` : _find.start_date,
            date: _find.start_date,
            commercialBranchTreeView: _find.commercialBranchTreeView,
            sum_branches: _totalM,
            sum_branches_collected: _totalR,
            valid: _validViewTable === undefined ? false : true
          });
        });
    
        const sumPrograming = _new_Detail_Information.reduce((total, currentItem) => total + currentItem.sum_branches, 0);
        const sumRecollected = _new_Detail_Information.reduce((total, currentItem) => total + currentItem.sum_branches_collected, 0);
    
        setEventView([..._validArray]);
        setListAmounts([]);
        setDetailInformation(_new_Detail_Information);
        setTotal_Programming(sumPrograming);
        setTotal_Recollected(sumRecollected);
      } else {
        setEventView([]);
        setListAmounts([]);
       }
    };


    const handleViewDetailsFilter = (_id) => {

      let valid = detailInformation

      let _validAmount = [];
      let _branches_Amount = [];
      let _branches_Amount_Programming = [];
      let _validAmount2 = [];
      let _sum_branches = 0;
      let _sum_branches_collected = 0;
      let _period = ""


      valid.forEach(_find => {
        _sum_branches = _find.sum_branches;
        _sum_branches_collected = _find.sum_branches_collected;
        _period = _find.start_date
        _find.commercialBranchTreeView.forEach(_f => {
          _f.children.forEach(_find => {
            const amount2 = (_find.amount);
            const amount = (_find.amount_collected);
            const amount3 = (_find.text);
            dataRecovered.push(amount);
            dataProgrammed.push(amount2)
            dataLabels.push(amount3)
          });

          let totalAmount = 0;
          let totalAmountCollected = 0;
          _f.children.forEach(_x => {
            if (_x.amount === 0) {
              return;
            }
            _validAmount.push([_x.amount]);
            _branches_Amount.push(` ${_x.text} : ${_x.amount_collected}`);
            _branches_Amount_Programming.push(` ${_x.text} : ${_x.amount}`);
            _validAmount2.push([_x.amount_collected]);
        
            totalAmount += _x.amount;
            totalAmountCollected += _x.amount_collected;
          });
        });
        
      });

      
      const _amountProgramming = _validAmount.flatMap(amount => amount);
      const _textProgramming = _branches_Amount.flatMap(text => text);
      const _textProgramming2 = _branches_Amount_Programming.flatMap(text => text);
      const _amountRecolected = _validAmount2.flatMap(amount => amount);

      setSetserieIndications([valid.sum_branches])
      setData1(_amountProgramming)
      setBranches_recolected(_textProgramming)
      setBranches_programming(_textProgramming2)
      setData2(_amountRecolected)
      setValid_visible(true)
      setSum_branches(_sum_branches)
      setSum_branches_recoleted(_sum_branches_collected)
      setTypePeriod(_period)
      setRecoveredBalance(dataRecovered)
      setProgrammedAmount(dataProgrammed)
      setLabelsBranchs(dataLabels)
    }
    
    //*CONFIGURATION PERIOD CHART
    const chartPeriod = {
      series: [{
          name: 'Monto Recuperado',
          type: 'column',
          data: recoveredBalance
        }, {
          name: 'Monto Programado',
          type: 'area',
          data: programmedAmount
        }],
        options: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
          },
          stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
          },
          plotOptions: {
            bar: {
              columnWidth: '80%'
            }
          },
          
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          labels: labelsBranchs,
          markers: {
            size: 0
          },
          colors:['#183462','#55595c7a'],
          yaxis: {
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$" + y.toFixed(0);
                }
                return y;
              }
            }
          }
        },
    }


    // ? CONFIGURATION DETAIL NIM
    const navigateDetailPatient = (_workOrderId) => {
            
        let value = "" + _workOrderId + "";
        history.push(`../admisiones/${value}/editar`);
    }
  
    // * GLOBAL REQUEST
    const GlobalRequest = async (method, methodUrl, nameMethod, body = null) => {
      let requestOptions = {
        method: method
      };

      if (body !== null) {
        requestOptions = {
          ...requestOptions,
          body: JSON.stringify(body)
        };
      }

      const response = await sendRequest(requestOptions, methodUrl);

      if (response.code === 200) {
        return response.data;
      }
      else if (response.code === 401) {
        validarSesion(history, response.code, GlobalRequest);
      }
      else if (response.code === 404) {
        createSweett("Programación", response.data.msg,"warning");
      }
      else if (response.code === 409) {
        createSweet("warning", "warning", nameMethod, response.data.code);
      }
      else if (response.code === 500) {
        createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
      }
      else {
        createSweet("warning", "warning", nameMethod, response.data.msg);
      }

      return null;
    }

    // * FORMAT NUMBERS
    const number_format = (cantidad, decimals) => {
      cantidad += '';
      cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

      decimals = decimals || 0;

      if (isNaN(cantidad) || cantidad === 0) {
        return parseFloat(0).toFixed(decimals);
      }

      cantidad = '' + cantidad.toFixed(decimals);

      let cantidad_parts = cantidad.split('.'),
        regexp = /(\d+)(\d{3})/;

      while (regexp.test(cantidad_parts[0]))
        cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

      return cantidad_parts.join('.');
    }



  return {

    //*CONFIGURATION CALENDER
    weekendsVisible,

    //*VARIABLES AND REF COMPONENTS
    calendarRef,treeViewRef,
    
    //*VARIABLES GLOBALES CALENDER
    start, end, setEnd, setStart,

    //*VARIABLES CALENDER,
    loadingPreview,

    //*VARIABLES MODAL
    modalTitle, toggleModalEvent, modalPreviewInvoice,handleClose,tolerance,

    //*SAVE METHOD
    loadingSave,

    //*DETAIL VIEW CALENDER
    eventView,loadingViews,viewProgramming,ViewsEvents,

    //*DETAIL BRANCH
    listBranchesTree,

    //*CONFIGURATION VIEWS
    dateFilter, setDateFilter,listAmounts,setListAmounts,validInformation,loadingProgramming,

    //* FORM CALENDER
    amount_required, handleInputChange, title, observation,

    //*INPUT DATE AND TREE
    onRenderItem,onChangeItemtree,handleChangeAmount,handleFilterDate,

    //*FUNCTION
    handleSelectDate,handleEvent, handeGeneralEvent, countSelected,

    //*POST AND GET METHODS
    saveProgrammingDiary,GetProgrammingAmount,

    //*VALIDATIONS
    validationsCreate,

    //*DETAIL INFORMATION CALENDER
    detailInformation,

    //*DATA TABLE
    handleMouseEnter,handleMouseLeave,renderSortIcon,handleSort,isHovered,setHoveredColumn,hoveredColumn,    
    handleMouseEnter1,handleMouseLeave2,isHovered2,

    //*CONFIGURATIONS DETAIL
    handleViewDetails, valid_visible, handleCloseViewDetails,

    //!TOTAL AMOUNTS
    total_Programming,number_format,

    //*CONFIGURATIONS CHARTS
    chartData,sum_branches,

    //!CONFIGURATION PROGRAMING
    options1,ChartProgrammingAmount,

    //!CONFIGURATION RECOLECTED
    total_Recollected,sum_branches_recolected,

    //!CONFIGURATION GLOBAL
    options,series,classifications,

    //*EVENT EXCLUDED PATIENT 
    handleGeneralPatients,feche_event,valid_loading_patient,patient_excluded,

    //*REPORTS
    exportToExcel,

    //*CONFIGURATIONS FILTER
    handleView,state_filter,handleFilter,handleClosedFilter,loadinfProgramming,handleClosedInformations,
    handleChangeTypePerson,type_filter_person,patient_Include,patient_Excluded,total_patient,

    //*FILTER TABLE 
    filterCon,setFilterCon,filterSin,setFilterSin,totalP,

    //*TYPE BUTTONS
    handleMouseEnter3,handleMouseLeave3,isHovered3,handleMouseEnter4,handleMouseLeave4,isHovered4,

    //*CONFIGURATION PERIOD
    handleViewMonth,viewMonth,handleViewRange,viewRange,handleClosedViewRange,handleClosedViewMonth,handleViewDetailsFilter,typePeriod,chartPeriod,

    navigateDetailPatient

  }
}
