//import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useHistory, useParams } from 'react-router';
import { useModal } from 'react-morphing-modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {getAllExamMethods,saveMethodExam} from '../../services/exams/method'


export const useCreateExamSimple = (exam_id, dataAppCodeMethod, methodExam, changeMethodDefault, changeTabGeneral) => {
    // const { examEditId } = useParams();
    // //console.log(exam_id);
    // //console.log("*****");
    // //console.log(dataAppCodeMethod);
    const history = useHistory();
    const [examId, setExamId] = useState(exam_id);
    const [exam, setExam] = useState({});
    const [groupAnalytes, setGroupAnalytes] = useState(false);
    const [typeAnalytes, setTypeAnalytes] = useState([]);
    const [analytes, setAnalytes] = useState([]);
    const [analytesGroup, setAnalytesGroup] = useState([]);
    const [analytesGroupPrint, setAnalytesGroupPrint] = useState({});
    const [analyteId, setAnalyteId] = useState(0);
    const [typePrecodedTexts, setTypePrecodedTexts] = useState({});
    const [units, setUnits] = useState([]);
    const [unitsTo, setUnitsTo] = useState([]);
    const [infoAnalytes, setInfoAnalytes] = useState([]);
    const [selectedInfoAnalytes, setSelectedInfoAnalytes] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [createSweet, sweetConfirmation, sweetDelete, sweetError] = useSweetAlert();

    //const [createSweet] = useSweetAlert();
    const [modal, setModal] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [nameAnalyte, setNameAnalyte] = useState("");
    const [methodActive, setMethodActive] = useState("create");
    const [validateFormula, setValidateFormula] = useState({ validacion: false, msg: "" });
    const [modalTest, setModalTest] = useState(false);
    const [loadingTest, setLoadingTest] = useState(false);
    const [results, setResults] = useState({});
    const [precodedTexts, setPrecodedTexts] = useState({});
    const [listRanges, setListRanges] = useState([]);
    const [rangosGlobales, setRangosGlobales] = useState([]);
    const [analyteNumberRangeId, setAnalyteNumberRangeId] = useState(0);
    const [methodActiveRange, setMethodActiveRange] = useState("create");

    const [loincState, setLoincState] = useState({});

    const [modalRange, setModalRange] = useState(false);
    const [modalOrder, setModalOrder] = useState(false);
    const [modalOrderPrint, setModalOrderPrint] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [loadingOrderPrint, setLoadingOrderPrint] = useState(false);

    const [activeModal, setActiveModal] = useState("");

    const [loadingAnalytes, setLoadingAnalytes] = useState(true);

    // inti FORMULAS COMPLEJAS
    const [analytesAvailables, setAnalytesAvailables] = useState([]);
    const [formComplex, setFormComplex] = useState([]);
    const [responseComplexText, setResponseComplexText] = useState({});
    const [analytesComplex, setAnalytesComplex] = useState([]);
    const [exams, setExams] = useState([]);
    // end FORMULAS COMPLEJAS

    // METHODS
    const [methods, setMethods] = useState([]);
    // END METHODS

    // RANGOS PRECODIFICADOS VARIABLES DEL MODAL
    const [listRangesPrecoded, setListRangesPrecoded] = useState([]);
    const [rangesPrecodedGlobal, setRangesPrecodedGlobal] = useState([]);
    const [analyteNumberCustomValueId, setAnalyteNumberCustomValueId] = useState(0);
    const [methodActiveRangePrecoded, setMethodActiveRangePrecoded] = useState("create");
    // RANGOS PRECODIFICADOS VARIABLES DEL MODAL

    // VALORES DE REFERENCIA Y ALERTAS
    const [listReferenceValues, setListReferenceValues] = useState([]);
    const [referenceValuesGlobal, setReferenceValuesGlobal] = useState([]);
    const [analyteNumberAlertId, setAnalyteNumberAlertId] = useState(0);
    const [methodActiveReferenceValues, setMethodActiveReferenceValues] = useState("create");
    // VALORES DE REFERENCIA Y ALERTAS

    // TEXTOS DE REFERENCIA
    const [listReferenceTexts, setListReferenceTexts] = useState([]);
    const [referenceTextsGlobal, setReferenceTextsGlobal] = useState([]);
    // TEXTOS DE REFERENCIA

    // FORMATO DE IMPRESIÓN DE LOS ANALITOS
    const [valueBold, setValueBold] = useState(false);
    const [valueItalics, setValueItalics] = useState(false);
    const [valueUnderline, setValueUnderline] = useState(false);
    const [valueFormatTransform, setValueFormatTransform] = useState(0);
    const [idDeleteAnalyte, setIdDeleteAnalyte] = useState(0);

    // FORMATO DE IMPRESIÓN DE LOS ANALITOS

    const toggle = () => setIsOpen(!isOpen);
    const toggleModal = () => setModal(!modal);
    const toggleTest = () => setModalTest(!modalTest);
    const toggleRange = () => setModalRange(!modalRange);
    const toggleOrder = () => setModalOrder(!modalOrder);
    const toggleOrderPrint = () => setModalOrderPrint(!modalOrderPrint);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        app_code: "",
        code: "",
        id_analyte_group: -1,
        name_analyte_group: "",
        id_type_analyte: 0,
        id_info_analyte: 0,
        print: 0,
        required: 0,
        id_type_precoded_text: 0,
        integers: 1,
        decimals: 1,
        id_unit: 0,
        id_unit_to: 0,
        compute: "",
        check_compute: false,
        replace: false,
        id_exam: examId,
        loinc_num: null
    });

    const [validaciones, setValidaciones] = useState({
        name: false,
        app_code: false,
        code: false,
        id_analyte_group: false,
        name_analyte_group: false,
        id_type_analyte: false,
        id_info_analyte: false,
        print: false,
        required: false,
        id_type_precoded_text: false,
        integers: false,
        decimals: false,
        compute: false,
        id_exam: false,
    });

    const { name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, check_compute, replace, id_exam, loinc_num } = formValues;

    const [formTest, handleInputTest, resetTest] = useForm({
        init_range: 0,
        end_range: 0,
        value: 0,
        increment: 1
    });

    const { init_range, end_range, value, increment } = formTest;

    // FORMULARIO PARA LOS RANGOS DE EDAD Y GENERO
    const [formRange, handleInputRange, resetRange, handleUpdateRange] = useForm({
        gender: 0,
        method: 0,
        years_from: 0,
        months_from: 0,
        days_from: 0,
        years_to: 0,
        months_to: 0,
        days_to: 0,
    });

    const [validacionesRange, setValidacionesRange] = useState({
        text_range: false,
        range: false,
        overhead: false,
        method: false,
    })

    const { gender, years_from, months_from, days_from, years_to, months_to, days_to, method } = formRange;

    // FORMULARIO PARA RANGO PRECODIFICADO
    const [formRangePrecoded, handleInputRangePrecoded, resetRangePrecoded, handleUpdateRangePrecoded] = useForm({
        id_analyte_age_gender_range: 0,
        init_value: 0,
        end_value: 0,
        text_range: ""
    });

    const { id_analyte_age_gender_range, init_value, end_value, text_range } = formRangePrecoded;

    const getExam = async () => {
        let requestOptions = {
            method: 'GET'
        }

        //console.log(examId);

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + examId);
        //console.log(respuesta);

        if (respuesta.code === 200) {
            // ////console.log("Paso", respuesta.data);
            setExam(respuesta.data);
            if (methodExam === "update") {
                ////console.log(respuesta.data, "EXAMEN DATA DATA");
                let e = { target: { value: respuesta.data.id_app_code_method_default } };
                // ////console.log(e);
                changeMethodDefault(e);

                if (respuesta.data.id_info_analyte !== null) {
                    let obj = {
                        "label": respuesta.data.id_info_analyte.label,
                        "value": respuesta.data.id_info_analyte.value,
                    }
                    setSelectedInfoAnalytes(obj);
                }
                // selectedInfoAnalytes
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const getGroupAnalytes = async () => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Exam/GetGroupAnalytes/" + examId);

        if (respuesta.code === 200) {
            setGroupAnalytes(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getGroupAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Campañas", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }

    const getTypeAnalytes = async () => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Exam/GetTypeAnalytes");

        if (respuesta.code === 200) {
            setTypeAnalytes(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getTypeAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }

    const getTypePrecodedTexts = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "TypePrecodedTexts");
        if (respuesta.code === 200) {
            setTypePrecodedTexts(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getTypePrecodedTexts);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const getAnalytesFromExam = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetAnalytesFromExam/" + examId);
        if (respuesta.code === 200) {
            if (respuesta.data.length > 0) {
                createAnalytesGroups(respuesta.data);
                createAnalytesGroupsPrint(respuesta.data);
                if (analyteId > 0) {
                    let analyte = respuesta.data.find(a => a.id_analyte === parseInt(analyteId));
                    let data = {
                        target: {
                            name: "id_analyte_group",
                            value: analyte.id_analyte_group
                        }
                    }
                    handleInputChange(data);
                }
            }
            setAnalytes([...respuesta.data]);

            setLoadingAnalytes(false);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAnalytesFromExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const getUnits = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Units");
        if (respuesta.code === 200) {
            setUnits(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getUnits);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const getFromTypePrecodedText = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "PrecodedTexts/GetFromTypePrecodedText/" + id_type_precoded_text);
        if (respuesta.code === 200) {
            setPrecodedTexts(respuesta.data);
            getListPrecoded()
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getFromTypePrecodedText);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }
    //pruebaswilson

    const getAnalyteAgeGenderRangesFromAnalyte = async (enableReload = true) => {
        getFromTypePrecodedText(id_type_precoded_text);
        let analyteNumberRangeAux = rangosGlobales.find(a => a.id_analyte === analyteId);
        if (analyteNumberRangeAux === undefined || analyteNumberRangeAux === null) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteAgeGenderRangesFromAnalyte/" + analyteId);
            // ////console.log();
            if (respuesta.code === 200) {


                ////console.log(respuesta.data);
                let newData = respuesta.data.map(function (obj) {
                    let df = obj.days_from;
                    let dt = obj.days_to;
                    let yf = 0;
                    let yt = 0;
                    let mf = 0;
                    let mt = 0;


                    let iconn = <i className="icofont icofont-ui-check text-success f-16"></i>
                    let iconn2 = <i className="icofont icofont-ui-close text-danger f-16"></i>


                    yf = parseInt(obj.days_from / 365);
                    df = df - (yf * 365);
                    mf = parseInt(df / 30);
                    df = df - (mf * 30);

                    yt = parseInt(obj.days_to / 365);
                    dt = dt - (yt * 365);
                    mt = parseInt(dt / 30);
                    dt = dt - (mt * 30);

                    let label_days_from = (yf > 0 ? `${yf} año(s) ` : "") + (mf > 0 ? `${mf} mes(es) ` : "") + (df > 0 ? `${df} día(s)` : "");
                    let label_days_to = (yt > 0 ? `${yt} año(s) ` : "") + (mt > 0 ? `${mt} mes(es) ` : "") + (dt > 0 ? `${dt} día(s)` : "");
                    let metodo = methods.find(m => m.id_app_code_method === obj.id_app_code_method);
                    let machinemodel = null;
                    obj.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                    obj.genero = (obj.id_gender === null ? "Indistinto" : (parseInt(obj.id_gender) === 1 ? "Femenino" : "Masculino"));
                    obj.rango_edad = (obj.days_from === 0 && obj.days_to === 0) ? "N/A" : `${label_days_from} - ${label_days_to}`;
                    obj.gender = (obj.id_gender === null ? 0 : (parseInt(obj.id_gender)));
                    obj.days_from = obj.days_from;
                    obj.days_to = obj.days_to;
                    obj.id_gender = (obj.id_gender === null ? null : (parseInt(obj.id_gender)));
                    ////console.log(obj.machine_model);
                    if (obj.machine_model == null) {
                        machinemodel = "";
                    } else {
                        machinemodel = obj.machine_model;
                    }
                    obj.metodo = metodo === undefined ? "" : metodo.name_method + "-" + machinemodel;
                    // obj.metodo = methods.find(x => x.id_app_code_method === obj.id_app_code_method).name_method;
                    // metodo: methods.find(m => m.id_app_code_method === parseInt(method)).name_method,
                    // obj.metodo = methods.find(x => x.id_exam_method === obj.id_exam_method).name;
                    obj.id_exam_method = obj.id_exam_method;
                    obj.id_method = obj.id_method ; 
                    // obj.id_exam_method = obj.id_exam_method;
                    // obj.precoded_text = ( obj.precoded_text === true ? "✓" : "X"  )
                    // obj.reference_values = (  obj.reference_values === true ? "✓" : "X")

                    obj.status = (obj.reference_values || obj.precoded_text || obj.text_reference_values ? iconn : iconn2)
                    //obj.reference_values = (obj.reference_values === true ? "✓" : "X")
                    return obj;
                });
                console.log("🚀 ~ getAnalyteAgeGenderRangesFromAnalyte ~ allRangeAnalytes:", newData)

                setListRanges([...newData]);

                // if (enableReload) {
                //     setTimeout(() => {
                //         window.location.reload();
                //     }, 1000);
                // }



            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, getAnalyteAgeGenderRangesFromAnalyte);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
            // else {
            //     validarSesion(history, respuesta.code, getAnalyteAgeGenderRangesFromAnalyte);
            // }
        } else {
            setListRanges(analyteNumberRangeAux.rangos);
        }
    }

    const getAnalyteNumberCustomValues = async () => {
        let analyteNumberCustomValues = rangesPrecodedGlobal.find(a => a.id_analyte === analyteId);
        if (analyteNumberCustomValues === undefined || analyteNumberCustomValues === null) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteNumberCustomValues/" + analyteId);
            if (respuesta.code === 200) {
                let newListRangesPrecoded = [];
                respuesta.data.forEach(function (obj) {

                    let df = obj.days_from;
                    let dt = obj.days_to;
                    let yf = 0;
                    let yt = 0;
                    let mf = 0;
                    let mt = 0;

                    yf = parseInt(obj.days_from / 365);
                    df = df - (yf * 365);
                    mf = parseInt(df / 30);
                    df = df - (mf * 30);

                    yt = parseInt(obj.days_to / 365);
                    dt = dt - (yt * 365);
                    mt = parseInt(dt / 30);
                    dt = dt - (mt * 30);

                    let label_days_from = (yf > 0 ? `${yf} año(s) ` : "") + (mf > 0 ? `${mf} mes(es) ` : "") + (df > 0 ? `${df} día(s)` : "");
                    let label_days_to = (yt > 0 ? `${yt} año(s) ` : "") + (mt > 0 ? `${mt} mes(es) ` : "") + (dt > 0 ? `${dt} día(s)` : "");

                    newListRangesPrecoded.push({
                        id_analyte_number_custom_value: obj.id_analyte_number_custom_value,
                        id_analyte_age_gender_range: obj.id_analyte_age_gender_range,
                        age_gender_range_format: label_days_from + "-" + label_days_to + " // " + obj.age_gender_range_format,
                        init_value: obj.from,
                        end_value: obj.to,
                        text_range: obj.name_precoded_text,
                        text_format: obj.name_precoded_text === null ? obj.text : obj.name_precoded_text,
                        id_precoded_text: (obj.id_precoded_text > 0) ? obj.id_precoded_text : null,
                        from: obj.from,
                        to: obj.to,

                    });
                });
                setListRangesPrecoded(newListRangesPrecoded);
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, getAnalyteNumberCustomValues);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
        }
    }

    const getMethods = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, `AppCodeMethods/ListFromInfoAnalyte/${id_info_analyte}?id_exam=${exam_id}` );
        console.log("🚀 ~ getMethods ~ respuesta-Save-Anlyte:", respuesta)


        if (respuesta.code === 200) {
            // ////console.log("Entra por aquí de los metodos");
            // ////console.log(respuesta.data);
            setMethods(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getMethods);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const getInfoAnalytes = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/ListAnalytes");
        console.log("🚀 ~ getInfoAnalytes ~ respuesta:", respuesta)

        if (respuesta.code === 200) {
            let array_aux = [];
            respuesta.data.forEach(element => {
                array_aux.push({
                    "label": element.code + "(" + element.name + ")",
                    "value": element.id_info_analyte,
                    ...element
                })
            });

            setInfoAnalytes(array_aux)
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getInfoAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }

    const handleValueInfoAnalyte = ({ label, value }) => {
        setSelectedInfoAnalytes({
            "label": label,
            "value": value
        });
        let analyte_info = infoAnalytes.find(x => x.id_info_analyte === parseInt(value));
        // ? VALIDATION METHOD
        if (!analyte_info?.has_method) {
            createSweet("error", "error", "Busqueda de analito", "El analito seleccionado no tiene asociado un metódo");
            setSelectedInfoAnalytes({})
            return; 
        }
      
            if (analyte_info !== undefined) {                
                handleUpdateValues({
                    ...formValues,
                    name: analyte_info.name,
                    app_code: analyte_info.app_code,
                    code: analyte_info.code,
                    id_info_analyte: value
                });
            }
    }

    useEffect(() => {
        if (history.location.state !== undefined && history.location.state.changeTab === 2) {
            changeTabGeneral('3');
            // setMethodActive("edit");
        }
    }, [history])


    useEffect(() => {

        if (infoAnalytes.length > 0 && selectedInfoAnalytes.value !== undefined && methodExam === "update") {
            let analyte_info = infoAnalytes.find(x => x.id_info_analyte === parseInt(selectedInfoAnalytes.value));
            // ////console.log(analyte_info);
            if (analyte_info !== undefined) {
                handleUpdateValues({
                    ...formValues,
                    name: analyte_info.name,
                    app_code: analyte_info.app_code,
                    code: analyte_info.code,
                    id_info_analyte: parseInt(selectedInfoAnalytes.value)
                });
            }

        }

    }, [infoAnalytes, selectedInfoAnalytes, method])

    // getMethods();

    useEffect(() => {

        if (methodExam === "update" && id_info_analyte > 0) {
            getMethods();
        } else if (id_info_analyte > 0) {
            getMethods();
        }

    }, [id_info_analyte, methodExam])


    // ////console.log(id_info_analyte);

    useEffect(() => {
        // getExam();
        // getGroupAnalytes();
        getTypeAnalytes();
        getTypePrecodedTexts();
        // getAnalytesFromExam();
        getUnits();
        // getMethods();
        getInfoAnalytes();
    }, []);

    useEffect(() => {
        if (exam_id > 0) {
            //console.log("pasas");
            getExam();
            // getGroupAnalytes();
            // getAnalytesFromExam();
            // getMethods();
            // getInfoAnalytes();
        }
    }, [dataAppCodeMethod]);

    useEffect(() => {
        if (exam_id > 0) {
            setExamId(exam_id);

        }
    }, [exam_id]);

    useEffect(() => {
        // methodExam
        // ////console.log(methodExam, examId);
        if (methodExam === "update" && examId > 0) {
            getExam();
            getAnalytesFromExam();
        }

    }, [methodExam, examId])

    useEffect(() => {

        if (methodExam === "update" && analytes.length === 1) {
            handleEdit(analytes[0].id_analyte);
        }

    }, [analytes])



    const getExams = useCallback(async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/List");

        if (respuesta.code === 200) {
            let examenes = respuesta.data.results.filter(e => e.id_exam !== parseInt(examId));
            setExams(examenes);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExams);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
    }, [exams]);


    const addAnalyte = (e) => {
        console.log("🚀 ~ addAnalyte ~ e:", e)
        if (e !== undefined) {
            e.preventDefault();
        }
        //e.preventDefault();
        if (validarForm()) return;
        let newAnalytes = analytes;
        let id_aux = 0 - (newAnalytes.length + 1);
        let analyte_age_gender_ranges = null;
        let analyte_number_ranges = null;
        let complex_value_analytes = null;
        let analyte_number_alerts = null;
        let analyte_text_alerts = null;
        let list_availables = null;

        if (parseInt(id_type_analyte) === 1) {
            let pA = selected;
            // ////console.log(pA,"Datos que debe traer");
            if (pA !== undefined && pA !== null) {
                list_availables = pA;
            }
        }



        if (parseInt(id_type_analyte) === 1 || parseInt(id_type_analyte) === 2 || parseInt(id_type_analyte) === 3 || parseInt(id_type_analyte) === 4 || parseInt(id_type_analyte) === 5 || parseInt(id_type_analyte) === 6 || parseInt(id_type_analyte) === 7) {
            let anr = rangosGlobales.find(rg => rg.id_analyte === analyteId);
            if (anr !== undefined && anr !== null) {
                analyte_age_gender_ranges = anr.rangos;
            }

            let rv = referenceValuesGlobal.find(x => x.id_analyte === analyteId);
            if (rv !== undefined && rv !== null) {
                analyte_number_alerts = rv.analyte_number_alerts
            }

            let rt = referenceTextsGlobal.find(x => x.id_analyte === analyteId);
            // ////console.log(rt);
            if (rt !== undefined && rt !== null) {
                analyte_text_alerts = rt.analyte_text_alerts
            }
        }

        if (parseInt(id_type_analyte) === 4 || parseInt(id_type_analyte) === 5) {
            let rangos = rangesPrecodedGlobal.find(x => x.id_analyte === analyteId);
            if (rangos !== undefined && rangos !== null) {
                analyte_number_ranges = rangos.rangosPrecoded;
            }
        }

        if (parseInt(id_type_analyte) === 7) {
            complex_value_analytes = Object.values(formComplex);
        }
        newAnalytes.push({
            id_analyte: id_aux,
            ...formValues,
            id_exam: examId,
            name_type_analyte: typeAnalytes.find(ta => ta.id_type_analyte === parseInt(id_type_analyte)).name,
            isTemp: true,
            analyte_age_gender_ranges: analyte_age_gender_ranges,
            analyte_number_ranges: analyte_number_ranges,
            complex_value_analytes: complex_value_analytes,
            analyte_number_alerts: analyte_number_alerts,
            print_bold_font: formValues.print ? valueBold : false,
            print_italics_font: formValues.print ? valueItalics : false,
            print_underlined_font: formValues.print ? valueUnderline : false,
            print_format_transform: formValues.print ? valueFormatTransform : 0,
            isSimple: true,
            list_availables: list_availables
        });
        setAnalytes(newAnalytes);
        // ////console.log(newAnalytes);
        saveAnalytes(e === undefined ? false : true);
        // setLoincState({});
        // reset();
    }

    const removeAnalyte = (id_analyte) => {
        toggleModal();
        let analyte = analytes.find(a => a.id_analyte === id_analyte);
        setNameAnalyte(analyte.name);
        setAnalyteId(id_analyte);
    }

    const deleteAnalyte = async (e) => {
        e.preventDefault();
        let analyte = analytes.find(a => a.id_analyte === analyteId);
        if (!analyte.isTemp) {
            let requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };
            setLoadingModal(true);
            const respuesta = await sendRequest(requestOptions, `Exam/DeleteAnalyte/${analyteId}`);
            if (respuesta.code === 200) {
                let newAnalytes = analytes.filter(u => u.id_analyte !== analyteId);
                setAnalytes(newAnalytes);
                setLoadingModal(false);
                toggleModal();
                createSweet("delete", "success", "Se elimino el analito de forma correcta");
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, deleteAnalyte);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
        } else {
            let newAnalytes = analytes.filter(a => a.id_analyte !== analyteId);
            setAnalytes(newAnalytes);
            setLoadingModal(false);
            toggleModal();
            createSweet("delete", "success", "Se elimino el analito de forma correcta");
        }
    }

    const saveAnalytes = async (enableReload = true) => {
        ////console.log(enableReload);
        setLoading(true);
        let raw = JSON.stringify(analytes);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Exam/CreateOrUpdateAnalytes");
        if (respuesta.code === 200) {
            getAnalytesFromExam();
            getGroupAnalytes();
            // ////console.log("pasa por aca....");
            setRangosGlobales([]);
            // reset();
            setLoading(false);
            createSweet("create", "success", "Examen guardado correctamente");

            ////console.log("paso aqui ");//eror de recarga
            if (enableReload) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     ////console.log("Error al crear los analitos...");
        //     validarSesion(history, respuesta.code, saveAnalytes);
        // }

    }

    const handleEdit = (id_analyte) => {
        let analyte = analytes.find(a => a.id_analyte === id_analyte);
        setNameAnalyte(analyte.name);
        setAnalyteId(id_analyte);
        setUnitsTo({});
        if (analyte.id_unit !== null) {
            getLinkedUnits(analyte.id_unit);
        }
        if (analyte.compute !== "" && analyte.compute !== null) {
            setValidateFormula({
                validacion: true,
                msg: ""
            });
        }
        if (analyte.loinc_num !== null) {
            setLoincState({
                value: analyte.loinc_num,
                label: `${analyte.component} [${analyte.loinc_num}]`
            });
        } else {
            setLoincState({});
        }

        setValueBold(analyte.print_bold_font);
        setValueItalics(analyte.print_italics_font);
        setValueUnderline(analyte.print_underlined_font);
        setValueFormatTransform(analyte.print_format_transform);

        if (analyte.id_type_precoded_text !== null ? analyte.id_type_precoded_text : 0) {
            getListPrecoded(analyte.id_type_precoded_text);
            // ////console.log(analyte.id_analyte);
            getPrecodedtexts(analyte.id_analyte)
        }


        handleUpdateValues({
            name: analyte.name,
            app_code: analyte.app_code,
            code: analyte.code,
            id_analyte_group: (analyte.id_analyte_group > 0 ? analyte.id_analyte_group : -1),
            name_analyte_group: (analyte.id_analyte_group > 0 ? analyte.name_analyte_group : ""),
            id_type_analyte: analyte.id_type_analyte.toString(),
            id_info_analyte: analyte.id_info_analyte.toString(),
            loinc_num: analyte.loinc_num,
            print: analyte.print,
            required: analyte.required,
            id_type_precoded_text: (analyte.id_type_precoded_text !== null ? analyte.id_type_precoded_text : 0),
            integers: (analyte.integers !== null ? analyte.integers : 0),
            decimals: (analyte.decimals !== null ? analyte.decimals : 0),
            id_unit: (analyte.id_unit !== null ? analyte.id_unit : 0),
            id_unit_to: (analyte.id_unit_to !== null ? analyte.id_unit_to : 0),
            compute: analyte.compute,
            check_compute: (analyte.compute !== "" ? true : false),
            replace: (analyte.replace !== null) ? analyte.replace : false,
            id_exam: examId
        });
        setMethodActive("edit");
    }

    const editAnalyte = async (e) => {

        if (e !== undefined) {
            e.preventDefault();
        }

        if (validarForm()) return;
        let analyte_age_gender_ranges = null;
        let analyte_number_ranges = null;
        let complex_value_analytes = null;
        let analyte_number_alerts = null;
        let analyte_text_alerts = null;
        let list_availables = null;

        if (parseInt(id_type_analyte) === 1) {
            let pA = selected;
            //.log(pA,"Datos que debe traer");
            if (pA !== undefined && pA !== null) {
                list_availables = pA;
            }
        }




        if (parseInt(id_type_analyte) === 1 || parseInt(id_type_analyte) === 2 || parseInt(id_type_analyte) === 3 || parseInt(id_type_analyte) === 4 || parseInt(id_type_analyte) === 5 || parseInt(id_type_analyte) === 6 || parseInt(id_type_analyte) === 7) {
            let anr = rangosGlobales.find(rg => rg.id_analyte === analyteId);
            if (anr !== undefined && anr !== null) {
                analyte_age_gender_ranges = anr.rangos;
            }

            let rv = referenceValuesGlobal.find(x => x.id_analyte === analyteId);
            if (rv !== undefined && rv !== null) {
                analyte_number_alerts = rv.analyte_number_alerts
            }
            // ////console.log(referenceTextsGlobal);
            let rt = referenceTextsGlobal.find(x => x.id_analyte === analyteId);
            // ////console.log(rt);
            if (rt !== undefined && rt !== null) {
                analyte_text_alerts = rt.analyte_text_alerts
            }
        }

        if (parseInt(id_type_analyte) === 4 || parseInt(id_type_analyte) === 5) {
            let rangos = rangesPrecodedGlobal.find(x => x.id_analyte === analyteId);
            if (rangos !== undefined && rangos !== null) {
                analyte_number_ranges = rangos.rangosPrecoded;
            }
        }

        if (parseInt(id_type_analyte) === 7) {
            complex_value_analytes = Object.values(formComplex);
        }
        let newAnalytes = analytes.map(function (obj) {
            if (obj.id_analyte === analyteId) {
                obj.name = formValues.name;
                obj.app_code = formValues.app_code;
                obj.code = formValues.code;
                obj.print = formValues.print;
                obj.required = formValues.required;
                obj.id_analyte_group = formValues.id_analyte_group;
                obj.name_analyte_group = formValues.name_analyte_group;
                obj.id_type_analyte = formValues.id_type_analyte;
                obj.id_info_analyte = formValues.id_info_analyte;
                obj.loinc_num = formValues.loinc_num;
                obj.id_type_precoded_text = (parseInt(formValues.id_type_analyte) === 1 || parseInt(formValues.id_type_analyte) === 4 || parseInt(formValues.id_type_analyte) === 5 || parseInt(formValues.id_type_analyte) === 7) ? formValues.id_type_precoded_text : 0;
                obj.integers = formValues.integers;
                obj.decimals = formValues.decimals;
                obj.id_unit = formValues.id_unit;
                obj.id_unit_to = formValues.id_unit_to;
                obj.compute = formValues.check_compute ? formValues.compute : (parseInt(formValues.id_type_analyte) === 7 ? formValues.compute : "");
                obj.replace = formValues.replace ? formValues.replace : false;
                obj.check_compute = formValues.check_compute;
                obj.name_type_analyte = typeAnalytes.find(ta => ta.id_type_analyte === parseInt(formValues.id_type_analyte)).name
                obj.isTemp = true;
                obj.analyte_age_gender_ranges = analyte_age_gender_ranges;
                obj.complex_value_analytes = complex_value_analytes;
                obj.analyte_number_ranges = analyte_number_ranges;
                obj.analyte_number_alerts = analyte_number_alerts;
                obj.analyte_text_alerts = analyte_text_alerts;
                obj.print_bold_font = formValues.print ? valueBold : false;
                obj.print_italics_font = formValues.print ? valueItalics : false;
                obj.print_underlined_font = formValues.print ? valueUnderline : false;
                obj.print_format_transform = formValues.print ? valueFormatTransform : 0;
                obj.list_availables = list_availables
            }
            // ////console.log(obj);
            return obj;
        });
        setAnalytes(newAnalytes);
        saveAnalytes(e === undefined ? false : true);
        // setLoincState({});
        // backCreate();
    }

    const backCreate = () => {
        setUnitsTo({});
        setAnalyteId(0);
        setValidateFormula({
            validacion: false,
            msg: ""
        });
        handleUpdateValues({
            name: "",
            app_code: "",
            code: "",
            id_analyte_group: -1,
            name_analyte_group: "",
            id_type_analyte: 0,
            id_info_analyte: 0,
            print: 0,
            required: 0,
            id_type_precoded_text: 0,
            integers: 1,
            decimals: 1,
            id_unit: 0,
            id_unit_to: 0,
            compute: "",
            check_compute: false,
            id_exam: examId
        })
        setMethodActive("create");
    }

    const changeUnit = (e) => {
        handleInputChange(e);
        handleUpdateValues({
            ...formValues,
            id_unit: e.target.value,
            id_unit_to: 0
        });
        getLinkedUnits(e.target.value);
    }

    const getLinkedUnits = async (id_unit_from) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "UnitConversions/GetLinkedUnits/" + id_unit_from);
        if (respuesta.code === 200) {
            setUnitsTo(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getLinkedUnits);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getLinkedUnits);
        // }
    }

    const validarFormula = async () => {
        let newForm = {
            "formula": formValues.compute,
            "valor": 1
        }
        let raw = JSON.stringify(newForm);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/ValidarFormula");

        if (respuesta.code === 200) {
            if (respuesta.data.validacion) {
                setValidateFormula({
                    validacion: true,
                    msg: ""
                });
            } else {
                setValidateFormula({
                    validacion: false,
                    msg: respuesta.data.msg
                });
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validarFormula);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, validarFormula);
        // }
    }

    const resetValidarFormula = () => {
        setValidateFormula({
            validacion: false,
            msg: ""
        });
    }

    const resetResults = () => {
        setResults({});
    }

    const handleModalTest = () => {
        resetTest();
        toggleTest();
        resetResults();
    }

    const testearFormula = async (e) => {
        e.preventDefault();
        setLoadingTest(true);
        let newForm = {
            "formula": formValues.compute,
            ...formTest
        }

        let raw = JSON.stringify(newForm);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/TestearFormula");

        if (respuesta.code === 200) {
            setResults(respuesta.data);
            setLoadingTest(false);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, testearFormula);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, testearFormula);
        // }
    }

    const { modalProps, close, open } = useModal({
        background: '#f2f2f2',
    });

    const modalFullRef = useRef(null);

    const handleFullModal = (type) => {
        if (analyteId === 0) {
            let id_aux_analyte = 0 - (listRanges.length + 1);
            setAnalyteId(id_aux_analyte);
        }
        if (type === "modalRangos") {
            setActiveModal("modalRangos");
            backToCreateRange();
            backToCreateRangePrecoded();
            getAnalyteAgeGenderRangesFromAnalyte();
            getAnalyteNumberCustomValues();
            getAnalyteNumberAlerts();
            getAnalyteTextAlerts();
            setValidacionesRange({
                text_range: false,
                range: false,
                overhead: false,
                method: false
            });
        } else {
            if (analyteId > 0) {
                getAnalytesComplex();
            } else {
                setAnalytesComplex([]);
            }
            getExams();
            setResponseComplexText({});
            getAnalytesAvailableComplex();
            setActiveModal("modalComplex");
        }

        open(modalFullRef);
    }

    const backToCreateRange = () => {
        setAnalyteNumberRangeId(0);
        handleUpdateRange({
            gender: 0,
            method: 0,
            years_from: 0,
            months_from: 0,
            days_from: 0,
            years_to: 0,
            months_to: 0,
            days_to: 0,
        });
        setMethodActiveRange("create");
    }

    const backToCreateRangePrecoded = () => {
        setAnalyteNumberCustomValueId(0);
        handleUpdateRangePrecoded({
            id_analyte_age_gender_range: 0,
            init_value: 0,
            end_value: 0,
            text_range: ""
        });
        setMethodActiveRangePrecoded("create");
    }


    const addAnalyteNumberRange = (e) => {   
        
        console.log("🚀 ~ addAnalyteNumberRange ~ method:", method)
        console.log("🚀 ~ addAnalyteNumberRange ~ methods:", methods)


        
        e.preventDefault();
        let iconn2 = <i className="icofont icofont-ui-close text-danger f-16"></i>

        let [id_exam_methodd, id_app_code_methodd] = method.split('-');

        console.log(id_exam_methodd); 
        console.log(id_app_code_methodd); 

        if (validarFormRange()) return;
        let newListRanges = listRanges;
        let id_aux = 0 - (newListRanges.length + 1);
        let days_from_aux = (parseInt(years_from * 365) + parseInt(months_from * 30) + parseInt(days_from));
        let days_to_aux = (parseInt(years_to * 365) + parseInt(months_to * 30) + parseInt(days_to));
        let label_days_from = (years_from > 0 ? `${years_from} año(s) ` : "") + (months_from > 0 ? `${months_from} mes(es) ` : "") + (days_from > 0 ? `${days_from} día(s)` : "");
        let label_days_to = (years_to > 0 ? `${years_to} año(s) ` : "") + (months_to > 0 ? `${months_to} mes(es) ` : "") + (days_to > 0 ? `${days_to} día(s)` : "");
        newListRanges.push({
            id_analyte_age_gender_range: id_aux,
            id_analyte_number_range: id_aux,
            genero: (parseInt(gender) === 0 ? "Indistinto" : (parseInt(gender) === 1 ? "Femenino" : "Masculino")),
            rango_edad: (days_from_aux === 0 && days_to_aux === 0) ? "N/A" : `${label_days_from} - ${label_days_to}`,
            ...formRange,
            id_exam_method: id_exam_methodd,
            id_app_code_method : (id_app_code_methodd === "" || id_app_code_methodd === null) ?  methods.find(m => m.id_exam_method === parseInt(method)).id_app_code_method :  methods.find(m => m.id_app_code_method === parseInt(id_app_code_methodd)).id_app_code_method,
            metodo: (id_app_code_methodd === "" || id_app_code_methodd === null) ?  methods.find(m => m.id_exam_method === parseInt(method)).name_method : methods.find(m => m.id_app_code_method === parseInt(id_app_code_methodd)).name_method ,
            days_from: days_from_aux,
            days_to: days_to_aux,
            id_gender: (parseInt(gender) === 0 ? null : (parseInt(gender))),
            status: iconn2
        });
        console.log("🚀 ~ addAnalyteNumberRange ~ Create-New:", newListRanges)
        setListRanges(newListRanges);
        // ////console.log(newListRanges);
        handleUpdateRange({
            gender: gender,
            method: 0,
            years_from: years_from,
            months_from: months_from,
            days_from: days_from,
            years_to: years_to,
            months_to: months_to,
            days_to: days_to,
            status: iconn2
        })
    }

    const editAnalyteNumberRange = (id) => {
        setAnalyteNumberRangeId(id);
        let analyte_number_range = listRanges.find(lr => lr.id_analyte_age_gender_range === id);
        let df = analyte_number_range.days_from;
        let dt = analyte_number_range.days_to;
        let yf = 0;
        let yt = 0;
        let mf = 0;
        let mt = 0;
        if (analyte_number_range.days_from === 0) {
            df = 0
        } else {
            if (id > 0) {
                if (!analyte_number_range.edited) {
                    yf = parseInt(analyte_number_range.days_from / 365);
                    df = df - (yf * 365);
                    mf = parseInt(df / 30);
                    df = df - (mf * 30);
                }
            } else {
                yf = parseInt(analyte_number_range.days_from / 365);
                df = df - (yf * 365);
                mf = parseInt(df / 30);
                df = df - (mf * 30);
            }
        }

        if (analyte_number_range.days_to === 0) {
            dt = 0
        } else {
            if (id > 0) {
                if (!analyte_number_range.edited) {
                    yt = parseInt(analyte_number_range.days_to / 365);
                    dt = dt - (yt * 365);
                    mt = parseInt(dt / 30);
                    dt = dt - (mt * 30);
                }
            } else {
                yt = parseInt(analyte_number_range.days_to / 365);
                dt = dt - (yt * 365);
                mt = parseInt(dt / 30);
                dt = dt - (mt * 30);
            }
        }
        setValidacionesRange({
            text_range: false,
            range: false,
            overhead: false,
            method: false
        })
        handleUpdateRange({
            gender: analyte_number_range.gender,
            method: analyte_number_range.id_exam_method,
            years_from: yf,
            months_from: mf,
            days_from: df,
            years_to: yt,
            months_to: mt,
            days_to: dt,
        });
        setMethodActiveRange("edit");
    }

    const removeAnalyteNumberRange = async (id, confirmation = -1) => {


        let analyteNumberRange = listRanges.find(anr => anr.id_analyte_age_gender_range === id);


        if (analyteNumberRange.id_analyte_age_gender_range > 0) {

            let copyAlertDelete = "El método " + analyteNumberRange.metodo + " será eliminado ";

            if (confirmation === -1) {
                sweetDelete("Valor de Referencia", "", "warning", true, copyAlertDelete, removeAnalyteNumberRange, id);
                return;
            }

            if (!confirmation) {
                return;
            }


            let requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, `Exam/DeleteAnalyteAgeGenderRange/${id}`);
            // ////console.log(respuesta)
            if (respuesta.code === 200) {
                let newListRanges = listRanges.filter(lr => lr.id_analyte_age_gender_range !== id);
                setListRanges(newListRanges);
                createSweet("delete", "success", "Se elimino el valor de referencia de forma correcta");
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, removeAnalyteNumberRange);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
            // else {
            //     validarSesion(history, respuesta.code, removeAnalyteNumberRange);
            //     ////console.log("Error");
            // }
        } else {
            let newAnalytesNumberRanges = listRanges.filter(anr => anr.id_analyte_age_gender_range !== id);
            setListRanges(newAnalytesNumberRanges);
            createSweet("delete", "success", "Se elimino el valor de referencia de forma correcta");
        }
    }

    //}

    const addRangePrecoded = (e) => {
        e.preventDefault();

        let newListRangesPrecoded = listRangesPrecoded;
        let objMethod = listRanges.find(x => parseInt(x.id_analyte_age_gender_range) === parseInt(id_analyte_age_gender_range));
        let id_aux = 0 - (newListRangesPrecoded.length + 1);
        newListRangesPrecoded.push({
            id_analyte_number_custom_value: id_aux,
            id_analyte_age_gender_range: id_analyte_age_gender_range,
            age_gender_range_format: `${objMethod.rango_edad} // ${objMethod.metodo} // ${objMethod.genero}`,
            init_value: init_value,
            end_value: end_value,
            text_range: text_range,
            text_format: (parseInt(id_type_precoded_text) === 0 ? text_range : precodedTexts.find(a => a.id_precoded_text === parseInt(text_range)).text),
            id_precoded_text: (parseInt(id_type_precoded_text) === 0 ? null : (precodedTexts.length > 0 ? parseInt(text_range) : null)),
            from: init_value,
            to: end_value
        });
        setListRangesPrecoded(newListRangesPrecoded);
        handleUpdateRangePrecoded({
            id_analyte_age_gender_range: 0,
            init_value: 0,
            end_value: 0,
            text_range: ""
        })
    }

    const editRangePrecoded = (id) => {
        setAnalyteNumberCustomValueId(id);

        let analyte_number_custom_value = listRangesPrecoded.find(x => x.id_analyte_number_custom_value === id);

        handleUpdateRangePrecoded({
            id_analyte_age_gender_range: analyte_number_custom_value.id_analyte_age_gender_range,
            init_value: analyte_number_custom_value.from,
            end_value: analyte_number_custom_value.to,
            text_range: (analyte_number_custom_value.id_precoded_text > 0 ? analyte_number_custom_value.id_precoded_text : analyte_number_custom_value.text_range)
        });

        setMethodActiveRangePrecoded("edit");
    }

    const removeRangePrecoded = async (id) => {
        let analyteNumberCustomValue = listRangesPrecoded.find(x => x.id_analyte_number_custom_value === id);

        if (analyteNumberCustomValue.id_analyte_number_custom_value > 0) {
            let requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, `Exam/DeleteAnalyteNumberCustomValues/${id}`);
            if (respuesta.code === 200) {
                let newRangePrecoded = listRangesPrecoded.filter(x => x.id_analyte_number_custom_value !== id);
                setListRangesPrecoded(newRangePrecoded);
                createSweet("delete", "success", "Se elimino el valor de referencia de forma correcta");
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, removeRangePrecoded);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
            // else {
            //     validarSesion(history, respuesta.code, removeRangePrecoded);
            //     ////console.log("Error");
            // }
        } else {
            let newRangePrecoded = listRangesPrecoded.filter(x => x.id_analyte_number_custom_value !== id);
            setListRangesPrecoded(newRangePrecoded);
            createSweet("delete", "success", "Se elimino el valor de referencia de forma correcta");
        }
    }

    const saveEditAnalyteRangeNumber = (e) => {        // ////console.log(formRange, "--");
        e.preventDefault();
        // return;
        let newData = listRanges.map(function (obj) {
            if (obj.id_analyte_age_gender_range === analyteNumberRangeId) {

                let days_from_aux = (parseInt(years_from * 365) + parseInt(months_from * 30) + parseInt(days_from));
                let days_to_aux = (parseInt(years_to * 365) + parseInt(months_to * 30) + parseInt(days_to));
                let label_days_from = (years_from > 0 ? `${years_from} año(s) ` : "") + (months_from > 0 ? `${months_from} mes(es) ` : "") + (days_from > 0 ? `${days_from} día(s)` : "");
                let label_days_to = (years_to > 0 ? `${years_to} año(s) ` : "") + (months_to > 0 ? `${months_to} mes(es) ` : "") + (days_to > 0 ? `${days_to} día(s)` : "");

                obj.genero = (parseInt(gender) === 0 ? "Indistinto" : (parseInt(gender) === 1 ? "Femenino" : "Masculino"));
                obj.rango_edad = (days_from === 0 && days_to === 0) ? "N/A" : `${label_days_from} - ${label_days_to}`;
                obj.gender = gender;
                obj.days_from = days_from_aux;
                obj.days_to = days_to_aux;
                obj.id_gender = (parseInt(gender) === 0 ? null : (parseInt(gender)));
              //  obj.id_exam_method = method;
              //  obj.metodo = methods.find(m => m.id_exam_method === parseInt(method)).name_method;
                // ? new
                obj.id_exam_method =method;
                obj.metodo = methods.find(m => m.id_exam_method === parseInt(method)).name_method;
                obj.id_app_code_method =  methods.find(m => m.id_exam_method === parseInt(method)).id_app_code_method;
               
                obj.edited = true;
            }
            return obj;
        });
        console.log("🚀 ~ newData ~ Update:", newData)
        // ////console.log("Estos son los rangos simple");
        // ////console.log(newData);
        resetRange();
        setListRanges(newData);
        backToCreateRange();
    }

    const saveTempAnalytesNumberRanges = () => {
        let newRangosGlobales = null;
        if (analyteId !== 0) {
            let validacion = rangosGlobales.find(rg => rg.id_analyte === analyteId);
            if (validacion !== null && validacion !== undefined) {
                newRangosGlobales = rangosGlobales.map(function (obj) {
                    if (obj.id_analyte === analyteId) {
                        obj.id_analyte = analyteId;
                        obj.rangos = listRanges;
                    }
                    return obj;
                });
            } else {
                newRangosGlobales = rangosGlobales;
                newRangosGlobales.push({
                    id_analyte: analyteId,
                    rangos: listRanges
                });
            }
        } else {
            newRangosGlobales = rangosGlobales;
            let id_aux = 0 - (analytes.length + 1);
            newRangosGlobales.push({
                id_analyte: id_aux,
                rangos: listRanges
            });
        }

        // ////console.log(methodActive);
        if (methodActive === "create") {
            addAnalyte();
        } else {
            editAnalyte();
        }
        setRangosGlobales(newRangosGlobales);
        toggleRange();
        close();
    }

    const saveTempRangePrecoded = () => {
        let newRangePrecoded = null;
        if (analyteId !== 0) {
            let validacion = rangesPrecodedGlobal.find(x => x.id_analyte === analyteId);
            if (validacion !== null && validacion !== undefined) {
                newRangePrecoded = rangesPrecodedGlobal.map(function (obj) {
                    if (obj.id_analyte === analyteId) {
                        obj.id_analyte = analyteId;
                        obj.rangosPrecoded = listRangesPrecoded;
                    }
                    return obj;
                })
            } else {
                newRangePrecoded = rangesPrecodedGlobal;
                newRangePrecoded.push({
                    id_analyte: analyteId,
                    rangosPrecoded: listRangesPrecoded
                });
            }
        }
        setRangesPrecodedGlobal(newRangePrecoded);
        toggleRange();
        close();
    }

    const saveEditRangePrecoded = (e) => {
        e.preventDefault();
        let newData = [];
        listRangesPrecoded.forEach(function (obj) {
            if (obj.id_analyte_number_custom_value === analyteNumberCustomValueId) {
                let objMethod = listRanges.find(x => parseInt(x.id_analyte_age_gender_range) === parseInt(id_analyte_age_gender_range));

                obj.id_analyte_number_custom_value = analyteNumberCustomValueId;
                obj.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                obj.age_gender_range_format = `${objMethod.rango_edad} // ${objMethod.metodo} // ${objMethod.genero}`;
                obj.init_value = init_value;
                obj.end_value = end_value;
                obj.text_range = text_range;
                obj.text_format = (parseInt(id_type_precoded_text) === 0 ? text_range : precodedTexts.find(a => a.id_precoded_text === parseInt(text_range)).text);
                obj.id_precoded_text = (parseInt(id_type_precoded_text) === 0 ? null : (precodedTexts.length > 0 ? parseInt(text_range) : null));
                obj.from = init_value;
                obj.to = end_value;
            }
            newData.push(obj);
        });
        resetRangePrecoded();
        setListRangesPrecoded(newData);
        backToCreateRangePrecoded();
    }

    // listReferenceValues
    // referenceValuesGlobal
    // analyteNumberAlertId
    // methodActiveReferenceValues

    // VALORES DE REFERENCIA METODOS
    const saveTempAnalytesNumberAlerts = (valoresReferencia) => {
        setListReferenceValues(valoresReferencia);
        let newReferenceValuesGlobal = null;
        if (analyteId !== 0) {
            let validacion = referenceValuesGlobal.find(rg => rg.id_analyte === analyteId);
            if (validacion !== null && validacion !== undefined) {
                newReferenceValuesGlobal = referenceValuesGlobal.map(function (obj) {
                    if (obj.id_analyte === analyteId) {
                        obj.id_analyte = analyteId;
                        obj.analyte_number_alerts = valoresReferencia;
                    }
                    return obj;
                });
            } else {
                newReferenceValuesGlobal = referenceValuesGlobal;
                newReferenceValuesGlobal.push({
                    id_analyte: analyteId,
                    analyte_number_alerts: valoresReferencia
                });
            }
        } else {
            newReferenceValuesGlobal = referenceValuesGlobal;
            let id_aux = 0 - (analytes.length + 1);
            newReferenceValuesGlobal.push({
                id_analyte: id_aux,
                analyte_number_alerts: valoresReferencia
            });
        }
        if (methodActive === "create") {
            addAnalyte();
        } else {
            editAnalyte();
        }
        setReferenceValuesGlobal(newReferenceValuesGlobal);
        toggleRange();
        close();
    }



    const getAnalyteNumberAlerts = async () => {
        let analyteNumberAlerts = referenceValuesGlobal.find(x => x.id_analyte === analyteId);
        if (analyteNumberAlerts === undefined || analyteNumberAlerts === null) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteNumberAlerts/" + analyteId);
            if (respuesta.code === 200) {
                // let newListRangesPrecoded = [];
                let newListReferenceValues = [];
                respuesta.data.forEach(function (obj) {

                    let df = obj.days_from;
                    let dt = obj.days_to;
                    let yf = 0;
                    let yt = 0;
                    let mf = 0;
                    let mt = 0;

                    yf = parseInt(obj.days_from / 365);
                    df = df - (yf * 365);
                    mf = parseInt(df / 30);
                    df = df - (mf * 30);

                    yt = parseInt(obj.days_to / 365);
                    dt = dt - (yt * 365);
                    mt = parseInt(dt / 30);
                    dt = dt - (mt * 30);

                    let label_days_from = (yf > 0 ? `${yf} año(s) ` : "") + (mf > 0 ? `${mf} mes(es) ` : "") + (df > 0 ? `${df} día(s)` : "");
                    let label_days_to = (yt > 0 ? `${yt} año(s) ` : "") + (mt > 0 ? `${mt} mes(es) ` : "") + (dt > 0 ? `${dt} día(s)` : "");

                    newListReferenceValues.push({
                        id_analyte_number_alert: obj.id_analyte_number_alert,
                        id_analyte_age_gender_range: obj.id_analyte_age_gender_range,
                        name_range: label_days_from + "-" + label_days_to + " // " + obj.age_gender_range_format,
                        low_alert: obj.low_alert,
                        low: obj.low,
                        normal_min: obj.normal_min,
                        normal_max: obj.normal_max,
                        high: obj.high,
                        high_alert: obj.high_alert,
                    });
                });
                setListReferenceValues(newListReferenceValues);
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, getAnalyteNumberAlerts);
            }
            else if (respuesta.code === 500) {

                createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
            }
            else {

                createSweet("error", "warning", "Examen simple", respuesta.data.msg);
            }
            // else {
            //     validarSesion(history, respuesta.code, getAnalyteNumberAlerts);
            // }
        }
    }

    // VALORES DE REFERENCIA METODOS


    // TEXTOS DE REFERENCIA METODOS
    const saveTempAnalytesTextAlerts = (textosReferencia) => {
        setListReferenceTexts(textosReferencia);
        let newReferenceTextsGlobal = null;
        if (analyteId !== 0) {
            let validacion = referenceTextsGlobal.find(rg => rg.id_analyte === analyteId);
            if (validacion !== null && validacion !== undefined) {
                newReferenceTextsGlobal = referenceTextsGlobal.map(function (obj) {
                    if (obj.id_analyte === analyteId) {
                        obj.id_analyte = analyteId;
                        obj.analyte_text_alerts = textosReferencia;
                    }
                    return obj;
                });
            } else {
                newReferenceTextsGlobal = referenceTextsGlobal;
                newReferenceTextsGlobal.push({
                    id_analyte: analyteId,
                    analyte_text_alerts: textosReferencia
                });
            }
        } else {
            newReferenceTextsGlobal = referenceTextsGlobal;
            let id_aux = 0 - (analytes.length + 1);
            newReferenceTextsGlobal.push({
                id_analyte: id_aux,
                analyte_text_alerts: textosReferencia
            });
        }

        if (methodActive === "create") {
            addAnalyte();
        } else {
            editAnalyte();
        }
        setReferenceTextsGlobal(newReferenceTextsGlobal);
        toggleRange();
        close();
    }

    const getAnalyteTextAlerts = async () => {
        let analyteTextAlerts = referenceTextsGlobal.find(x => x.id_analyte === analyteId);

        // if (analyteTextAlerts === undefined || analyteTextAlerts === null) {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteTextAlerts/" + analyteId);
        if (respuesta.code === 200) {
            let newListReferenceTexts = [];
            respuesta.data.forEach(function (obj) {
                let df = obj.days_from;
                let dt = obj.days_to;
                let yf = 0;
                let yt = 0;
                let mf = 0;
                let mt = 0;

                yf = parseInt(obj.days_from / 365);
                df = df - (yf * 365);
                mf = parseInt(df / 30);
                df = df - (mf * 30);

                yt = parseInt(obj.days_to / 365);
                dt = dt - (yt * 365);
                mt = parseInt(dt / 30);
                dt = dt - (mt * 30);

                let label_days_from = (yf > 0 ? `${yf} año(s) ` : "") + (mf > 0 ? `${mf} mes(es) ` : "") + (df > 0 ? `${df} día(s)` : "");
                let label_days_to = (yt > 0 ? `${yt} año(s) ` : "") + (mt > 0 ? `${mt} mes(es) ` : "") + (dt > 0 ? `${dt} día(s)` : "");

                newListReferenceTexts.push({
                    id_analyte_text_alert: obj.id_analyte_text_alert,
                    id_analyte_age_gender_range: obj.id_analyte_age_gender_range,
                    name_range: label_days_from + "-" + label_days_to + " // " + obj.age_gender_range_format,
                    text: obj.text
                });
            });
            ////console.log("esyta es la respuesta que tenemos");
            ////console.log(newListReferenceTexts);
            setListReferenceTexts(newListReferenceTexts);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAnalyteTextAlerts);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // }
    }

    // TEXTOS DE REFERENCIA METODOS

    const handleModalOrder = () => {
        toggleOrder();
    }

    const createAnalytesGroups = (array_aux) => {
        let analytesGroupAux = [];
        array_aux.map(function (obj) {
            let validacion = analytesGroupAux.find(ag => ag.id === `grupo-${obj.id_analyte_group}`);
            if (validacion === undefined) {

                let subElementos = [];
                let grupos = array_aux.filter(a => a.id_analyte_group === obj.id_analyte_group);
                grupos.map(function (elemento) {
                    subElementos.push({
                        id: `analito-${elemento.id_analyte}`,
                        content: elemento.name
                    });
                });

                let element = {
                    id: `grupo-${obj.id_analyte_group}`,
                    content: (obj.name_analyte_group === null ? "Sin grupo" : obj.name_analyte_group),
                    subItems: [
                        ...subElementos
                    ]
                }
                analytesGroupAux.push(element);
            }
        });
        setAnalytesGroup(analytesGroupAux);
    }

    // OrderAnalytes
    const saveOrderAnalytes = async (analytesGroup) => {
        setLoadingOrder(true);
        let raw = JSON.stringify(analytesGroup);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/OrderAnalytes");

        if (respuesta.code === 200) {
            getAnalytesFromExam();
            setLoadingOrder(false);
            handleModalOrder();

            createSweet("create", "success", "Se actualizo el orden de los analitos");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveOrderAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     ////console.log("Error al crear los analitos...");
        //     validarSesion(history, respuesta.code, saveOrderAnalytes);
        // }
    }

    // ORDER PRINT ANALYTES

    const handleModalOrderPrint = () => {
        toggleOrderPrint();
    }

    const createAnalytesGroupsPrint = (array_aux) => {
        let analytesGroupAux = [];
        array_aux = array_aux.filter(x => x.print !== false);
        array_aux.sort((a, b) => (a.position_print > b.position_print) ? 1 : ((b.position_print > a.position_print) ? -1 : 0));
        array_aux.map(function (obj) {
            let validacion = analytesGroupAux.find(ag => ag.id === `grupo-${obj.id_analyte_group}`);
            if (validacion === undefined) {

                let subElementos = [];
                let grupos = array_aux.filter(a => a.id_analyte_group === obj.id_analyte_group);
                grupos.map(function (elemento) {
                    if (elemento.print) {
                        subElementos.push({
                            id: `analito-${elemento.id_analyte}`,
                            content: elemento.name
                        });
                    }
                });

                let element = {
                    id: `grupo-${obj.id_analyte_group}`,
                    content: (obj.name_analyte_group === null ? "Sin grupo" : obj.name_analyte_group),
                    subItems: [
                        ...subElementos
                    ]
                }
                analytesGroupAux.push(element);
            }
        });
        setAnalytesGroupPrint(analytesGroupAux);
    }


    const saveOrderPrintAnalytes = async (analytesGroupPrintAux) => {
        setLoadingOrderPrint(true);
        let raw = JSON.stringify(analytesGroupPrintAux);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/OrderAnalytesPrint");

        if (respuesta.code === 200) {
            getAnalytesFromExam();
            setLoadingOrderPrint(false);
            handleModalOrderPrint();

            createSweet("create", "success", "Se actualizo el orden de impresión de los analitos");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveOrderPrintAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveOrderPrintAnalytes);
        // }
    }

    // FORMULAR COMPLEJAS
    const getAnalytesAvailableComplex = () => {
        let analtyes_available = [];
        let analyteIdAux = analyteId;
        ////console.log(analytes, "----");
        analytes.filter(function (obj) {
            let ita = parseInt(obj.id_type_analyte);
            if (ita === 2 || ita === 3 || ita === 4 || ita === 5 || ita === 7) {
                if (obj.id_analyte !== analyteIdAux) {
                    let newObj = {
                        id_analyte: obj.id_analyte,
                        name_code: obj.name + " [" + obj.code + "]",
                        name: obj.name,
                        code: obj.code
                    }
                    analtyes_available.push(newObj);
                    return true;
                }
            }
        });
        setAnalytesAvailables(analtyes_available);
    }

    const refreshFormComplex = async (formComplex, formula) => {
        let complexValueAnalytes = [];
        Object.keys(formComplex).map(function (key) {
            complexValueAnalytes.push({
                "id_analyte": key,
                "variable": formComplex[key].variable,
                "valor": formComplex[key].valor
            })
        });
        let data = {
            "formula": formula,
            "complexValueAnalytes": [...complexValueAnalytes]
        }
        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Exam/ValidarFormulaComplex");

        if (respuesta.code === 200) {
            setResponseComplexText(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, refreshFormComplex);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, refreshFormComplex);
        // }
    }

    const preSavedFormComplex = (formComplex, formula) => {
        setFormComplex(formComplex);
        handleUpdateValues({
            ...formValues,
            compute: formula,
        });
        close();
    }

    const refreshResultComplex = () => {
        setResponseComplexText({});
    }

    const getAnalytesComplex = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Exam/GetAnalyteComplex/" + analyteId);
        if (respuesta.code === 200) {
            setAnalytesComplex(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getAnalytesComplex);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen simple", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen simple", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getAnalytesComplex);
        // }
    }

    const [selected, setSelected] = useState([]);


    const onChange = (selected) => {
        setSelected(selected)
    };


    const handleChangeTextP = (e) => {
        handleInputChange(e);

        let mValue = e.target.value;

        getListPrecoded(mValue);
        setSelected([]);

        // getPrecodedtexts()
    }


    const [data, setData] = useState([]);


    const getListPrecoded = async (id_type_precoded_text) => {

        if (id_type_precoded_text === undefined) {
            return;
        }

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `PrecodedTexts/ListOrderPrecodedText/${id_type_precoded_text}`);

        if (respuesta.code === 200) {

            let array = []

            respuesta.data.forEach(element => {

                array.push({
                    "value": element.id_precoded_text,//ID
                    "label": element.title,
                    "id": element.id_precoded_text,//ID PRECODTEXT
                    "idTypePrecodText": id_type_precoded_text,
                    "id_precoded_text": element.id_precoded_text,
                    "id_type_precoded_text": id_type_precoded_text
                })
            });
            ////console.log(array, "data primero");
            setData(array);
        }
        else {
            validarSesion(history, respuesta.code, getListPrecoded);
        }
    }

    const getPrecodedtexts = async (id_analyte) => {

        let requestOptions = {
            method: 'GET',
        };
        const respuesta = await sendRequest(requestOptions, `PrecodedTexts/ListPrecodedTextFromAnalyte/${id_analyte}`);

        if (respuesta.code === 200) {
            let array = []

            respuesta.data.forEach(element => {

                array.push({
                    "value": element.id_precoded_text,//ID
                    "label": element.text,
                    "id": element.id_precoded_text,//ID PRECODTEXT,
                    "id_precoded_text": element.id_precoded_text,
                    "id_type_precoded_text": element.id_type_precoded_text
                })
            });
            // ////console.log(id_analyte,"sdkasn");
            //////console.log(array, "segundo valor que trae");
            setSelected([...array]);
        }
    }

    const validarFormRange = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        console.log("🚀 ~ validarFormRange ~ newValidaciones:", newValidaciones)

        // if (id_type_precoded_text > 0) {
        //     if (text_range > 0) {
        //         newValidaciones = {
        //             ...newValidaciones,
        //             text_range: false
        //         }
        //     } else {
        //         newValidaciones = {
        //             ...newValidaciones,
        //             text_range: true
        //         }
        //         statusValidacion = true;
        //     }
        // } else {
        //     if (text_range.length > 0) {
        //         newValidaciones = {
        //             ...newValidaciones,
        //             text_range: false
        //         }
        //     } else {
        //         newValidaciones = {
        //             ...newValidaciones,
        //             text_range: true
        //         }
        //         statusValidacion = true;
        //     }
        // }

        // if (parseFloat(init_value) >= parseFloat(end_value)) {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         range: true
        //     }
        //     statusValidacion = true;
        // } else {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         range: false
        //     }
        // }
        console.log("🚀 ~ validarFormRange ~ method:", method)

        let [id_exam_methodd, id_app_code_methodd] = method.split('-');

        console.log(id_exam_methodd); 
        console.log(id_app_code_methodd); 

        let _valid_id_exam_method = (id_app_code_methodd === "" || id_app_code_methodd === null) ?  methods.find(m => m.id_exam_method === parseInt(method)).id_app_code_method :  methods.find(m => m.id_app_code_method === parseInt(id_app_code_methodd)).id_app_code_method
        console.log("🚀 ~ validarFormRange ~ _valid_id_exam_method:", _valid_id_exam_method)



        if (parseInt(_valid_id_exam_method) <= 0) {
            console.log("a");
            
            newValidaciones = {
                ...newValidaciones,
                method: true
            }
            statusValidacion = true;
        } else {
            console.log("b");
            
            newValidaciones = {
                ...newValidaciones,
                method: false
            }
        }

        let validacionValores = false;

        let new_df = parseInt(days_from);
        let new_dt = parseInt(days_to);
        let new_mf = parseInt(months_from);
        let new_mt = parseInt(months_to);
        let new_yf = parseInt(years_from);
        let new_yt = parseInt(years_to);

        let from_total = (new_yf * 365) + (new_mf * 30) + new_df;
        let to_total = (new_yt * 365) + (new_mt * 30) + new_dt;

        listRanges.forEach(function (obj) {
            console.log("🚀 ~ obj:", obj)
            console.log("pasassssss");

            let _valid_obj = (id_app_code_methodd === "" || id_app_code_methodd === null) ?  obj.id_exam_method : obj.id_app_code_method
            console.log("🚀 ~ _valid_obj:", _valid_obj)

            
            if (parseInt(gender) === parseInt(obj.gender) && parseInt(_valid_id_exam_method) === parseInt(obj.id_exam_method)) {

                ////console.log(obj);
                ////console.log(from_total);
                ////console.log(obj.days_from);
                ////console.log(obj.days_to);
                console.groupEnd();
                if (from_total >= obj.days_from && from_total < obj.days_to) {
                    validacionValores = true;
                } else if (to_total > obj.days_from && to_total <= obj.days_to) {
                    validacionValores = true;
                }
            }
        });

        if (validacionValores) {
            newValidaciones = {
                ...newValidaciones,
                overhead: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                overhead: false
            }
        }

        setValidacionesRange({
            ...validacionesRange,
            ...newValidaciones
        });

        return statusValidacion;
    }

    const handleValueBold = () => {
        setValueBold(!valueBold);
    }

    const handleValueItalics = () => {
        setValueItalics(!valueItalics);
    }

    const handleValueUnderline = () => {
        setValueUnderline(!valueUnderline);
    }

    const handleValueFormatTransform = (type) => {
        setValueFormatTransform(type);
    }

    // ? CONFIGURATION METHODS
    const [modalMethods, setModalMethods] = useState(false);
    const [listNewMethod, setListNewMethod] = useState([]);
    const [formMethod, setFormMethod] = useState({
        id_method: null,
        id_app_code_method: null

    })

    const [methods1, setMethods1] = useState([]);


    const toggleMethod = () => 
    {
        setModalMethods(!modalMethods)
        setFormMethod({
            ...formMethod,
            id_method: null,
            id_app_code_method: null

        });     
    }

    const handleNewMethods = () => 
    {
        setModalMethods(!modalMethods)
        getExamMethods()
    }

       //* ALL METHODS
    const getExamMethods = async () =>
    {
        const method = await getAllExamMethods(examId);
    
        if (method?.length > 0)
        {
            const list = method?.map(_find => {
                return {
                    value: _find?.id_method,
                    label: _find?.name,
                    id_app_code_method: _find?.id_app_code_method 
                }
            });
            setListNewMethod(list)
        }
    }

    const handleChangeMethod = async (e) =>
    {
        if (e !== null && e?.value !== undefined) {
            setFormMethod({
                ...formMethod,
                id_method: {"label": e?.label,"value":e?.value} ,
                id_app_code_method: e?.id_app_code_method
            });            
        }
    }

    const saveMethod = async () => 
    {
        const response = await saveMethodExam( examId,formMethod?.id_method.value,formMethod?.id_app_code_method);

        if (response.code === 200) {
            createSweet("success", "success", "Se genero correctamente el metodo");
            window.location.reload()
            setFormMethod({
                ...formMethod,
                id_method: null,
                id_app_code_method: null

            });  
        }    
    }

   
    






    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        if (formValues.code.length < 1) {
            newValidaciones = {
                ...newValidaciones,
                code: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                code: false
            }
        }

        if (formValues.id_analyte_group === "0") {
            if (formValues.name_analyte_group.length < 3) {
                newValidaciones = {
                    ...newValidaciones,
                    name_analyte_group: true
                }
                statusValidacion = true;
            } else {
                newValidaciones = {
                    ...newValidaciones,
                    name_analyte_group: false
                }
            }
        }

        if (formValues.id_info_analyte === 0) {
            newValidaciones = {
                ...newValidaciones,
                name_analyte_group: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_analyte_group: false
            }
        }

        if (formValues.id_type_analyte === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_type_analyte: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                id_type_analyte: false
            }
            if (formValues.id_type_analyte === "1") {
                if (formValues.id_type_precoded_text <= 0) {
                    newValidaciones = {
                        ...newValidaciones,
                        id_type_precoded_text: true
                    }
                    statusValidacion = true;
                } else {
                    newValidaciones = {
                        ...newValidaciones,
                        id_type_precoded_text: false
                    }
                }
            }
            else if (formValues.id_type_analyte === "3") {
                if (parseInt(formValues.integers) <= 0) {
                    newValidaciones = {
                        ...newValidaciones,
                        integers: true
                    }
                    statusValidacion = true;
                } else {
                    newValidaciones = {
                        ...newValidaciones,
                        integers: false
                    }
                }
                if (parseInt(formValues.decimals) <= 0) {
                    newValidaciones = {
                        ...newValidaciones,
                        decimals: true
                    }
                    statusValidacion = true;
                } else {
                    newValidaciones = {
                        ...newValidaciones,
                        decimals: false
                    }
                }
            } else {
                newValidaciones = {
                    ...newValidaciones,
                    id_type_precoded_text: false
                }
            }

            if (formValues.id_type_analyte === "2" || formValues.id_type_analyte === "3") {
                if (formValues.check_compute) {
                    if (formValues.compute.length <= 2) {
                        newValidaciones = {
                            ...newValidaciones,
                            compute: true
                        }
                        statusValidacion = true;
                    } else {
                        newValidaciones = {
                            ...newValidaciones,
                            compute: false
                        }
                    }
                    // validateFormula.validacion
                    if (!validateFormula.validacion) {
                        statusValidacion = true;
                    }
                } else {
                    newValidaciones = {
                        ...newValidaciones,
                        compute: false
                    }
                }
            } else {
                newValidaciones = {
                    ...newValidaciones,
                    compute: false
                }
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;

    }

    return {
        analytes, loadingAnalytes, exam, analytesGroup, analytesGroupPrint, groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo,
        isOpen, toggle, loading, modal, toggleModal, nameAnalyte, loadingModal,
        handleInputChange, validaciones, methodActive, validateFormula,
        name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, check_compute, replace, id_exam, loinc_num,
        addAnalyte, removeAnalyte, saveAnalytes, deleteAnalyte, handleEdit, editAnalyte, backCreate, changeUnit, validarFormula, resetValidarFormula,
        toggleTest, modalTest, loadingTest, testearFormula,
        init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults, handleModalTest,
        handleFullModal, modalProps, modalFullRef,
        gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to, text_range, validacionesRange, method, methods,
        handleInputRange, resetRange, precodedTexts, listRanges, addAnalyteNumberRange, removeAnalyteNumberRange, saveTempAnalytesNumberRanges, editAnalyteNumberRange,
        id_analyte_age_gender_range, handleInputRangePrecoded,
        listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded, removeRangePrecoded, editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded,
        saveEditAnalyteRangeNumber, methodActiveRange, backToCreateRange, loincState,
        handleModalOrder, modalOrder, loadingOrder, saveOrderAnalytes,
        handleModalOrderPrint, modalOrderPrint, loadingOrderPrint, saveOrderPrintAnalytes,
        activeModal, analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex, analytesComplex, exams,
        saveTempAnalytesNumberAlerts, listReferenceValues,
        handleValueBold, handleValueItalics, handleValueUnderline, handleValueFormatTransform, valueBold, valueItalics, valueUnderline, valueFormatTransform,
        selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
        saveTempAnalytesTextAlerts, listReferenceTexts,
        //list
        onChange, data, handleChangeTextP, selected, data,

        // ? CONFIGURATION METHODS
        modalMethods,toggleMethod,handleNewMethods,listNewMethod,handleChangeMethod,formMethod,saveMethod,methods1,
        

    };
}
