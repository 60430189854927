import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Label, FormGroup, Row, Input, Button, Collapse } from 'reactstrap';

import { usePowerBi } from '../../hooks/Pbi/usePowerBi';

export const IndexSurvey = () => {

  
      const {
          dataLink
      } = usePowerBi();

      const _order0 = dataLink.find(item => item.order === 0)?.link;
      console.log("🚀 ~ IndexSurvey ~ _order0:", _order0)

  
  
      return (
           <Container fluid={true} >
                {
                    (_order0 === undefined || _order0 === '' ) ?
                    <Card className='shadow' >
                        <div style={{ textAlign: "-webkit-center" }} >
                        <img src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                        <label className='f-w-600 f-14 badge badge-light-primary'>No se ha Cargado algún reporte que mostrar</label>
                        </div>
                    </Card>
                    :
                        <div className='embed-container'>
                        <iframe
                            id="inlineFrameExample"
                            src={dataLink.length > 0 && _order0}
                            frameborder="0"
                        >
                        </iframe>
                        </div>
                        }
                </Container>
  
      )
  }