import { useState, useEffect } from "react"
import { HubConnectionBuilder } from '@microsoft/signalr';

export const useSignalR = ({ url = "/hub/notification", funcionRespuesta }) => {
    const [conexion, setConexion] = useState(null);

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect()
            .build();

        setConexion(connection);

        // Detener la conexión al desmontar el componente
        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [url]);

    useEffect(() => {
        if (conexion) {
            // Iniciar la conexión solo si está en estado 'Disconnected'
            if (conexion.state === 'Disconnected') {
                conexion.start()
                    .then(() => {
                        console.log("Conexión a SignalR establecida");
                        conexion.on("message", (message) => {
                            funcionRespuesta(message);
                        });
                    })
                    .catch((e) => console.error("Fallo en la conexión: ", e));
            }
        }
    }, [conexion, funcionRespuesta]);

    return { conexion };
};