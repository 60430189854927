import React from 'react';
import { Button, Card, CardBody, Input, Col, Row } from 'reactstrap';
import { ModalDoctor } from '../../components/doctors/ModalDoctor';
import { TableStriped } from '../../components/tables/TableStriped';
import { useDoctors } from '../../hooks/doctors/useDoctors';
import { TabDoctor } from '../../constant';

export const IndexDoctors = ({ activeTab }) => {

    const {

        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, dataDoctor, handleSearch,
        handleUpdate, handleDelete, handleCreate, saveCreate, saveDelete, handleInputChange, id_specialty, name, professional_license, phone, address, email, code,
        validaciones, nameInputs, saveUpdate,
        specialties, toggle, loading, method, modal, name_specialty, isCommercialPartner
    } = useDoctors({ activeTab });


    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        {TabDoctor}
                    </div>
                    <br />
                    <Col sm="12" style={{ textAlign: "end" }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>

                    </Col>
                    <Col sm="12"> <br /> <br />
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="8" xl="8">
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="4" xl="4">

                                <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                            </Col>
                        </Row>
                    </Col>
                    <br />
                    <Col sm="12">
                        <div className="table-responsive">
                            <TableStriped
                                notMaped={["visibleTable", "email", "phone", "professional_license", "id_specialty", "address", "isCommercialPartner", "code"]}
                                methodsActions={true}
                                cabeceras={["Nombre", "Especialidad"]}
                                items={dataDoctor}
                                {...{ handleUpdate, handleDelete }}
                                nextPage={goNextPage}
                                previousPage={goPreviousPage}
                                totalPageCount={totalPages}
                                currentPage={actualPage}
                                goToPage={goToPage}
                                paginingBack={true}
                            />
                        </div>
                    </Col>
                </CardBody>
            </Card>
            <ModalDoctor
                {
                ...{
                    method,
                    handleInputChange, validaciones, nameInputs,
                    specialties, id_specialty, name, professional_license,
                    phone, address, email,code,
                    toggle, modal, loading,
                    saveCreate, saveUpdate, saveDelete, name_specialty, isCommercialPartner,
                }
                }
            />
        </>
    )
}
