import { useState, useEffect } from "react"
import { useHistory } from "react-router";
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';
import useSound from 'use-sound';
import soundAlert from '../../assets/audio/Campana.mp3';


export const useNotification = () => {


    const history = useHistory();

    const [notifications, setNotifications] = useState({});
    const [notificationInvoices, setNotificationInvoices] = useState({})

    const [createSweet] = useSweetAlert();

    const [play] = useSound(soundAlert);


    useEffect(() => {
       getNotification();
       getNotificationInvoices()
  //  play();
    }, [history])


    useEffect(() => {
       // play();
    }, [notifications])

    //notifications
    const getNotification = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Notification/GetNotifications");

        if (respuesta.code === 200) {
            
            if (respuesta.data.id !== undefined) {

                setNotifications(respuesta.data);
            }
        }
        else {
            // createSweet("error", "error", "Hubo un error!", "Error de conexion");
            validarSesion(history, respuesta.code, getNotification);
        }
    }

    const getNotificationInvoices = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "ProgrammingSerie/GetNotifications");


        if (respuesta.code === 200) {

            if (respuesta.data.id !== undefined) {

                setNotificationInvoices(respuesta.data);
            }
        }
        else {
            // createSweet("error", "error", "Hubo un error!", "Error de conexion");
            validarSesion(history, respuesta.code, getNotification);
        }
    }


    const deleteNotification = async (id) => {

        let requestOptions = {
            method: 'POST'
        };

        const respuesta = await sendRequest(requestOptions, 'Notification/CheckNotification/' + id);

        if (respuesta.code === 200) {

            getNotification();

        }
        else {
            //  createSweet("error", "error", "Hubo un error!");
        }
    }

    const updateMessage = (message) => {
        if (message === "refresh") {
          getNotification(); 
          getNotificationInvoices()
        }
      }


      const navigateUpdate = (workOrderId, id) => {
        let value = "" + workOrderId + "";
    
        window.location.replace(`/admisiones/${value}/editar/${id}`);
    }

    return {
        notifications,
        deleteNotification,
        updateMessage,
        play,navigateUpdate,
        notificationInvoices
    }
}
