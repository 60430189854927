import React, { Fragment } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Container, FormFeedback, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import { ButtonGoBack, CompleteEmail, CompleteInput, CompleteSelect, InstructionAgreementBranch, PlaceHolderOption, SelectExamOrProfile } from '../../constant';
import { useAddAgreement } from '../../hooks/agreements/useAddAgreement';
import { SearchAsync } from '../../components/elements/SearchAsync';
import DeniReactTreeView from 'deni-react-treeview';
import Select from 'react-select';
import { TableAgreement } from '../../components/agreement/TableAgreement';
import 'animate.css';
import { InformationAgreement } from './InformationAgreement';
import { ModalCodeCsv } from './ModalCodeCsv';
import { ModalDeleteTest } from './ModalDeleteTest';

export const IndexAddAgreement = () => {

    const {
        //#region AGREEMENTS LIST
        listCompanies, listPayForms, listFinancingMethods, listUseCfdi, listSellers, listCommercialLines,
        ListBranchesTree, listDoctors,
        //#endregion

        //#region AGREEMENTS MODELS
        informationCompany, informationDoctor, examProfileState, loading, globalPercentage, loadingAgreement, typeFilterAgreement,
        pageSizeSelectDoctor,

        //FORM
        id_agreement, id_company, id_doctor, id_pay_method, id_financing_methods, id_commercial_line, id_seller, id_use_cfdi,
        contract_number, validity, sales_name, sales_phone, sales_email, payment_name, payment_phone,
        payment_email, observations, swift_code, is_global, payment_periodicity, credit_limit, percentage_credit_limit,
        checkSpecimenQuantity, quantity_sample, list_agrement_test, is_pay_for_compamy, required_invoice_company,

        //FORM METHODS
        handleInputChangeA, handleSelectValuesA,
        //REFS
        treeViewRef,
        //#endregion

        //#region VALIDATIONS AGREEMENTS
        validationsAgreement, visibilityInputs,
        //#endregion  

        //#region AGREEMENTS METHODS
        handleChangeCompany, handleChangeDoctor, handleSelectTest, handleChangeRange, handleChangePercentage,
        handleDeleteRange, handleAddRange, handleDeleteTest, handleChangePrice, handleGoBack,
        saveOrUpdateAgreement, handleCalculateGlobalPercentage, handleChangeTypeFilter, handleScrollPaginingDoctor,

        //#endregion
        number_format,

        //#region csv Exam and Profile
        setDatacsv, handleCsvLoading,
        //#region sanitaria
        check_sanitary,on_check_sanitary,handleCant,sample_periodicity,is_micro,

         //RETURN PAGINATION
         goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
         prevPage, nextPage, totalRecords, resultCount, handleSearch,
         validCode,
         modalListEgress,toggleModalListEgress,listCode,handlemove,
         listRepit,priceTarifarion,profileT,

        //* MODAL DELETE TEST
        modalCancellation,method,toggleModalCancellation,handleTest,modalTitle,informationExams,handleDeleteTestCreate,
         //*CSV
         file,handleOnChange,ExamIndication,view_exams,
         charge_emergency,border_tax,address_sample_taking,_active_micro

    } = useAddAgreement();
    const {
        id_company_valid,
        id_doctor_valid,
        id_pay_method_valid,
        id_financing_methods_valid,
        id_commercial_line_valid,
        id_use_cfdi_valid,
        id_seller_valid,
        contract_number_valid,
        validity_valid,
        sales_name_valid,
        sales_phone_valid,
        sales_email_valid,
        payment_name_valid,
        payment_phone_valid,
        payment_email_valid,
        payment_periodicity_valid,
        credit_limit_valid,
        percentage_credit_limit_valid,
        list_agrement_test_valid,
        quantity_sample_validity,
        branch_tree_valid
    } = validationsAgreement;

    //console.log(id_agreement);



    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12" lg="12" hidden={!loadingAgreement}>
                        <Col sm="12" style={{ textAlign: "center" }} >
                            <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'150px' }} />                            
                            <br /> <p className='f-w-600 f-14 badge badge-light-primary' >Cargando información...</p>
                        </Col>
                    </Col>
                    <Col sm="12" md="12" lg="12" hidden={loadingAgreement}>
                    <Row>
                        <Col sm="12" md="4" lg="4">
                        <div className="email-left-aside">
                            <Card>
                                <CardBody className='pt-3 pl-4 pr-3 pb-1' >
                                    <div className="email-app-sidebar left-bookmark">
                                    <Col sm="12" md="12" lg="12" className='pb-3 p-l-0 p-r-0' style={{textAlign:"end"}} >
                                        <ButtonGroup size='sm' className=''>
                                            <Button disabled={id_agreement > 0 ? true : false} color='primary' outline={typeFilterAgreement === 1 ? false : true} onClick={() => handleChangeTypeFilter(1)}><i className="fa fa-building-o"></i> Convenio para empresas</Button>
                                            <Button disabled={id_agreement > 0 ? true : false} color='primary' outline={typeFilterAgreement === 2 ? false : true} onClick={() => handleChangeTypeFilter(2)}> <i className="fa fa-user-md"></i> Convenio para doctores</Button>
                                        </ButtonGroup>
                                    </Col>
                                        <div className="media">
                                            <div className="media-size-email">
                                                <img className="mr-3 rounded-circle" src={require("../../assets/images/user/user.png")} alt="" />
                                            </div>
                                            <div className="media-body">
                                                <h5 className="f-w-600">{"Cliente"}</h5>
                                                {typeFilterAgreement === 1 && informationCompany?.company_turn !== undefined &&<label className='txt-success f-w-600'>{informationCompany?.company_turn}</label>}
                                            </div>
                                        </div>
                                        <Col sm="12" className='p-l-0 p-r-0' >
                                        <Row>
                                            <Col sm="6">
                                            {typeFilterAgreement === 1 && informationCompany?.phone !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp;  Telefono:</label><br /> {informationCompany?.phone}</label>}
                                            {typeFilterAgreement === 2 && informationDoctor?.specialty !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Epecialidad:</label><br /> {informationDoctor?.specialty ?? "N/A"}</label>}
                                            </Col>
                                            <Col sm="6">
                                            {typeFilterAgreement === 1 && informationCompany?.rfc !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; RFC:</label> <br />{informationCompany?.rfc}</label>} 
                                            {typeFilterAgreement === 2 && informationDoctor?.specialty !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Dirección:</label><br /> {informationDoctor?.address ?? "N/A"}</label>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                            {typeFilterAgreement === 1 && informationCompany?.payment_periodicity_value !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Periodo de credito:</label> <br /> {informationCompany?.payment_periodicity_value}</label>} 
                                            {typeFilterAgreement === 2 && informationDoctor?.specialty !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Telefono:</label><br /> {informationDoctor?.phone ?? "N/A"}</label>}
                                            </Col>
                                            <Col sm="6">
                                            {typeFilterAgreement === 1 && informationCompany?.is_pay_for_compamy_value !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Generar cobro a : </label><br />  {informationCompany?.is_pay_for_compamy_value}</label>}
                                            {typeFilterAgreement === 2 && informationDoctor?.specialty !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Correo:</label><br /> {informationDoctor?.email ?? "N/A"}</label>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6" >
                                            {typeFilterAgreement === 1 && informationCompany?.required_invoice_company !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Requiere factura: </label><br />  {informationCompany?.required_invoice_company}</label>} <br />
                                            {typeFilterAgreement === 2 && informationDoctor?.specialty !== undefined &&<label className='' ><label className='txt-info f-w-600'><i className='fa fa-circle text-info'></i>&nbsp; Licencia profecional:</label><br /> {informationDoctor?.professional_license ?? "N/A"}</label>}                                            
                                            </Col>
                                            <Col sm="6" className='pt-3' style={{textAlign:"end"}} >
                                            <Button outline color='dark' size='xs' onClick={ handleGoBack}  > <i className='fa fa-reply'></i> Regresar</Button>
                                            </Col>
                                        </Row>
                                        </Col>
                                        <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-1' >
                                            {
                                                typeFilterAgreement === 1 && <FormGroup>
                                                    <Label className='f-w-600' >Filtrar por cliente</Label>
                                                    <Select
                                                        name="id_company"
                                                        isClearable={true}
                                                        placeholder="Selecciona una opción"
                                                        options={listCompanies}
                                                        defaultValue={id_company}
                                                        value={id_company}
                                                        className='input-air-primary b-r-9'
                                                        onChange={(e) => handleChangeCompany(e)}
                                                        isDisabled={id_agreement > 0 ? true : false}
                                                    />
                                                    <FormText color='danger' hidden={!id_company_valid}>Selecciona un cliente</FormText>
                                                </FormGroup>
                                            }
                                            {
                                                typeFilterAgreement === 2 && <FormGroup>
                                                    <Label className='f-w-600' >Filtrar por doctores</Label>
                                                    <Select
                                                        name="id_company"
                                                        isClearable={true}
                                                        placeholder="Selecciona una opción"
                                                        options={listDoctors}
                                                        defaultValue={id_doctor}
                                                        value={id_doctor}
                                                        className='input-air-primary b-r-9'
                                                        onChange={(e) => handleChangeDoctor(e)}
                                                        onMenuScrollToBottom={() => handleScrollPaginingDoctor(pageSizeSelectDoctor)}
                                                        isDisabled={id_agreement > 0 ? true : false}
                                                    />
                                                    <FormText color='danger' hidden={!id_doctor_valid}>Selecciona un cliente</FormText>
                                                </FormGroup>
                                            }
                                        </Col>
                                        <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0 pb-3'>
                                            <FormGroup>
                                                <Label className='f-w-600' >Selecciona una razón social</Label>
                                                <Select
                                                    name="id_commercial_line"
                                                    defaultValue={id_commercial_line}
                                                    value={id_commercial_line}
                                                    isClearable={false}
                                                    placeholder="Selecciona una opción"
                                                    className="input-air-primary b-r-9"
                                                    options={listCommercialLines}
                                                    onChange={(e) => handleSelectValuesA(e, 'id_commercial_line')}
                                                    number={8}
                                                    isDisabled={id_agreement > 0 ? true : false}

                                                />
                                                <FormText color='danger' hidden={!id_commercial_line_valid}>Selecciona una razón social</FormText>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        </Col>
                        <Col sm="8" lassName={(informationCompany.rfc === undefined && informationDoctor.specialty === undefined) ? "d-none ribbon-wrapper" : " p-l-0 p-r-0"} >
                        <Card>
                            <CardBody className='pl-3 pr-3 pt-3 pb-3' >
                                {
                                    !loading &&  <Col sm="12" className='p-l-0 p-r-0'>
                                    <Row className={(informationCompany.rfc === undefined && informationDoctor.specialty === undefined) ? "d-none ribbon-wrapper" : ""}>
                                        {
                                        <InformationAgreement
                                            {
                                                ...{
                                                    //FORM
                                                    contract_number, validity, is_global, payment_periodicity, id_pay_method, id_financing_methods,
                                                    credit_limit, percentage_credit_limit, id_use_cfdi,
                                                    sales_name, sales_phone, sales_email, payment_name, payment_phone, payment_email, id_seller,
                                                    observations, swift_code,
        
                                                    //METHODS
                                                    handleInputChangeA, handleSelectValuesA,
        
                                                     //VALIDATIONS
                                                    contract_number_valid, validity_valid, payment_periodicity_valid, id_pay_method_valid, id_financing_methods_valid,
                                                    credit_limit_valid, percentage_credit_limit_valid, id_use_cfdi_valid, sales_name_valid,
                                                    sales_phone_valid, sales_email_valid, payment_name_valid, payment_phone_valid, payment_email_valid, id_seller_valid,
        
                                                    //INFORMATION COMPANY AND DOCTORS
                                                    informationCompany,
        
                                                    //LISTS
                                                    listPayForms, listFinancingMethods, listUseCfdi, listSellers,
                                                    
                                                    //SANITARY
                                                    sample_periodicity, is_micro,
        
                                                    on_check_sanitary, is_pay_for_compamy, required_invoice_company,
        
                                                    //*validate
                                                    saveOrUpdateAgreement,id_agreement,_active_micro
                                                }
                                            }
                                            visibility_inputs={visibilityInputs}
                                        />
                                    }
                                </Row>   
                                    </Col>
                                }
                                {
                                    loading &&  <>
                                    <Row>
                                        <Col sm="12" md="12" lg="12" className='text-center'>
                                            <img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "15%" }} />
                                        </Col>
                                        <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                            <p>Cargando ...</p>
                                        </Col>
                                    </Row>
                                </>
                                }
                    </CardBody>
                    </Card>  
                    </Col>
                    </Row>
                    <Col sm="12" className={(informationCompany.company_turn === undefined) ? "d-none ribbon-wrapper" : "p-l-0 p-r-0"} >
                        <Card>
                            <CardBody className='pl-3 pr-2 pb-3 pt-3' >
                            <Row>
                                <Col sm="4">
                                <h5>Sucursales</h5>                                
                                <b className='txt-info' >Selecciona las sucursales en las que aplicara el convenio</b> <br /><br />
                                <DeniReactTreeView
                                    items={ListBranchesTree}
                                    ref={treeViewRef}
                                    showCheckbox={true}
                                    showIcon={true}
                                    style={{  height: "245px"}}
                                />
                                <FormText color='danger' className='f-16' hidden={!branch_tree_valid}>Selecciona al menos una sucursal</FormText>
                                </Col>
                                <Col sm="8">
                                <h5>Datos del contacto</h5>                                
                                <Row className='mt-1' >
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto (Empresa)</span>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Nombre </Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='sales_name' value={sales_name} autoComplete='off' onChange={handleInputChangeA}  />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Teléfono</Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='sales_phone' value={sales_phone} autoComplete='off' onChange={handleInputChangeA} />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Correo</Label>
                                        <Input type="email" className='form-control form-control-sm input-air-primary b-r-9' name='sales_email' value={sales_email} autoComplete='off' onChange={handleInputChangeA} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-1' >
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto de pagos</span>
                                </Col>
                            </Row>
                            <Row className='mt-1' >
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Nombre</Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='payment_name' value={payment_name} autoComplete='off' onChange={handleInputChangeA} />
                                        <FormFeedback>Escribe mínimo 4 caracteres.</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Teléfono</Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='payment_phone' value={payment_phone} autoComplete='off' onChange={handleInputChangeA} />
                                        <FormFeedback>{CompleteInput}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Correo</Label>
                                        <Input type="email" className='form-control form-control-sm input-air-primary b-r-9' name='payment_email' value={payment_email} autoComplete='off' onChange={handleInputChangeA}  />
                                        <FormFeedback>{CompleteEmail}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col style={{textAlign : "end"}} sm="12" md="12" lg="12" className={ id_agreement > 0 ? "d-none" : "p-4"}>
                                    <FormGroup>
                                    <Button outline color='primary' onClick={() => saveOrUpdateAgreement()}>{"Guardar Datos Generales"}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                                </Col>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                        <Row className={(informationCompany.rfc === undefined && informationDoctor.specialty === undefined )? "d-none ribbon-wrapper mb-5" : "mb-5"}>
                            <Col sm="12" md="12" lg="12" className={id_agreement > 0 ? "" : "d-none"} >
                                <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                    <CardHeader className='bg-primary p-3'>
                                        <h6>Búsqueda de exámenes / Perfiles</h6>
                                    </CardHeader>
                                    <CardBody className='p-2'>
                                        <Col sm="12" md="12" lg="12">
                                            <Row>
                                                <Col lg="5" xl="5">
                                                    <Label className='f-w-500 f-12 badge badge-light-primary'>{"Busqueda de Exámen / Perfil"}</Label> <br /> <br />
                                                    <FormGroup>
                                                        <SearchAsync
                                                            name={'id_exam_profile'}
                                                            method={handleSelectTest}
                                                            loincState={examProfileState}
                                                            url="Profiles/SearchProfileExam"
                                                            maxLenght={3}
                                                        />
                                                        <FormText color='danger' hidden={!list_agrement_test_valid}>Selecciona al menos un examen</FormText>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="2" md="2" lg="2">
                                                    <Label className='f-w-500 f-12 badge badge-light-primary'>{"% Descuento global"}</Label> <br /> <br />
                                                    <Input className='form-control form-control-sm input-air-primary b-r-9' type='number' disabled={list_agrement_test.length === 0 ? true : false} value={globalPercentage} onChange={(e) => handleCalculateGlobalPercentage(e)} />
                                                </Col>
                                                <Col sm="5" md="5" lg="5" xl="5">
                                                    {/* {
                                                        id_agreement > 0 ?  */}
                                                        <div>
                                                             <Label className='f-w-500 f-12 badge badge-light-primary'>{"Cargar archivo csv."}</Label> <br /> <br />
                                                    <FormGroup>
                                                        <Col sm="12">
                                                            <Row>
                                                                <Col sm="8">
                                                                <Input 
                                                        
                                                            accept={".csv"}
                                                            onChange={handleOnChange}
                                                            type="file"
                                                            />
                                                                </Col>
                                                                <Col sm="4">
                                                                <Button outline color='primary' size='xs' onClick={ExamIndication} >Cargar</Button>

                                                                </Col>
                                                            </Row>
                                                            
                                                        </Col>
                                                    </FormGroup>

                                                        </div>
                                               
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Row>
                                            <Col sm="12" md="12" lg="12"> <br /> <br />
                                                {
                                                    list_agrement_test.length === 0 ?
                                                        <div>
                                                            <Col sm="12" style={{ textAlign: "center" }} >
                                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className={view_exams ? 'animate__animated animate__pulse animate__infinite '  : "" } style={{ width: '110px' }} />
                                                            </Col>
                                                            <br />
                                                            <Col sm="12" style={{ textAlign: "center" }}>
                                                                <p className='f-14 text-primary'>{view_exams ? <b>Carga masiva en progreso.</b> : <b>Seleccione los estudios que conformaran en el convenio.</b>   } </p>
                                                            </Col>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                id_agreement > 0 ?
                                                                <Row>
                                                                    <Col className='offset-8 pt-1 pb-2' md="4">
                                                                        <label className='txt-secondary f-14'>Cuando modifiques un precio, actualiza el convenio.</label>
                                                                        <Input placeholder='Buscar...' className='form-control input-air-primary b-r-9' onChange={handleSearch}/>
                                                                    </Col>
                                                                </Row> 
                                                                : ""  
                                                            }
                                                            <div className='table-responsive'>
                                                                <TableAgreement
                                                                    {
                                                                    ...{
                                                                        handleChangeRange, handleChangePercentage, handleDeleteRange, handleAddRange,
                                                                        handleChangePrice,
                                                                        list_agrement_test,
                                                                        number_format,handleCant,check_sanitary,is_micro,
                                                                        goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
                                                                        prevPage, nextPage, totalRecords, resultCount,
                                                                        modalCancellation,method,toggleModalCancellation,handleTest,id_agreement,handleDeleteTestCreate
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className='text-right'>
                                        <Button outline color='primary' onClick={() => saveOrUpdateAgreement()}>{id_agreement > 0 ? "Actualizar convenio" : "Guardar convenio"}</Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalCodeCsv
                {
                    ...{
                       
                        modalListEgress,toggleModalListEgress,listCode,handlemove,listRepit,priceTarifarion,profileT

                    }
                }
            />
            {
                 <ModalDeleteTest
                 {
                     ...{
                        modalCancellation,toggleModalCancellation,handleTest,modalTitle,informationExams,handleDeleteTest
                     }
                 }
             />

            }
        </Fragment>
    )
}
