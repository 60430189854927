import React, { Fragment } from 'react'
import { Container,Card } from 'reactstrap';
import './stylePbi.css';
import { usePowerBi } from '../../../hooks/Pbi/usePowerBi';


export const IndexPbi1 = () => {

    const {
        dataLink
    } = usePowerBi();

    const _order1 = dataLink.find(item => item.order === 1)?.link;
    console.log("🚀 ~ IndexPbi1 ~ _order1:", _order1)

    return (

        <Container fluid={true} >
            {
                _order1 === undefined ?
                    <Card className='shadow' >
                        <div style={{ textAlign: "-webkit-center" }} >
                            <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                            <label className='f-w-600 f-14 badge badge-light-primary'>No se ha Cargado algún reporte que mostrar</label>
                        </div>
                    </Card>
                    :
                    <div className='embed-container'>
                        <iframe
                            id="inlineFrameExample"
                            src={dataLink.length > 0 && _order1}
                            frameborder="0"
                        >
                        </iframe>
                    </div>
            }
        </Container>
    )
}

