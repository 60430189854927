//#region IMPORTS
import React, { Fragment, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row, Table, Alert } from 'reactstrap';
import { BtnSaveWorkOrder, CheckTextEmail, CheckTextPrint, CheckTextWhats, FontSize, LabelPrinterPoint, PlaceHolderOption } from '../../../constant';
import { useWorkOrder } from '../../../hooks/workOrder/useWorkOrder';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { TableWorkOrder } from '../../../components/elements/TableWorkOrder';
import { ModalViewExams } from '../../../components/workOrders/ModalViewExams';
import { ModalPatient } from '../../../components/patients/ModalPatient';
import { ModalDoctor } from '../../../components/doctors/ModalDoctor';
import { ModalSpecialDiscount } from '../../../components/workOrders/ModalSpecialDiscount';
import { ModalCommonDiscounts } from '../../../components/workOrders/ModalCommonDiscounts';
import { ModalQuoter } from '../../../components/workOrderQuoter/ModalQuoter';
import { ModalInvoice } from '../../../components/workOrderInvoice/ModalInvoice';
import { ModalRepeatProfile } from '../../../components/workOrders/ModalRepeatProfile';
import { IncomeTypeWorkOrder } from './IncomeTypeWorkOrder';
import { TableWorkOrderUpdate } from '../../../components/elements/TableWorkOrderUpdate';
import { ModalDevolution } from '../../../components/workOrders/ModalDevolution';
import { ModalLiquidacion } from '../../../components/workOrders/ModalLiquidacion';
import { ModalAuthorize } from '../../../components/workOrders/ModalAuthorize';
import Select from 'react-select';
import { AvailablePayMethodByCompany } from '../../../constant/workOrder';
import './StyleWorkOrder.css';
import 'animate.css';
import { ModalUpdateMethod } from '../../../components/workOrders/ModalUpdateMethod';
import DeniReactTreeView from "deni-react-treeview"
import { ModalCourtesyy } from '../../../components/workOrders/ModalCourtesyy';
import { IndexTypeMicro } from './IndexTypeMicro';

//#endregion

export const IndexWorkOrder = () => {
    //SE OBTIENE DIRECTO DEL TOKEN 
    let enable_whatsapp = localStorage.getItem('enable_whatsapp');
    const enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let _adminFinance = localStorage.getItem('admin_finance'); 
    
    const {
        modalTitle, number_format,

        //GLOBAL VARIABLES
        isEditWorkOrder, loadingEditWorkOrder,

        //#region WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts,
        id_patient, id_company, id_printer_point,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        percentageDiscount, showDiscountWarning, showDiscountWarningCredit, showDiscountWarningPay,
        checkDoctor, checkInvoice, listTest, listPayMethods, listDoctors,
        listCompanies, listAgreements, listTypeService, listType,
        times, loading,
        searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW, totalDevolutions,
        showMessageW, showMessageWF, showClassSD, showClass, OrderMedicalEstatus, viewExamsProfile, validationsWorkOrder, viewWorkOrder,
        enableBtnSaveWorkOrder, validationsRepeat, checkComments, ShowClassNameC, ShowDiscount, checkDiscopunt,
        devolutionsW, isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,
        searchExtra, listSupplies, isFullyPaid,  handleBirthDateChange, handleAgeChange,
        idAgreementCompany,
        //FILTER TYPE SEARCH
        typeSearch,

        //FILES PATIENT
        medical_order, handleOnChange, DeleteFile,

        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings
        , changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,
        handleChangeTypeFilterTest, saveUpdateWorkOrder, handleChangeExternalLaboratories,
        removeSupplies, handleChangeGeneratePay, handleChangeClient, handleChangeAgreement,

        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,
        toggleModalDevolutions, modalDevolutions,
        //#endregion

        //#region PATIENT
        //VALUES
        formValuesPatient, listBloodType, formSocialGender,
        searchPatientState, informationPatient, listStates, listMunicipalities, listTaxRegime, listUseCfdiPatient,
        modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient, handleInputChangeSocialGender, handleSelectValuesPatient,
        handlePickerValuesPatient, validationsPatient, validationsSG,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, Listsocialgender, modalSocialGender,
        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender,
        //METHODS
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient, handleChangeTypeService,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient, toggleModalSocialGender, saveSocialGender,
        handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
        //#endregion

        //#region DOCTOR
        //VALUES
        formDoctor, informationDoctor, id_work_order, workOrderId,
        modalDoctor, toggleDoctor,
        methodDoctor, loadingDoctor, validaciones1,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        // ? 
        code,

        //METHODS
        handleCreateDoctor, saveCreateDoctor,
        handleUpdateDoctor, saveUpdateDoctor, handleSaveDoctor, 
        //#endregion

        //#region SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,

        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,
        //#endregion

        //#region COMMON DISCOUNTS
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount,
        selectedCampaign, listCampaigns,

        //METHODS
        handleModalListCommonDiscounts, handleModalSocialGender,
        clearDiscount, handleChangeCampaign, removeExamProfileByCampaign,

        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,
        //#endregion

        //#region QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,
        //#endregion

        //#region INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice, id_work_order_type_service,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments, enableDiscount,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,
        //#endregion

        //#region PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //#endregion

        //#region SCAN INE
        setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, handleOnChangeINEU,
        //#endregion

        //#region DEVOLUTIONS
        listDevolutions,
        handleChangeDevolution, handleCancelDevolution,
        validateDevolutions, saveDevolution,
        //#endregion

        //#region PAYMENT BILLS
        //MODALS
        modalPayBills, toggleModalPayBills,
        //MODELS
        id_method_liquided, amount_liquided, id_method_pay, amount_liq,
        handleInputChangePaymentBills, validationsPaymentBills,

        //METHODS
        handlePayBill, savePaymentBill,
        //#endregion

        //#region CANCELL WORK ORDER UPDATE
        //MODALS
        modalCancelWorkOrderUpdate, toggleModalCancellWorkOrderUpdate,

        //MODELS
        user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        handleInputChangeCancelWorkOrder, totalCancellationWorkOrder,

        //VALIDATIONS
        validationsCancelWorkOrder,

        //METHODS
        handleCancelWorkOrderUpdate, handleValidateReturnAmount, saveCancelWorkOrderUpdate,
        //#endregion
        validatePercentajeSpecialDiscount, percentage,

        //CALCULO PARA FECHA DEL PACIENTE
        getAge, getBirthday, handleWheel, validPorcentejeSpecial, doctList,
        handleDeleteDoctor, validHopitalInterfaced, id_agreementBranch, validRestrictCompany,

        //#region SUPPLIES
        handleSelectSupplies, disableEdit, newDiscount, validateCommonDiscountDetail, setValidateCommonDiscountDetail,
        //#endregion SUPPLIES

        //*MODAL PAY METHOD
        modalPreviewUpdate, toggleModalUpdate, handleUpdateMethod,

        //*UPDATE METHOD
        user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,

        //*SAVE PAY METHOD
        saveUpdateMethod,

        //*VALID BUTTON
        validButton,

        //*CONFIGURATION ROLES FINANCE
        configuration_role, finance,
        //!FINANCIAL METHODS
        modalAutorization, toggleModalAutorization, PreviewAutorization, handleCancelAutorization, name_User, isEdit_agremment,
        handleSendNotificationInvoices,
        //VOTRE CHOISE
        study_delivery, validTotalCredit, validatePassword, aunthentication, validatePasswordCancell, validatePasswordFinance,
        loadingPayment, loadingUpdateMethod,nim_Notification, handleAgeC, handleDateB,

        // ? INDICATIONS EXAMS
        treeviewRef,itemsTree,dataPreloaded,handleSelectValuesPreloadedDiscounts,id_preloaded_discount,validatePreloadedDiscount,clearDiscount2,
        // ? COURTESY
        ModalCourtesy,toggleCourtesy,handleCourtesy,validatePasswordCortesy,

        // ? CONFIGURATION NAME BRANCH
        _branch_name,

        // ? CONFIGURATION AUTORIZATION TYPE SERVICE
        modalAurhorization,toggleAutorization,handleAutorizationTypeService,validatePasswordTypeService,loadingTypeService,
        _config_doctors,
          
        // ? CONFIGURATION MICRO
        is_micro,informationSampling,valueTax,downloadQuoter,validate_actions,_config_dashboard,
        id_patient_company


    } = useWorkOrder();
        // console.log("🚀 ~ IndexWorkOrder ~ listTest:", listTest)
        console.log("🚀 ~ IndexWorkOrder ~ validate_actions:", validate_actions)

    //!VALIDATION AGREMMENT
    let _valid_url = isEditWorkOrder ? isEdit_agremment : id_agreementBranch
    

    const onRenderItem = (item) => {
        const icon = item.isLeaf ? <i className='icofont icofont-prescription' ></i> : <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/typeService.svg")} style={{ width: "21px", height: "21px" }} />; 
        const color = item.state === 1 ? "#000000" : item.state === 2 ?  "#3c413ca6"  : item.state === 3 ? "#1f9ad9"  : item.state === 4 ? "#1f9ad9a3" :"#343a40"
    
        return (
            <div style={{ color, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>{icon}</span>
                {item.text}
            </div>
        );
    };
    
    const showButton = validPorcentejeSpecial === 'https://labcen.helen-sw.com';
    const isButtonDisabled = isEditWorkOrder ? (!enableBtnSaveWorkOrder || !showButton) : false;

    const {
        id_patient_valid,
        id_printer_point_valid,
        id_company_valid,
        listTest_valid,
        listPayMethods_valid,
        listid,
        id_work_order_type_service_valid
    } = validationsWorkOrder;

    const AlertItem = props => {
        const { item } = props;

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={true}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }


    
    return (
        <Fragment>
            <Container fluid={true}>
                {
                    isEditWorkOrder && loadingEditWorkOrder && <>
                        <Row className='mt-2'>
                            <Col sm="12" md="12" lg="12" className='text-center'>
                                <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite' style={{ width: '150px' }} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                            <Col sm="3" md="3" lg="3" className='text-left'>
                                <p className='f-24 text-primary'><b>Cargando</b></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                        </Row>
                    </>
                }
                {
                    // ! CONFIGURATION GLOBAL ORDER CLINICOS
                }
                <IncomeTypeWorkOrder
                    patientValid={id_patient_valid}
                    companyValid={id_company_valid}
                    {
                    ...{
                        // ? DOCTORS
                        handleCreateDoctor,_config_doctors,

                        // ? EDIT WORK ORDER 
                        isEditWorkOrder, loadingEditWorkOrder,

                        // ? WORK ORDER VARIABLES
                        checkTypeClient, modalScannQrPatient,isPayForCompany, 

                        // ? WORK ORDERS METHODS
                        handleChangeTypeClient, handleChangeGeneratePay, handleChangeClient,handleChangeAgreement,

                        // ? PATIENT VARIABLES
                        id_patient,inputReadQrCode, focusQrPatient, user_unique_code,searchPatientState, informationPatient,

                        // ? PATIENT METHODS
                        handleChangePatient,handleScannQrPatient, handleCreatePatient, handleSelectValuesQr,handleUpdatePatient,onKeyPressQr,qrCodeAutoFocus,

                        // ? CONFIGURATION DOCTOR
                        searchDoctorState, informationDoctor, doctList, workOrderId,handleChangeDoctor, handleUpdateDoctor, handleDeleteDoctor, handleSaveDoctor,

                        // ? COMPANY
                        id_company,listCompanies,listAgreements,idAgreementCompany,handleSelectValuesWorkOrder, 

                        // ? CANCELLATION WORK ORDER 
                        handleCancelWorkOrderUpdate,nim_Notification,validPorcentejeSpecial,_branch_name,
                    
                        // ? GLOBAL PRINT 
                        validHopitalInterfaced,id_printer_point,listPrinterPoint,PlaceHolderOption,id_printer_point_valid,LabelPrinterPoint,

                        // ? CONFIGURATION TYPE SERVICE SERVICE
                        handleAutorizationTypeService,id_work_order_type_service,listType,loadingTypeService,id_work_order_type_service_valid,

                        // ? MODAL TYPE SERVICE
                        modalAurhorization,toggleAutorization,handleInputChangeMethod,user_method,user_password_method,validatePasswordTypeService,

                        // ? CONFIGURATION GLOBAL
                        CheckTextPrint,handleInputChangeWorkOrder,checkPrint,checkPartial,checkInvoice,CheckTextEmail,checkEmail,checkDoctor,enable_whatsapp,CheckTextWhats,checkWhats,
                        observations,observations_sample,

                        // ? PDF MEDICAL
                        showClassSD,showMessageWF,handleOnChange,DeleteFile,showClass,viewWorkOrder,

                        // ? MOADAL AUTORIZATION
                        modalAutorization,toggleModalAutorization,PreviewAutorization,handleCancelAutorization,name_User, configuration_role,
                        handleSendNotificationInvoices, validatePasswordFinance,
                        id_patient_company,

                        is_micro

                       }
                    }
                />
                {
                    // ! CONFIGURATION GLOBAL MICRO
                    is_micro && <IndexTypeMicro
                    {
                        ...{checkTypeClient,informationPatient,id_company,informationSampling,nim_Notification,listTest,handleCancelWorkOrderUpdate,
                            number_format,totalW,listPayMethods,handleDinamicInputWorkOrder,validTotalCredit,AvailablePayMethodByCompany,_adminFinance,
                            handleGiveChange,handlePayBill,handleDeletePayMethod,isEditWorkOrder,isPayForCompany,
                            listPayMethods_valid,showMessageW,subTotalW,totalDiscountW,totalTaxesW,totalDevolutions,listPayMethod,valueTax,downloadQuoter,
                            checkInvoice
                            
                         }
                    }
                    />
                }
                {
                    // ! EXAMS
                }
               {
                !is_micro &&  <div className={ (checkTypeClient === 3 ?   (idAgreementCompany !==0 ? "animate__animated animate__fadeInDown" : "d-none" ) : viewWorkOrder ? 'animate__animated animate__fadeInDown' : 'd-none' )   }>
                 <Col sm="12" className='p-l-0 p-r-0 pt-3' >
                 <Card>
                    <CardBody>
                    <Col sm="12" className='p-l-0 p-r-0'>
                 <Row>
                    <Col sm="9" className='p-l-0 p-r-0' >
                    <h5>Búsqueda de examen/perfil</h5>
                    </Col>
                    <Col sm="2">
                    {
                        !isEditWorkOrder &&  <Button className='f-right bottonquoter' outline color='primary' size="xs" onClick={() => handleSearchQuoter()} disabled={isEditWorkOrder}>
                        <i className="icofont icofont-id"></i> Cargar Cotización
                    </Button>
                    }
                   
                    </Col>
                    <Col sm="1">
                    <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>    
                            <Col sm="5" className='p-r-0' style={{textAlign:"right"}} >
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/typeService.svg")} style={{ width: "30px", height: "30px" }} />
                            </Col>
                            <Col sm="7" className='p-l-0' >
                            <h5>{listTest.length}</h5>
                             </Col>   
                        </Row>
                    </Col>
                    </Col>
                 </Row>
                    </Col>
                    <Col sm="12" className='pt-3 pb-2 p-l-0 p-r-0' >
                    {
                        <Row className={checkTypeClient === 1 ? '' : 'd-none'}>
                        <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                            <ButtonGroup md="12" className='btn-group-pill btn-block'>
                                <Button disabled={isEditWorkOrder ? true : false} color='primary' type='button' onClick={() => handleChangeTypeFilterTest(0)} outline={typeSearch === 0 ? false : true}><i className="icofont icofont-test-tube-alt"></i> &nbsp;&nbsp; Búsqueda por Examen/Perfil</Button>
                                <Button disabled={isEditWorkOrder ? true : false} color='primary' type='button' onClick={() => handleChangeTypeFilterTest(1)} outline={typeSearch === 1 ? false : true}><i className="icofont icofont-paper"></i> &nbsp;&nbsp; Búscar campañas</Button>
                            </ButtonGroup>
                        </Col>
                        </Row>
                    }
                    <Row className={checkTypeClient === 1 ? typeSearch === 0 ? 'mt-3' : 'd-none' : 'mt-3'} >
                    {
                        _config_dashboard === "True" ? (
                        validate_actions === "true" ? null : (
                            <Col sm="12" md="12" className='p-l-0 p-r-0' >
                                <FormGroup>
                                <label className='f-w-600' >Selecciona un estudio</label>
                                <SearchAsync
                                    loincState={searchExamProfileState}
                                    name="id_profileExam"
                                    method={handleSelectExamChange}
                                    url={validRestrictCompany === "True" && checkTypeClient === 3 ? `Profiles/SearchProfileExamCompany?id_agreement=${_valid_url}` : `Profiles/SearchProfileExam`}
                                    maxLenght={3}
                                    isWorkOrder={true}
                                />
                                <FormText color="danger" hidden={!listTest_valid}>Debes de seleccionar un examen o perfil</FormText>
                                </FormGroup>
                            </Col>
                                )
                            ) : <Col sm="12" md="12">
                                <FormGroup>
                                    <label className='f-w-600' >Selecciona un estudio</label>
                                    <SearchAsync
                                        loincState={searchExamProfileState}
                                        name="id_profileExam"
                                        method={handleSelectExamChange}
                                        url={ validRestrictCompany === "True" && checkTypeClient === 3 ? `Profiles/SearchProfileExamCompany?id_agreement=${_valid_url}` : `Profiles/SearchProfileExam`}
                                        maxLenght={3}
                                        isWorkOrder={true}
                                    />
                                    <FormText color="danger" hidden={!listTest_valid}>  Debes de seleccionar un examen o perfil</FormText>
                                </FormGroup>
                            </Col>
                            }         
                    </Row>
                    <Row className={checkTypeClient === 1 ? typeSearch === 1 ? 'mt-3' : 'd-none' : 'd-none'}>
                        <Col sm="12" md="12">
                            <FormGroup>
                            <Label className='col-form-label'>Campañas adecuadas para el paciente</Label>
                            <Select
                                classNamePrefix="select"
                                name="selectedCampaign"
                                value={selectedCampaign}
                                placeholder="Selecciona una campaña"
                                options={listCampaigns}
                                onChange={(e) => handleChangeCampaign(e)}
                                theme={
                                    (theme) => ({
                                    ...theme,
                                    colors: {
                                    ...theme.colors,
                                    primary25: '#898989'
                                    }
                                })
                                }
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </Col>
                    <Col sm="12" md="12" className='pt-3 p-l-0 p-r-0' >
                        <div className='table-responsive'> <br />
                            {
                            !isEditWorkOrder && listTest.length > 0 &&
                                <TableWorkOrder
                                    key="tableExams"
                                    items={listTest}
                                    {
                                        ...{removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, number_format, changeSelectSamplings, 
                                            changeValueTimeSampling, times, handleViewExamsProfile,checkTypeClient, listid, enable_tracking_toma, 
                                            removeExamProfileByCampaign, handleChangeExternalLaboratories
                                            }
                                    }
                                />
                            }
                            {
                                isEditWorkOrder && listTest.length > 0 &&
                                <TableWorkOrderUpdate
                                    key={"tableOfExamsUpdate"}
                                    items={listTest}
                                    count={listTest.length}
                                    isEditWorkOrder={isEditWorkOrder}
                                    {
                                        ...{
                                            number_format, id_company, removeExamProfile, handleCheckSpecimen, handleCheckUrgent,
                                            changeSelectSamplings, timesCurves: times, changeValueTimeSampling, handleViewExamsProfile,
                                            handleCheckForwarded, enable_tracking_toma, checkDiscount: false, valid_commont: false,
                                            checkTypeClient, handleChangeExternalLaboratories,validate_actions,_config_dashboard
                                            }
                                    }
                                />
                            }
                        </div>
                    </Col>
                    </CardBody>
                 </Card>
                 </Col>
                
                {
                    // ? INDICATION
                }
                
                
                {listTest && listTest.length > 0 ? (
                    <Col sm="12" className='p-l-0 p-r-0 pb-5' >
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col sm="4" className='p-l-0 p-r-0'>
                                    <h5 className='f-w-600' >Indicaciones de los estudios</h5>
                                    <div className='pt-4' >
                                        {itemsTree && itemsTree.length > 0 ? (
                                        <DeniReactTreeView
                                            key={JSON.stringify(itemsTree)}
                                            ref={treeviewRef}
                                            style={{ marginRight: '1px', marginBottom: '11px', width: "97%", textAlign: "center" }}
                                            showCheckbox={false}
                                            showIcon={false}
                                            items={itemsTree}
                                            onRenderItem={onRenderItem}
                                        />
                                        ) : (
                                            <label className='txt-danger f-w-500' ><i className="fa fa-warning "></i> Sin indicaciones previas de estudios</label>
                                        )}
                                    </div>
                                    </Col>
                                    <Col sm="8" className='p-r-0' >
                                    <h5 className='f-w-600' >Detalles del pago</h5>
                                    <Col sm="12"  >
                                    <Row className='m-t-10 p-1'>
                                        <Col sm="12" md="12" lg="12" xl="12" className={ShowClassNameC}>
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Observaciones Generales</Label>
                                            <textarea name="observations_general" className='form-control form-control-sm input-air-primary'
                                                rows="2" onChange={handleInputChangeWorkOrder}
                                                value={observations_general}>
                                            </textarea>
                                        </Col>
                                    </Row>
                                    {showButton && isEditWorkOrder && (
                                        <Row className='m-t-10 p-3 justify-content-end'>
                                            <Col sm="12" md="12" lg="12" xl="12" className={`text-right ${ShowDiscount}`}>
                                                <Label className='f-w-600 f-12 badge badge-light-primary'>Descuento</Label>
                                                <input name="percentageDiscount" type="number" pattern="[0-9]*"
                                                    onChange={handleInputChangeWorkOrder}
                                                    value={percentageDiscount} />
                                            </Col>
                                        </Row>
                                    )}

                                    <Row className='m-t-10 p-1'>
                                        <Col xs="12" sm="6" md="3" lg="4" xl="4" className='p-l-0 p-r-0' >
                                            <Row>
                                                <Col sm="4" className='p-r-0'>
                                                    <label className='txt-secondary'>Tiempo de entrega</label>

                                                </Col>
                                                <Col sm="8" className='p-l-0'>
                                                    <Input type='datetime-local' name="study_delivery" onChange={handleInputChangeWorkOrder} value={study_delivery} className="form-control form-control-sm input-air-primary" placeholder="Hora..." />

                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs="12" sm="6" md="3" lg="3" xl="3" style={{ textAlign: "right" }}>
                                            {
                                                (!validTotalCredit && validButton) && <Button type='button' className='btn-air-primary' outline color='dark' size='sm' onClick={handleAddPayMethodInputs}>
                                                    <i className='fa fa-plus'></i> Método de pago
                                                </Button>

                                            }
                                        </Col>
                                        {
                                            (id_company === 0 || id_company === "0" || id_company === null ||  id_company === "" )
                                            ?
                                            <Col xs="12" sm="6" md="3" lg="3" xl="3" >
                                            { 
                                                specialDiscount === null ?
                                                    <Button type='button' className='float-right btn-air-primary' outline color='primary' size='sm' onClick={() => handleModalSpecialDiscount()} hidden={isEditWorkOrder} disabled={checkTypeClient === 1 ? false : true} >
                                                        <i className="fa fa-percent"></i> Descuento especial
                                                    </Button>
                                                    :
                                                    <Button type='button' disabled className='float-right btn-air-primary disabled' outline color='primary' size='sm' onClick={null} hidden={isEditWorkOrder}>
                                                        <i className="fa fa-check"></i> Descuento aplicado
                                                    </Button>
                                            }
                                            </Col>
                                            :
                                            ""
                                        }
                                        {
                                            (id_company === 0 || id_company === "0" || id_company === null ||  id_company === "" )
                                            ?
                                            <Col xs="12" sm="6" md="2" lg="2" xl="2"  >
                                            <Button type='button' className='float-right btn-air-primary' outline={!checkComments} color='primary' size='sm' onClick={enableComments}>
                                                    {`${checkComments ? 'Ocultar' : 'observaciones'}`}
                                                </Button>
                                            </Col>
                                            :
                                            <Col xs="12" sm="6" md="5" lg="5" xl="5"  >
                                            <Button type='button' className='float-right btn-air-primary' outline={!checkComments} color='primary' size='sm' onClick={enableComments}>
                                                    {`${checkComments ? 'Ocultar' : 'observaciones'}`}
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    <Col sm="12" className={validPorcentejeSpecial === "https://labcen.helen-sw.com" ? " p-l-0 p-r-0" : "d-none"}   >
                                        <br />
                                        {
                                            (arrayCommonDiscounts.length > 0 && !isEditWorkOrder && !validateCommonDiscountDetail) ?

                                                <AlertItem
                                                    item={
                                                        {
                                                            alerttxt: "EL paciente es candidato a una campaña",
                                                            alertcolor: 'danger inverse',
                                                            btnclose: 'default',
                                                            icon: <i className="icon-alert"></i>
                                                        }
                                                    }
                                                />
                                                : ""
                                        }
                                    </Col>

                                    <Row className='m-t-5 p-1'>
                                        <Col sm="12" md="6" lg="6" xl="6" className=' mt-2'>
                                            <Row className='m-t-15'>
                                            <Table style={{ fontSize: "11px" }}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#002940" }}>
                                                            <td colSpan={3} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                                Detalle de Pagos
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: "5px", textAlign:"center" }}>
                                                                <label className=''>Método de pago</label>
                                                            </td>
                                                            <td className="text-center" style={{ padding: "5px" }}>
                                                                <label>Monto</label>
                                                            </td>
                                                            <td style={{ padding: "5px" }}>
                                                                <label className=''>Acciones</label>
                                                            </td>
                                                        </tr>
                                                        {
                                                            listPayMethods.map((_paymethod, keyPayment) => {
                                                                return (
                                                                    <tr key={`method-${keyPayment}`} className={_paymethod.deleted ? 'd-none' : ''}>
                                                                        <td>
                                                                            <Input type="select" required={true} className='form-control form-control-sm input-air-primary p-1' value={_paymethod.id_pay_method} data-id={keyPayment}
                                                                                onChange={(e) => handleDinamicInputWorkOrder(e, "id_pay_method", "listPayMethods")} disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}>
                                                                                <option value={0}>Selecciona una opción</option>
                                                                                {
                                                                                    listPayMethod.map(_pMethod => {
                                                                                        if (checkTypeClient === 3 && isPayForCompany) {
                                                                                            
                                                                                            if (_pMethod.name === AvailablePayMethodByCompany) {
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                        else if (_pMethod.abbreviation !== "CONV") {

                                                                                            if (_adminFinance === "True" ) {
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                            else {
                                                                                                if (_pMethod.visibility_particular === true) {                                                                                                    
                                                                                                    return (
                                                                                                        <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                    )
                                                                                                }
                                                                                            }                                                                                         
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </td>
                                                                        <td>
                                                                            <Input type='number' name="amount" data-id={keyPayment} value={_paymethod.amount} placeholder='$ 0.0' step="any"
                                                                                className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                                disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}
                                                                            />
                                                                        </td>
                                                                        <td className={validTotalCredit ? "d-none" : 'p-1 text-center' }  style={{ verticalAlign: "middle" }} colSpan={3}  >
                                                                            {

                                                                                (_paymethod.fully_paid !== undefined && !_paymethod.fully_paid) ?
                                                                                    <Button type='button' className='btn-air-success' outline color='success' size='xs' onClick={() => handlePayBill(_paymethod.id_work_order_pay, _paymethod.amount)}>
                                                                                        <i className="fa fa-money"></i>
                                                                                    </Button>
                                                                                    :
                                                                                    <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(keyPayment)}
                                                                                        disabled={(_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW > 0 ? false : true)}
                                                                                    >
                                                                                        <i className='icofont icofont-ui-delete'></i>
                                                                                    </Button>
                                                                            }
                                                                            {
                                                                                _paymethod.fully_paid && isEditWorkOrder && <img className="img-fluid pointer" src={require("../../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} style={{ width: "18%" }} onClick={handleUpdateMethod} />
                                                                            }
                                                                            {
                                                                            (_paymethod.id_pay_method !== 29 &&  _adminFinance === "True"  && isEditWorkOrder )&& <img src={require("../../../assets/images/price/courtesy.svg")} className='pointer' style={{ width: "18%" }}  onClick={handleCourtesy} alt="" />

                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    
                                                
                                                    </tbody>
                                                    {
                                                    !validTotalCredit  && 
                                                        <tfoot>
                                                        <tr>
                                                            <td colSpan={3} className="text-xs-center">
                                                                <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>
                                                                <b style={{color:"#002940"}}>{showMessageW.labelMoney}</b>
                                                            </td>
                                                            <td>
                                                                <b style={{color:"#002940"}} className=''>$ {`${number_format(showMessageW.amount, 2)}`}</b>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                    }
                                                </Table>
                                            </Row>
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="12" md="5" lg="5" xl="5" className='p-r-0 p-l-0 mt-2'>
                                            <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                                <Col sm="12">
                                                <Table style={{ fontSize: "11px" }}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#002940" }}>
                                                            <td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                                Detalle de movimientos
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: "5px" }}>
                                                                <label className='f-12'>Subtotal</label>
                                                            </td>
                                                            <td className="text-center" style={{ padding: "5px" }}>
                                                                <label>$ {number_format(subTotalW, 2)}</label>
                                                            </td>
                                                        </tr>
                                                        {totalDiscountW >= 0 &&
                                                        <tr style={{ cursor: "pointer" }} className="border-bottom-primary">
                                                            <td className='f-14'><label className='labelPrices'>Descuento</label>  </td>
                                                            <td className="text-right ">
                                                                <b className="text-danger text-WorkOrderDanger">(-) ${number_format(totalDiscountW, 2)}</b>
                                                                <a onClick={handleModalListCommonDiscounts} className="f-w-600 f-12 badge badge-light-primary ml-2"><i className="fa fa-plus-circle"></i></a>
                                                            </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={{ padding: "5px" }}>
                                                                <label style={{fontSize:"13px"}}   >I.V.A )</label>
                                                            </td>
                                                            <td className="text-center" style={{ padding: "5px" }}>
                                                                <label>$ {number_format(totalTaxesW, 2)}</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "5px" }}>
                                                                <label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
                                                            </td>
                                                            <td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
                                                                <label style={{fontSize:"13px"}} className='f-w-600'>$ {number_format(totalW, 2)}</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "5px" }}>
                                                                <label style={{fontSize:"13px"}} className='f-w-600'>Devoluciones</label>
                                                            </td>
                                                            <td className="text-center f-w-600 " style={{ padding: "5px" }}>
                                                                <label style={{fontSize:"13px"}} className='f-w-600'>$ {number_format(totalDevolutions, 2)}</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15'>
                                                <Col sm="12">
                                                    {showDiscountWarning && (
                                                        <h7 style={{ color: 'red' }}>¡El porcentaje de descuento debe estar entre 0 y 100!</h7>
                                                    )}
                                                    {showDiscountWarningPay && (
                                                        <h7 style={{ color: 'red' }}>¡Excediste el crédito!</h7>
                                                    )}
                                                    {
                                                        listTest.length > 0 &&
                                                        <ButtonGroup className="btn-group-pill btn-group-sm" size='sm' style={{ width: '100%' }}>
                                                            {
                                                                isEditWorkOrder ? <Button
                                                                    className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                                                                    outline
                                                                    color='primary-2x'
                                                                    disabled={
                                                                        validTotalCredit ? false :
                                                                            showButton === false ?
                                                                                enableBtnSaveWorkOrder :
                                                                                (showDiscountWarning || showDiscountWarningPay)
                                                                                    ? true
                                                                                    : false
                                                                    }


                                                                    onClick={() => saveUpdateWorkOrder()}
                                                                >
                                                                    <label className="labelSaveCreate">{BtnSaveWorkOrder}</label>
                                                                </Button>
                                                                    : <Button
                                                                        className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                                                                        outline
                                                                        color='primary-2x'
                                                                        disabled={validTotalCredit ? false : enableBtnSaveWorkOrder}
                                                                        onClick={() => saveWorkOrder()}
                                                                    >
                                                                        <label className="labelSaveCreate">{BtnSaveWorkOrder}</label>
                                                                    </Button>
                                                            }
                                                            <Button
                                                                className='btn-air-danger'
                                                                outline
                                                                color='danger'
                                                                onClick={() => cancellWorkOrder()}
                                                                hidden={isEditWorkOrder}
                                                            >
                                                                <label className='labelSaveCreate'>Cancelar</label>
                                                            </Button>
                                                        </ButtonGroup>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    </Col>
                                    </Col>
                                </Row>
                        

                            </CardBody>
                        </Card>
                    </Col  >
                ) : null}

                
                </div>
               }

               
            </Container>
            <ModalPatient
                {
                ...{
                    modal: modalPatient, toggle: togglePatient, modalTitle, method: methodPatient, loading: loadingPatient,
                    validationsPatient, saveUpdateWorkOrder, validHopitalInterfaced, handleBirthDateChange, handleAgeChange,

                    handleDatePatient, handleChangeSocialGender, validPorcentejeSpecial,
                    handleInputChange: handleInputChangePatient, handleSelectValues: handleSelectValuesPatient, handleDate:
                    handleDatePatient, getBirthday, getAge, handleWheel, handleChangeSocialGender,

                    id_gender: formValuesPatient.id_gender, id_state: formValuesPatient.id_state, id_municipality: formValuesPatient.id_municipality,
                    id_blood_type: formValuesPatient.id_blood_type, id_social_gender: formValuesPatient.id_social_gender, id_tax_regime: formValuesPatient.id_tax_regime,
                    id_use_cfdi: formValuesPatient.id_use_cfdi, rfc: formValuesPatient.rfc, type_person: formValuesPatient.type_person, code: formValuesPatient.code,
                    paternal_surname: formValuesPatient.paternal_surname, maternal_surname: formValuesPatient.maternal_surname, name: formValuesPatient.name, birthday: formValuesPatient.birthday,
                    age: formValuesPatient.age, nss: formValuesPatient.nss,
                    curp: formValuesPatient.curp, address: formValuesPatient.address, cp: formValuesPatient.cp, colony: formValuesPatient.colony,
                    phone: formValuesPatient.phone, email: formValuesPatient.email, membership: formValuesPatient.membership, cardnumber: formValuesPatient.cardnumber,
                    new_social_gender: formValuesPatient.new_social_gender, expedient_number: formValuesPatient.expedient_number,
                    listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi: listUseCfdiPatient, listSocialGender: Listsocialgender,

                    handleChangeState: handleChangeStatePatient, handleChangeTypePerson, handleChangeTaxRegime,
                    savePatient: saveCreatePatient, saveUpdate: saveUpdatePatient,

                    isInvoiceService, setIsInvoiceService,
                    viewNewSocialGender, setViewNewSocialGender,
                    scan_ine_check: formValuesPatient.scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
                    img64File: formValuesPatient.img64File, handleOnChangeINEU,
                    codeP: formValuesPatient.cp, handleAgeC, handleDateB,
                    matricula_buap: formValuesPatient.matricula_buap,
                }
                }
            />
            <ModalDoctor
                nameInputs={nameInputsDoctor}
                addSpecialty={true}
                {
                ...{
                    modal: modalDoctor, toggle: toggleDoctor, handleInputChange: handleInputChangeDoctor, saveCreate: saveCreateDoctor,
                    saveUpdate: saveUpdateDoctor, loading: loadingDoctor, method: methodDoctor, specialties, validaciones: validationsDoctor,
                    id_specialty, name_specialty, name: name_doctor, professional_license, phone: phone_doctor, address: address_doctor,
                    email: email_doctor, isCommercialPartner, saveUpdateWorkOrder, validaciones1,
                    code
                }
                }
            />
            <ModalViewExams
                {
                ...{
                    modalViewExamsP, toggleModalViewExams, viewExamsProfile
                }
                }
            />

            <ModalSpecialDiscount
                {
                ...{
                    modal: modalSpecialDiscounts, toggle: toggleSpecialDiscount, special_discount_code, handleInputChangeSpecialDiscount, validateCodeSpecialDiscount,
                    validatePercentajeSpecialDiscount, percentage, validPorcentejeSpecial,dataPreloaded,handleSelectValuesPreloadedDiscounts,id_preloaded_discount,validatePreloadedDiscount
                }
                }
            />

            <ModalCommonDiscounts
                {
                ...{
                    modal: modalCommonDiscount, toggle: toggleCommonDiscount,
                    arrayCommonDiscounts, clearDiscount,
                    arrayAgreementDiscounts, percentageDiscount, newDiscount, validPorcentejeSpecial, setValidateCommonDiscountDetail,clearDiscount2,id_preloaded_discount
                }
                }
            />

            <ModalQuoter
                {
                ...{
                    modal: modalQuoter, toggle: toggleModalQuoter, modalTitle, method: methodQuoter, loading: loadingQuoter,
                    handleGetQuoter, code_quoter, date_quoter, listQuoter,
                    handleInputChangeQuoter, validationsQuoter, searchQuoterByCode,
                    enableViewTest, viewTestQuoter, handleViewTest, handleCloseViewTest,
                }
                }
            />

            <ModalInvoice
                {
                ...{
                    modal: modalInvoice, toggle: toggleModalInvoice, modalTitle, loading: loadingInvoice, method: methodInvoice,
                    listUseCfdi, handleInputChange: handleInputChangeWorkOrder,
                    validationsInvoice, saveInvoice, cancelInvoice,
                    business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice,
                }
                }
            />
            <ModalRepeatProfile
                {
                ...{
                    modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile
                }
                }
            />

            <ModalDevolution
                {
                ...{
                    toggleDevolutions: toggleModalDevolutions, modalTitle,
                    devolutionsState: devolutionsW, modalDevolutions, listTest,
                    number_format, listReturnReason: listDevolutions,
                    handleChangeReturnReason: handleChangeDevolution,
                    validateDevolutions, canceldevolution: handleCancelDevolution,
                    saveReturnReason: saveDevolution, loading
                }
                }
            />

            <ModalLiquidacion
                {
                ...{
                    modal: modalPayBills, toggle: toggleModalPayBills, handleInputChangeLiquided: handleInputChangePaymentBills,
                    saveLiquided: savePaymentBill, listPayMethod, loading,
                    validatesaveLiquided: validationsPaymentBills,
                    id_method_liquided, amount_liquided,
                    id_method_pay, amount_liq, percentageDiscount, loadingPayment
                }
                }
            />

            <ModalAuthorize
                {
                ...{
                    modal: modalCancelWorkOrderUpdate, toggle: toggleModalCancellWorkOrderUpdate,
                    user_authorize, password_authorize, id_return_reason_woc, percentage_return,
                    validationsCancelWorkOrder, totalCancellationWorkOrder, loading, listDevolutions,

                    handleInputChangeCancelWorkOrder, handleValidateReturnAmount,

                    saveCancelWorkOrderUpdate, validatePasswordCancell, aunthentication
                }
                }
            />

            <ModalUpdateMethod
                {
                ...{
                    modalPreviewUpdate, toggleModalUpdate,
                    user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,
                    saveUpdateMethod, loading, modalTitle, listPayMethod, validatePassword, aunthentication, loadingUpdateMethod
                }
                }
            />
            <ModalCourtesyy
            {
                ...{ ModalCourtesy,toggleCourtesy,validatePasswordCortesy,user_method, user_password_method,handleInputChangeMethod,aunthentication,loadingUpdateMethod}
            }

            />
        
        </Fragment>
    )

}