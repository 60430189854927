import React, { useState, useEffect } from 'react';
import { getAllPbi } from '../../services/Pbi/pbiService';



export const usePowerBi = () => {


    const [dataLink, setDataLink] = useState([]);
   

    useEffect(() => {
        handleGetSpecialties();
    }, []);

    const handleGetSpecialties = async () =>
    {
        const listPbi = await getAllPbi(false);    
    
        if (listPbi.length > 0)
        {
            const list = listPbi?.map(_find => {
                return {
                    link: _find?.link,
                    order: _find?.order,
                }
            });  
            setDataLink(list)    
        }
    }
    

    return {
        dataLink
    }
}
